<script>
	import Page from '../components/Page.svelte';
	import Button from '../components/Button.svelte';
	import StoreTable from '../components/StoreTable.svelte';
	import CategoryDialog from '../components/dialogs/CategoryDialog.svelte';

	import {categories} from '../stores/categories';

	const onAdd = () => {
		clickedCategory = null;
		openDialog();
	};

	const onEdit = (category) => {
		clickedCategory = category;
		openDialog();
	};

	const onDelete = async (category) => {
		if (
			confirm(
				`Are you sure you want to delete category ${category.name}?`
			)
		) {
			await categories.delete(category.id);
			onUpdate();
		}
	};

	const columns = [
		{
			title: 'Name',
			value: (v) => v.name,
		},
	];

	const actions = [
		{
			title: 'Edit',
			icon: 'edit',
			action: onEdit,
		},
		{
			title: 'Delete',
			icon: 'delete',
			action: onDelete,
		},
	];

	let clickedCategory;
	let onUpdate;
	let openDialog;
</script>

<Page title="Categories" crumbs={['categories']}>
	<svelte:fragment slot="toolbar">
		<Button on:click={onAdd}>Add category</Button>
	</svelte:fragment>

	<StoreTable
		store={categories}
		columns={columns}
		actions={actions}
		bind:onUpdate />

	<CategoryDialog
		bind:open={openDialog}
		category={clickedCategory}
		on:submit={onUpdate} />
</Page>
