<script>
	import {router} from 'tinro';

	import {user} from '../stores/user';
	import {shortLocations} from '../stores/short/locations';
	shortLocations.fetch();

	export let crumbs = [];
	let hidden = false;
	if (crumbs === 'hidden') {
		hidden = true;
	}

	const paths = [];

	const pathing = () => {
		// Get link for crumbs
		const path = $router.path.split('/');
		path.forEach(() => {
			paths.push(path.join('/'));
			path.pop();
		});

		paths.reverse();
	};

	$: pathing();
</script>

<div class="crumbs">
	{#if !hidden}
		<a href={`/location/${$user?.locationId}`} class="crumb"
			>{$shortLocations[$user?.locationId]}</a>
	{/if}
	{#each crumbs as crumb}
		<a href={paths[crumbs.indexOf(crumb) + 1]} class="crumb">{crumb}</a>
	{/each}
</div>

<style>
	.crumbs {
		width: 100%;
	}
	.crumb {
		font-weight: bold;
		text-transform: capitalize;
		text-decoration: none;
		color: #30465e;
	}
	.crumb:not(:last-child) {
		color: #9c9c9c;
	}
	.crumb:not(:nth-child(1))::before {
		content: ' / ';
	}
</style>
