<script>
	import {onMount} from 'svelte';
	import {router} from 'tinro';

	import {getSelf} from '../api/users';
	import {login, loginWithGoogle, refreshToken} from '../api/authentication';
	import {token} from '../stores/token';
	import {user} from '../stores/user';
	import {localstore} from '../stores/localstore';

	import GoogleSignInButton from './GoogleSignInButton.svelte';

	import {getBestLocationId} from '../utils/tokenUtils';
	import getSafeUrl from '../utils/getSafeUrl';
	import {setLocation} from '../api/authentication';

	import {
		initAmplitude,
		setAmplitudeUserId,
		sendAmplitudeEvent,
	} from '../utils/amplitude';
	initAmplitude();
	export let onLogin;

	let email = '';
	let password = '';
	let error = '';

	/* eslint-disable */
	//Disables cause these are produced in build scripts
	const buildType = myapp.env.BUILD_TYPE;
	/* eslint-enable */

	onMount(async () => {
		// Just calling the api with a function that every user is able to call,
		// so that the token is updated via the regular path if the cookie is set
		await getSelf();
	});

	$: if ($token) {
		if (
			new Date($user?.exp).valueOf() <
			Math.floor(new Date().valueOf() / 1000)
		) {
			$token = null;
		} else if ($router.query.invite) {
			const code = $router.query.invite;
			router.goto(`/invite/?code=${code}`);
		} else {
			const locationId = $localstore?.locationId
				? $localstore.locationId
				: getBestLocationId($user);

			setLocation(locationId);

			const url = getSafeUrl(
				locationId,
				$user?.locationPermissions[locationId],
				$user?.admin
			);

			router.goto(url);
		}
	}

	const onSubmit = async () => {
		sendAmplitudeEvent('DASHBOARD_LOGIN', {
			method: 'EmailPassword',
		});
		try {
			const authResponse = await login({email, password});
			console.log(authResponse);
			await refreshToken(user);

			$token = authResponse.token;
		} catch (e) {
			error = e.error ? e.error : e;
			sendAmplitudeEvent('DASHBOARD_LOGIN_FAILED');
			return;
		}

		let url;
		if ($router.query.invite) {
			const code = $router.query.invite;
			url = `/invite/?code=${code}`;
		} else {
			const locationId = $localstore?.locationId
				? $localstore.locationId
				: getBestLocationId($user);

			setLocation(locationId);

			url = getSafeUrl(
				locationId,
				$user?.locationPermissions[locationId],
				$user?.admin
			);
			$localstore = {locationId, email};
		}
		setAmplitudeUserId('USERID_' + $user.id);
		sendAmplitudeEvent('DASHBOARD_LOGIN_SUCCESS');
		router.goto(url);
		onLogin();
	};

	const onGoogleSignIn = async (idToken) => {
		sendAmplitudeEvent('DASHBOARD_LOGIN', {
			method: 'Google',
		});
		try {
			const authResponse = await loginWithGoogle({idToken});
			$token = authResponse.token;
		} catch (e) {
			error = e.error ? e.error : e;
			sendAmplitudeEvent('DASHBOARD_LOGIN_FAILED');
			return;
		}

		let url;
		if ($router.query.invite) {
			const code = $router.query.invite;
			url = `/invite/?code=${code}`;
		} else {
			const locationId = $localstore?.locationId
				? $localstore.locationId
				: getBestLocationId($user);

			url = getSafeUrl(
				locationId,
				$user?.locationPermissions[locationId],
				$user?.admin
			);
			$localstore = {locationId};
		}
		setAmplitudeUserId('USERID_' + $user.id);
		sendAmplitudeEvent('DASHBOARD_LOGIN_SUCCESS');
		router.goto(url);
		onLogin();
	};
</script>

<form on:submit|preventDefault={onSubmit}>
	<div>
		<label>
			Email
			<input type="email" bind:value={email} />
		</label>

		<label>
			Password
			<input type="password" bind:value={password} />
		</label>
		<div class="errortext">{error}</div>
		<br />
		<button class="login-button main-button" type="submit">
			Sign in
		</button>
		<br />
		<br />
		<br />
		{#if buildType !== 'PRODUCTION'}
			<!-- This check is temporary until also implemented in app -->
			<GoogleSignInButton onSignIn={onGoogleSignIn} />
		{/if}
		<br />
		<a class="link" href="/forgot-password">Forgot password?</a>
	</div>
</form>

<style>
	.main-button {
		background: #0000ff;
		border: none;
		color: white;
	}
	.main-button:hover {
		background-color: #000096;
		cursor: pointer;
	}

	.login-button {
		min-width: 200px;
		padding: 15px 30px;
		border-radius: 25px;
		transition: all 0.12s;
	}

	.errortext {
		color: red;
	}
</style>
