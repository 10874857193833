import {writable} from 'svelte/store';

import {
	deleteDataset,
	getDatasets,
	postDataset,
	updateDataset,
} from '../api/datasets';

function createDatasets() {
	const {subscribe, set, update} = writable();
	const fetchDatasets = async (offset, limit, orderBy) => {
		return set(await getDatasets(offset, limit, orderBy));
	};

	return {
		subscribe,
		fetch: fetchDatasets,
		add: async (dataset) => {
			await postDataset(dataset);
		},
		change: async (dataset) => {
			await updateDataset(dataset);
		},
		delete: async (id) => {
			await deleteDataset(id);
			update((arr) => ({
				count: arr.count,
				records: arr.records.filter((dataset) => dataset.id !== id),
			}));
		},
	};
}

export const datasets = createDatasets();
