<script>
	import {onMount} from 'svelte';
	import {createEventDispatcher} from 'svelte';

	import Dialog from '../Dialog.svelte';
	import {getPermissions} from '../../api/permissions';
	import {updateRole, postRole} from '../../api/roles';

	let close;
	export let open;
	let error = '';
	let permissions = [];
	export let role;
	const checkedPermissions = {};
	const dispatch = createEventDispatcher();

	$: role = role || {
		id: undefined,
		name: '',
		permissions: [],
	};

	onMount(async () => {
		permissions = (await getPermissions()).records;
		permissions.forEach((permission) => {
			checkedPermissions[permission.id] = false;
		});
	});

	const onSubmit = async () => {
		try {
			const newRole = {
				id: role.id,
				name: role.name,
				permissions: role.permissions.map((p) => p.id),
			};
			if (role.id) {
				await updateRole(newRole);
			} else {
				await postRole(newRole);
			}
			role = null;
			dispatch('submit');
			close();
		} catch (e) {
			error = e.error;
		}
	};

	const onCheckChange = (event, permission) => {
		if (event.target.checked) {
			role.permissions = [...role.permissions, permission];
		} else {
			role.permissions = role.permissions.filter(
				(rolePermission) => rolePermission.id !== permission.id
			);
		}
	};
</script>

<form on:submit|preventDefault={onSubmit}>
	<Dialog
		title="{role.id ? 'Edit' : 'Add'} role"
		canScroll
		bind:open
		bind:close
		bind:error>
		<label>
			Name
			<input type="text" bind:value={role.name} />
		</label>
		{#each permissions as permission}
			<div>
				<label>
					<input
						type="checkbox"
						checked={role.permissions.some(
							(rolePermission) =>
								permission.id === rolePermission.id
						)}
						on:change={(e) => onCheckChange(e, permission)} />
					{permission.name}
				</label>
			</div>
		{/each}
	</Dialog>
</form>
