<script>
	import {onMount, tick} from 'svelte';
	import timeSince from '../utils/timeSince';
	import {isDocument, isVideo} from '../utils/fileUtils';

	import {updateLink} from '../api/links';
	import {
		getVideosFromLocation,
		updateVideo,
		deleteVideo,
	} from '../api/videos';
	import {getVideos} from '../api/videos.js';
	import {getDocuments, getDocumentsFromLocation} from '../api/documents';
	import {updateTextbox, getTextboxes} from '../api/textboxes';
	import {getShortUserGroups, postUserGroup} from '../api/usergroups.js';
	import {getPictures, getPicturesFromLocation} from '../api/pictures.js';

	import {
		moveContent,
		getModules,
		updateModule,
		getModule,
		linkContent,
		getShortModules,
		unlinkContent,
		getModulesFromLocation,
		updateContent,
		getItemModules,
	} from '../api/modules.js';

	import ProgressBar from '../components/ProgressBar.svelte';
	import Page from '../components/Page.svelte';
	import VideoPlayer from '../components/VideoPlayer.svelte';
	import Button from '../components/Button.svelte';
	import VideoDialog from '../components/dialogs/VideoDialog.svelte';
	import ModuleDialog from '../components/dialogs/ModuleDialog.svelte';
	import TextboxDialog from '../components/dialogs/TextboxDialog.svelte';
	import DocumentDialog from '../components/dialogs/DocumentDialog.svelte';
	import LinkDialog from '../components/widgetdialogs/AddLinkWidget.svelte';
	import VideoPreviewModal from '../components/modals/VideoPreviewModal.svelte';
	import {getLocationRoot, getLocations} from '../api/locations';
	import {user} from '../stores/user';
	import {getMimeColor} from '../utils/mime.js';
	import PictureDialog from '../components/dialogs/PictureDialog.svelte';

	let video;
	let openVideoDialog;

	let previewVideo;
	let openVideoPreviewModal;

	let document;
	let openDocumentDialog;

	let module;
	let openModuleDialog;

	let textbox;
	let openTextboxDialog;

	let link;
	let openLinkDialog;

	let fromAddButton = false;
	$: moduleId = moduleStack.at(-1)?.id;

	let picture;
	let openPictureDialog;

	let originalModules = [];
	let modules = [];
	let groups = [];
	let allModules = [];
	let itemModules = [];

	let originalVideos = [];
	let videos = [];
	let videoSearchText = '';

	let pictures = [];

	let originalDocuments = [];
	let documents = [];
	let documentSearchText = '';

	let locations = [];

	let moduleStack = [];

	let groupContainerLeft = 0;
	let groupContainerTop = 0;
	let showGroupContainer = false;
	let addNewGroup = false;
	let newGroupName;

	let accessType;
	let selectedGroups = [];

	let selected = 'videos';

	let contentContainer;

	let isGlobal;

	$: if (isGlobal) {
		loadLocations();
		if (selected === 'items') {
			selected = 'modules';
		}
	}

	let edit = false;
	let selectBox = null;

	let mouseDownModule;
	let hoveredElement;

	//let listMode = 'list';

	let selectedElement = null;

	let boxNode;

	let hideVideoChooser = true;
	let hideDocumentChooser = true;

	const loadGroups = async () => {
		groups = await getShortUserGroups();
	};

	const loadLocations = async () => {
		const res = await getLocations();
		if (res.records) {
			locations = res.records;
		}
		locations.forEach((l) => {
			l.open = false;
			l.videos = [];
			l.documents = [];
			l.pictures = [];
			l.modules = [];
		});
	};

	const loadLocationVideos = async (id) => {
		const res = await getVideosFromLocation({id});
		const videos = res.records;
		videos.forEach((v) => {
			v.type = 'video';
		});
		return videos;
	};

	const loadLocationDocuments = async (id) => {
		const res = await getDocumentsFromLocation({id});
		const documents = res.records;
		documents.forEach((d) => {
			d.type = 'document';
		});
		return documents;
	};

	const loadLocationPictures = async (id) => {
		const res = await getPicturesFromLocation({id});
		const pictures = res.records;
		pictures.forEach((p) => {
			p.type = 'picture';
		});
		return pictures;
	};

	const loadLocationModules = async (id) => {
		const res = await getModulesFromLocation({id});
		const modules = res.records;
		modules.forEach((m) => {
			m.type = 'module';
		});
		return modules;
	};

	const loadVideos = async () => {
		const res = await getVideos();
		videos = res.records.reverse();
		videos.forEach((v) => (v.type = 'video'));
		originalVideos = JSON.parse(JSON.stringify(videos));
	};

	const loadDocuments = async () => {
		const res = await getDocuments();
		documents = res.records.reverse();
		documents.forEach((d) => (d.type = 'document'));
		originalDocuments = JSON.parse(JSON.stringify(documents));
	};

	const loadPictures = async () => {
		const res = await getPictures();
		pictures = res.records.reverse();
		pictures.forEach((p) => (p.type = 'picture'));
	};

	const loadAllModules = async () => {
		const res = await getModules();
		if (res.records) {
			allModules = res.records.reverse();
		}
		allModules.forEach((m) => {
			m.type = 'module';
			m.origin = 'library';
		});
		return allModules;
	};

	const loadAllItems = async () => {
		const res = await getItemModules();
		if (res.records) {
			itemModules = res.records.reverse();
		}
		itemModules.forEach((m) => {
			m.type = 'module';
			m.origin = 'library';
		});
		return itemModules;
	};

	const updateOriginals = () => {
		originalModules = JSON.parse(JSON.stringify(modules));
	};

	const loadRootModules = async () => {
		const location = await getLocationRoot($user.locationId);
		updateContentPanel(location);

		moduleStack = [
			...moduleStack,
			{id: location.rootModuleId, name: location.name},
		];
	};

	const updateContentPanel = (mod) => {
		modules = mod.content.map((e) => {
			if (e.module !== null) {
				return {
					...e.module,
					contentId: e.id,
					parentId: e.parentId,
					type: 'module',
				};
			} else if (e.video !== null) {
				return {
					...e.video,
					title: e.name ?? e.video.title,
					description: e.description ?? e.video.description,
					contentId: e.id,
					parentId: e.parentId,
					type: 'video',
				};
			} else if (e.textbox !== null) {
				return {
					...e.textbox,
					contentId: e.id,
					parentId: e.parentId,
					type: 'textbox',
				};
			} else if (e.link !== null) {
				return {
					...e.link,
					contentId: e.id,
					parentId: e.parentId,
					type: 'link',
				};
			} else if (e.document !== null) {
				return {
					...e.document,
					contentId: e.id,
					parentId: e.parentId,
					type: 'document',
				};
			} else if (e.picture !== null) {
				return {
					...e.picture,
					contentId: e.id,
					parentId: e.parentId,
					type: 'picture',
				};
			}
			return {type: 'unknown'};
		});
		updateOriginals();
	};

	const loadModule = async (id) => {
		const mod = await getModule(id);

		updateContentPanel(mod);

		console.log(mod);

		return mod;
	};

	const handleModuleUpdate = async (mod, force = false) => {
		// Only update if the module actually changed
		const comp = originalModules.find((m) => m.id === mod.id);

		if (
			!force &&
			comp.name === mod.name &&
			comp.description === mod.description
		) {
			return;
		}

		await updateModule(mod);
		updateOriginals();
	};

	const handleKeyDown = (e) => {
		if (e.code === 'Enter') {
			e.target.blur();
		}
	};

	const handleBlur = async (mod) => {
		switch (mod.type) {
			case 'module':
				handleModuleUpdate(mod);
				break;

			case 'video':
				if (mod.originalLocation !== $user?.locationId) {
					updateContent({
						id: mod.contentId,
						name: mod.title,
						description: mod.description,
					});
				} else {
					await updateVideo(mod);
				}
				break;

			case 'textbox':
				await updateTextbox(mod);
				break;

			case 'link':
				await updateLink(mod);
				break;
			default:
				break;
		}
	};

	const updateImage = async (mod) => {
		await handleModuleUpdate(mod, true);
		await loadModule(moduleStack.at(-1).id);
	};

	const handleImageDrop = (e, mod) => {
		if (e.dataTransfer.files.length > 0) {
			mod.img = URL.createObjectURL(e.dataTransfer.files[0]);
			mod.files = e.dataTransfer.files;
			modules = modules;
			updateImage(mod);
		}
	};

	const handleEnterModuleClick = async (clickedMod) => {
		if (clickedMod.type !== 'module') {
			return;
		}
		showGroupContainer = false;
		const mod = await loadModule(clickedMod.id);
		moduleStack = [...moduleStack, mod];
	};

	const isRootModule = () => {
		return moduleStack.length === 1;
	};

	const handleDeleteModuleClick = async (clickedMod) => {
		switch (clickedMod.type) {
			case 'module':
				if (
					!confirm(
						`Remove module ${clickedMod.name || clickedMod.id}?`
					)
				) {
					return;
				}

				if (isRootModule()) {
					const count = modules.filter(
						(m) => m.id === clickedMod.id
					).length;
					if (count <= 1) {
						clickedMod.hideFromHome = 1;
						await updateModule(clickedMod);
					}
				}

				await unlinkContent(clickedMod.contentId);
				await loadModule(moduleStack.at(-1).id);

				break;

			case 'video':
				if (
					!confirm(
						`Remove video ${clickedMod.title || clickedMod.id}?`
					)
				) {
					return;
				}
				await unlinkContent(clickedMod.contentId);
				await loadModule(moduleStack.at(-1).id);
				await loadVideos();
				break;

			case 'document':
				if (
					!confirm(
						`Remove document ${clickedMod.title || clickedMod.id}?`
					)
				) {
					return;
				}
				await unlinkContent(clickedMod.contentId);
				await loadModule(moduleStack.at(-1).id);
				break;

			case 'textbox':
				if (
					!confirm(
						`Remove textbox ${clickedMod.name || clickedMod.id}?`
					)
				) {
					return;
				}
				await unlinkContent(clickedMod.contentId);
				await loadModule(moduleStack.at(-1).id);
				break;

			case 'link':
				if (
					!confirm(`Remove link ${clickedMod.name || clickedMod.id}?`)
				) {
					return;
				}
				await unlinkContent(clickedMod.contentId);
				await loadModule(moduleStack.at(-1).id);
				break;

			case 'picture':
				if (!confirm('Remove picture?')) {
					return;
				}
				await unlinkContent(clickedMod.contentId);
				await loadModule(moduleStack.at(-1).id);
				break;
			default:
				break;
		}
	};

	const handleStackBack = async (clickedMod) => {
		// No need to update if this is the last crumb
		if (clickedMod === moduleStack.at(-1)) {
			return;
		}

		await loadModule(clickedMod.id);

		moduleStack = moduleStack.slice(0, moduleStack.indexOf(clickedMod) + 1);
	};

	const handleDrop = (e) => {
		if (e.dataTransfer.files.length > 0) {
			const file = e.dataTransfer.files[0];

			if (isVideo(file.type)) {
				video.files = e.dataTransfer.files;
				video.title = file.name.split('.')[0];
				openVideoDialog();
			} else if (isDocument(file.type)) {
				document.files = e.dataTransfer.files;
				document.name = file.name.split('.')[0];
				openDocumentDialog();
			}
		}
	};

	const handleEditGroupClick = (e, mod) => {
		groupContainerLeft = e.target.offsetLeft + 46;
		groupContainerTop = e.target.offsetTop;
		showGroupContainer = true;
		if (mod.userGroups.length === 0) {
			accessType = 1;
		} else {
			accessType = 2;
		}
		selectedGroups = mod.userGroups;
	};

	const handleBackgroundClick = (e) => {
		if (
			!e.target.className.includes('group') &&
			!e.target.className.includes('usergroup-manager') &&
			!e.target.parentNode.className.includes('usergroup-manager') &&
			!e.target.parentNode.parentNode.className.includes(
				'usergroup-manager'
			)
		) {
			showGroupContainer = false;
		}

		if (!e.target.className.includes('element-video')) {
			videos.forEach((v) => {
				v.selected = false;
			});
			videos = videos;
		}

		if (
			boxNode &&
			!boxNode.contains(e.target) &&
			!e.target.className.includes('background')
		) {
			selectedElement = null;
		}
	};

	const handleNewModuleClick = async () => {
		if (isRootModule()) {
			module.hideFromHome = 0;
		} else {
			module.hideFromHome = 1;
		}

		openModuleDialog();
	};

	const handleNewTextboxClick = async () => {
		openTextboxDialog();
	};

	const handleNewLinkClick = async () => {
		openLinkDialog();
	};

	const handleNewVideoClick = async () => {
		video = {};

		video.moduleId = moduleStack.at(-1).id;

		hideVideoChooser = false;

		openVideoDialog();
	};

	const handleNewDocumentClick = async () => {
		document = {};

		document.moduleId = moduleStack.at(-1).id;

		hideDocumentChooser = false;

		openDocumentDialog();
	};

	const handleNewPicutreClick = async () => {
		picture = {};
		openPictureDialog();
	};

	const submitGroup = async () => {
		await postUserGroup({name: newGroupName});
		newGroupName = '';
		addNewGroup = false;

		loadGroups();
	};

	const handleDragOver = (e) => {
		e.preventDefault();
	};

	const handleVideoSearchTextChange = () => {
		const searchTerm = videoSearchText.toLowerCase();

		videos = originalVideos.filter((v) => {
			return (
				v.title.toLowerCase().includes(searchTerm) ||
				v.description?.toLowerCase().includes(searchTerm) ||
				v.modules.some((m) => m.name.toLowerCase().includes(searchTerm))
			);
		});
	};

	const handleDocumentSearchTextChange = () => {
		const searchTerm = documentSearchText.toLowerCase();

		documents = originalDocuments.filter((v) => {
			return (
				v.name.toLowerCase().includes(searchTerm) ||
				v.modules.some((m) => m.name.toLowerCase().includes(searchTerm))
			);
		});
	};

	const handleVideoSubmit = async () => {
		await loadVideos();
		await loadModule(moduleStack.at(-1).id);
		hideVideoChooser = true;
	};

	const handleDocumentSubmit = async () => {
		await loadDocuments();
		await loadModule(moduleStack.at(-1).id);
		hideDocumentChooser = true;
	};

	const handlePictureSubmit = async () => {
		await loadPictures();
	};

	const handleTextboxSubmit = async () => {
		const textboxes = await getTextboxes(0, 1000, 'createdAt:asc');
		const newTextbox = textboxes.records.at(-1);
		await linkContent({
			parentId: moduleStack.at(-1).id,
			textboxId: newTextbox.id,
		});
		await loadModule(moduleStack.at(-1).id);

		await tick();
		contentContainer.scrollTop = contentContainer.scrollHeight;
	};

	const handleLinkSubmit = async () => {
		await loadModule(moduleStack.at(-1).id);

		await tick();
		contentContainer.scrollTop = contentContainer.scrollHeight;
	};

	const handleModuleSubmit = async () => {
		if (fromAddButton) {
			if (selected === 'items') {
				await loadAllItems();
			} else if (selected === 'modules') {
				await loadAllModules();
			}
			return;
		}

		const modules = await getShortModules();
		const newModule = modules.at(-1);
		await linkContent({
			parentId: moduleStack.at(-1).id,
			moduleId: newModule.id,
		});
		await loadModule(moduleStack.at(-1).id);

		await tick();
		contentContainer.scrollTop = contentContainer.scrollHeight;
	};

	const handleVideosClick = async () => {
		selected = 'videos';
		await loadVideos();
	};

	const handleDocumentsClick = async () => {
		selected = 'documents';
		await loadDocuments();
	};

	const handlePicturesClick = async () => {
		selected = 'pictures';
		await loadPictures();
	};

	const handleModulesClick = async () => {
		selected = 'modules';
		await loadAllModules();
	};

	const handleItemsClick = async () => {
		selected = 'items';
		await loadAllItems();
	};

	const handleLocationVideosClick = async (loc) => {
		loc.open = !loc.open;
		if (loc.open) {
			loc.videos = await loadLocationVideos(loc.id);
		}

		locations = locations;
	};

	const handleLocationDocumentsClick = async (loc) => {
		loc.open = !loc.open;
		if (loc.open) {
			loc.documents = await loadLocationDocuments(loc.id);
		}

		locations = locations;
	};

	const handleLocationPicturesClick = async (loc) => {
		loc.open = !loc.open;
		if (loc.open) {
			loc.pictures = await loadLocationPictures(loc.id);
		}

		locations = locations;
	};

	const handleLocationModulesClick = async (loc) => {
		loc.open = !loc.open;
		if (loc.open) {
			loc.modules = await loadLocationModules(loc.id);
		}

		locations = locations;
	};

	const handleEdit = () => {
		edit = !edit;
	};

	const alertContentInModule = () => {
		alert(
			'Cannot add this content type to the home screen. Click on a module to add it in there'
		);
	};

	const handleContentDrop = async (e) => {
		const dropData = e.dataTransfer.getData('text');
		if (!dropData) {
			mouseDownModule = null;
			hoveredElement = null;
			return;
		}

		const content = JSON.parse(dropData);

		if (content.parentId) {
			await moveContent({
				id: mouseDownModule.contentId,
				afterId: hoveredElement.contentId,
				parentId: hoveredElement.parentId,
			});
		} else {
			switch (content.type) {
				case 'video': {
					const linkedVideo = await linkContent({
						parentId: moduleStack.at(-1).id,
						videoId: content.id,
					});
					if (hoveredElement) {
						await moveContent({
							parentId: hoveredElement.parentId,
							id: linkedVideo.id,
							afterId: hoveredElement.contentId,
						});
					}
					break;
				}

				case 'document': {
					const linkedDocument = await linkContent({
						parentId: moduleStack.at(-1).id,
						documentId: content.id,
					});
					if (hoveredElement) {
						await moveContent({
							parentId: hoveredElement.parentId,
							id: linkedDocument.id,
							afterId: hoveredElement.contentId,
						});
					}
					break;
				}

				case 'picture': {
					const linkedPicture = await linkContent({
						parentId: moduleStack.at(-1).id,
						pictureId: content.id,
					});
					if (hoveredElement) {
						await moveContent({
							parentId: hoveredElement.parentId,
							id: linkedPicture.id,
							afterId: hoveredElement.contentId,
						});
					}
					break;
				}

				case 'module': {
					const linkedModule = await linkContent({
						parentId: moduleStack.at(-1).id,
						moduleId: content.id,
					});
					if (hoveredElement) {
						await moveContent({
							parentId: hoveredElement.parentId,
							id: linkedModule.id,
							afterId: hoveredElement.contentId,
						});
					}
					if (isRootModule()) {
						content.hideFromHome = 0;
						await updateModule(content);
					}

					break;
				}
				default:
					break;
			}
		}

		mouseDownModule = null;
		hoveredElement = null;

		await loadModule(moduleStack.at(-1).id);
	};

	const handleMouseUp = () => {
		mouseDownModule = null;
		hoveredElement = null;
		selectBox = null;
	};

	const handleDragstart = (e, mod) => {
		if (edit) {
			e.preventDefault();
			return;
		}
		mouseDownModule = mod;
		e.dataTransfer.setDragImage(e.target, 0, 0);
		e.dataTransfer.setData('text', JSON.stringify(mod));
	};

	onMount(() => {
		loadRootModules();
		loadGroups();
		loadVideos();
	});
</script>

<svelte:window
	on:dragover={handleDragOver}
	on:drop|preventDefault={handleDrop}
	on:click={handleBackgroundClick}
	on:mouseup={handleMouseUp}
	on:mousemove={(e) => {
		if (selectBox) {
			selectBox = {
				...selectBox,
				endX: e.clientX,
				endY: e.clientY,
			};
		}
	}} />

<Page
	title="Content"
	description="Manage the content in your location"
	crumbs={['content']}>
	<div class="container">
		<div>
			<div class="button-container">
				<span style="margin-left: auto">
					<!-- {#if listMode === 'list'}
						<Button on:click={() => (listMode = 'tree')}
							><span
								class="material-icons"
								style="font-size: 16px">account_tree</span
							></Button>
					{:else if listMode === 'tree'}
						<Button on:click={() => (listMode = 'list')}
							><span
								class="material-icons"
								style="font-size: 16px">list</span
							></Button>
					{/if} -->

					<Button on:click={handleEdit} selected={edit}>Edit</Button>
				</span>
			</div>
			<div class="crumbs">
				{#each moduleStack as mod}
					<span
						class="crumb"
						class:last={mod === moduleStack.at(-1)}
						on:click={() => handleStackBack(mod)}
						>{mod === moduleStack.at(0) ? '' : '/ '}{mod.name}
					</span>
				{/each}
			</div>
			<div
				class="contentcontainer"
				bind:this={contentContainer}
				on:dragover={(e) => e.preventDefault()}
				on:drop|preventDefault={handleContentDrop}>
				{#if modules.length === 0}
					<div class="empty">
						This module is empty, drag in content from the right, or
						press one of the buttons below to add some content to it
					</div>
				{/if}
				{#each modules as mod}
					<div
						class="contentbox"
						class:dropper={mouseDownModule === mod}
						style={hoveredElement === mod &&
							'border-bottom: 1px solid #880000'}
						on:click={() => !edit && handleEnterModuleClick(mod)}
						on:dragover={() => (hoveredElement = mod)}
						on:dragstart={(e) => handleDragstart(e, mod)}
						draggable={true}>
						{#if mod.type === 'module'}
							{#if mod.thumbnailUrl || mod.img}
								<img
									class="image"
									alt="epic"
									src={mod.thumbnailUrl || mod.img}
									draggable="false"
									on:dragover={(e) => e.preventDefault()}
									on:drop|preventDefault={(e) =>
										handleImageDrop(e, mod)} />
							{:else}
								<div
									class="image "
									on:dragover={(e) => e.preventDefault()}
									on:drop|preventDefault={(e) =>
										handleImageDrop(e, mod)}>
									<div class="material-icons centered-icon">
										broken_image
									</div>
								</div>
							{/if}
						{:else if mod.type === 'video'}
							{#if mod.videoUrl}
								<div class="image">
									<VideoPlayer
										imageSrc={mod.thumbnailUrl}
										on:fullscreen={() => {
											previewVideo = mod;
											openVideoPreviewModal();
										}} />
								</div>
							{:else}
								<div
									class="image"
									on:dragover={(e) => e.preventDefault()}
									on:drop|preventDefault={(e) =>
										handleImageDrop(e, mod)} />
							{/if}
						{:else if mod.type === 'document'}
							<div
								class="image "
								on:dragover={(e) => e.preventDefault()}
								on:drop|preventDefault={(e) =>
									handleImageDrop(e, mod)}>
								<div
									class="material-icons centered-icon"
									style={`color: ${getMimeColor(
										mod.mimeType
									)}`}>
									description
								</div>
							</div>
						{:else if mod.type === 'textbox'}
							<div
								class="image "
								on:dragover={(e) => e.preventDefault()}
								on:drop|preventDefault={(e) =>
									handleImageDrop(e, mod)}>
								<div class="material-icons centered-icon">
									notes
								</div>
							</div>
						{:else if mod.type === 'link'}
							<div
								class="image "
								on:dragover={(e) => e.preventDefault()}
								on:drop|preventDefault={(e) =>
									handleImageDrop(e, mod)}>
								<div class="material-icons centered-icon">
									link
								</div>
							</div>
						{:else if mod.type === 'picture'}
							<div
								class="image "
								on:dragover={(e) => e.preventDefault()}
								on:drop|preventDefault={(e) =>
									handleImageDrop(e, mod)}>
								<img
									draggable="false"
									class="image"
									src={mod.url}
									alt="user-content" />
							</div>
						{:else}
							<div
								class="image"
								on:dragover={(e) => e.preventDefault()}
								on:drop|preventDefault={(e) =>
									handleImageDrop(e, mod)} />
						{/if}

						<div class="meta">
							{#if mod.type === 'video' || mod.type === 'textbox'}
								<input
									type="text"
									class="name"
									class:edit
									disabled={!edit}
									bind:value={mod.title}
									on:keydown={handleKeyDown}
									on:blur={() => handleBlur(mod)} />
							{:else}
								<input
									type="text"
									class="name"
									class:edit
									disabled={!edit}
									bind:value={mod.name}
									on:keydown={handleKeyDown}
									on:blur={(e) => handleBlur(mod, e)} />
							{/if}
							<div class="createdAt" style="margin-left:2px">
								{timeSince(mod.createdAt)}
							</div>
							{#if mod.type === 'textbox'}
								<textarea
									class="description"
									class:edit
									disabled={!edit}
									initialValue="-"
									spellcheck="false"
									bind:value={mod.text}
									on:keydown={handleKeyDown}
									on:blur={() => handleBlur(mod)} />
							{:else if mod.type === 'link'}
								<textarea
									class="description"
									class:edit
									disabled={!edit}
									initialValue="-"
									spellcheck="false"
									bind:value={mod.url}
									on:keydown={handleKeyDown}
									on:blur={(e) => handleBlur(mod, e)} />
							{:else}
								<textarea
									class="description"
									class:edit
									disabled={!edit}
									initialValue="-"
									spellcheck="false"
									bind:value={mod.description}
									on:keydown={handleKeyDown}
									on:blur={() => handleBlur(mod)} />
							{/if}
						</div>
						<div class="button-area">
							<!-- <div
								class="material-icons buttons group"
								title="Manage user groups"
								on:click|stopPropagation={(e) =>
									handleEditGroupClick(e, mod)}>
								manage_accounts
							</div> -->
							<div
								class="material-icons buttons"
								title="Remove from module"
								on:click|stopPropagation={() =>
									handleDeleteModuleClick(mod)}>
								delete
							</div>
						</div>
					</div>
				{/each}
			</div>
			<div class="bottom">
				<button
					class="type"
					on:click={() => {
						handleNewModuleClick();
						module.type = 'module';
						fromAddButton = false;
					}}>
					<div class="material-icons">folder</div>
					<div class="add-text">Module</div>
				</button>
				<button
					class="type"
					on:click={() => {
						handleNewModuleClick();
						module.type = 'item';
						fromAddButton = false;
					}}>
					<div class="material-icons">view_in_ar</div>
					<div class="add-text">Item</div>
				</button>
				<button class="type" on:click={handleNewTextboxClick}>
					<div class="material-icons">subject</div>
					<div class="add-text">Textbox</div>
				</button>
				<button class="type" on:click={handleNewLinkClick}>
					<div class="material-icons">link</div>
					<div class="add-text">Link</div>
				</button>
			</div>
		</div>

		{#if showGroupContainer}
			<div
				class="usergroup-manager"
				style={`left: ${groupContainerLeft}px; top: ${groupContainerTop}px`}>
				Access
				<select bind:value={accessType}>
					<option value={1}>Everyone</option>
					<option value={2}>Selected groups</option>
				</select>

				{#if accessType === 2}
					{#each groups as group}
						<label>
							<input
								type="checkbox"
								checked={selectedGroups.some(
									(g) => group.id === g.id
								)} />
							{group.name}
						</label>
						<br />
					{/each}

					{#if addNewGroup}
						<div>
							<input type="checkbox" />
							<input
								bind:value={newGroupName}
								on:keydown={(e) =>
									e.code === 'Enter' && submitGroup()} />
						</div>
					{:else}
						<button
							class="minibutt"
							on:click={() => (addNewGroup = true)}
							>+ New group</button>
					{/if}
				{/if}
			</div>
		{/if}
		<div class="stretch">
			<div class="right-bar">
				<Button
					on:click={handleVideosClick}
					selected={selected === 'videos'}>Videos</Button>
				<Button
					on:click={handleDocumentsClick}
					selected={selected === 'documents'}>
					Documents</Button>
				<Button
					on:click={handlePicturesClick}
					selected={selected === 'pictures'}>
					Pictures</Button>
				<Button
					on:click={handleModulesClick}
					selected={selected === 'modules'}>
					Modules</Button>
				{#if !isGlobal}
					<Button
						on:click={handleItemsClick}
						selected={selected === 'items'}>
						Items</Button>
				{/if}
				{#if selected === 'videos'}
					<input
						type="text"
						class="search-text"
						placeholder="Search for a video..."
						on:keyup={handleVideoSearchTextChange}
						bind:value={videoSearchText} />
				{:else if selected === 'documents'}
					<input
						type="text"
						class="search-text"
						placeholder="Search for a document..."
						on:keyup={handleDocumentSearchTextChange}
						bind:value={documentSearchText} />
				{/if}
				<select class="global-select" bind:value={isGlobal}>
					<option value={false}>Local</option>
					<option value={true}>Global</option>
				</select>
			</div>
			<div class="expander">
				<!-- <span class="material-icons">expand_more</span> -->
				<!-- <span>Sort by date</span> -->
			</div>
			{#if isGlobal}
				<div class="container-video">
					{#if selected === 'videos'}
						{#each locations as loc}
							<div
								class="location"
								on:click={() => handleLocationVideosClick(loc)}>
								<div class="material-icons caret">
									{loc.open ? 'expand_more' : 'chevron_right'}
								</div>
								<div>
									{loc.name}
								</div>
								<div class="location-videocount">
									{loc.videoCount}
									{selected}
								</div>
							</div>
							{#if loc.open}
								{#each loc.videos as video}
									<div
										class="element-video"
										style={selectedElement === video &&
											'background-color:#ccc'}
										draggable="true"
										on:click|stopPropagation={() => {
											selectedElement = video;
										}}
										on:dragstart={(e) =>
											e.dataTransfer.setData(
												'text',
												JSON.stringify(video)
											)}>
										{#if video.videoUrl}
											<VideoPlayer
												imageSrc={video.thumbnailUrl}
												on:fullscreen={() => {
													previewVideo = video;
													openVideoPreviewModal();
												}} />
										{:else}
											<div class="image" />
										{/if}
										<div>
											<div class="video-name">
												{video.title}
											</div>
											<div class="video-description">
												{video.description ?? '-'}
											</div>
										</div>
									</div>
								{/each}
							{/if}
						{/each}
					{:else if selected === 'documents'}
						{#each locations as loc}
							<div
								class="location"
								on:click={() =>
									handleLocationDocumentsClick(loc)}>
								<div class="material-icons caret">
									{loc.open ? 'expand_more' : 'chevron_right'}
								</div>
								<div>
									{loc.name}
								</div>
								<div class="location-videocount">
									{loc.documentCount}
									{selected}
								</div>
							</div>
							{#if loc.open}
								{#each loc.documents as doc}
									<div
										class="element-video"
										draggable="true"
										on:dragstart={(e) =>
											e.dataTransfer.setData(
												'text',
												JSON.stringify(doc)
											)}
										on:click|stopPropagation={() => {
											selectedElement = doc;
										}}>
										<div>
											<div class="image" />
										</div>
										<div
											style="position:relative;width:100%">
											<div class="video-name">
												{doc.name}
											</div>
											<div class="video-description">
												{doc.mimeType}
											</div>
										</div>
									</div>
								{/each}
							{/if}
						{/each}
					{:else if selected === 'pictures'}
						{#each locations as loc}
							<div
								class="location"
								on:click={() =>
									handleLocationPicturesClick(loc)}>
								<div class="material-icons caret">
									{loc.open ? 'expand_more' : 'chevron_right'}
								</div>
								<div>
									{loc.name}
								</div>
								<div class="location-videocount">
									{loc.pictureCount}
									{selected}
								</div>
							</div>
							{#if loc.open}
								{#each loc.pictures as pic}
									<div
										class="element-video"
										draggable="true"
										on:dragstart={(e) =>
											e.dataTransfer.setData(
												'text',
												JSON.stringify(pic)
											)}>
										{#if pic.url}
											<img
												class="image"
												src={pic.url}
												alt="user-defined" />
										{:else}
											<div class="image" />
										{/if}
									</div>
								{/each}
							{/if}
						{/each}
					{:else if selected === 'modules'}
						{#each locations as loc}
							<div
								class="location"
								on:click={() =>
									handleLocationModulesClick(loc)}>
								<div class="material-icons caret">
									{loc.open ? 'expand_more' : 'chevron_right'}
								</div>
								<div>
									{loc.name}
								</div>
								<div class="location-videocount">
									{loc.moduleCount}
									{selected}
								</div>
							</div>
							{#if loc.open}
								{#each loc.modules as mod}
									<div
										class="element-video"
										draggable="true"
										on:dragstart={(e) =>
											e.dataTransfer.setData(
												'text',
												JSON.stringify(mod)
											)}>
										{#if mod.thumbnailUrl}
											<img
												class="image"
												src={mod.thumbnailUrl}
												alt="" />
										{:else}
											<div class="image" />
										{/if}
										<div
											style="position:relative;width:100%">
											<div class="video-name">
												{mod.name}
											</div>
											<div class="video-description">
												{mod.description}
											</div>
										</div>
									</div>
								{/each}
							{/if}
						{/each}
					{/if}
				</div>
			{:else if selected === 'videos'}
				<div
					class="container-video"
					class:infoOpen={selectedElement}
					on:mousedown|self={(e) => {
						selectBox = {
							startX: e.clientX,
							startY: e.clientY,
						};
					}}>
					{#if videos.length > 0}
						{#each videos as video}
							<div
								class="element-video"
								style={selectedElement === video &&
									'background-color:#ccc'}
								draggable="true"
								on:click|stopPropagation={() => {
									selectedElement = video;
								}}
								on:dragstart={(e) =>
									e.dataTransfer.setData(
										'text',
										JSON.stringify(video)
									)}>
								{#if video.videoUrl}
									<VideoPlayer
										imageSrc={video.thumbnailUrl}
										on:fullscreen={() => {
											previewVideo = video;
											openVideoPreviewModal();
										}} />
								{:else}
									<div class="image">
										<div class="processing">
											<div class="processing-text">
												Video is processing
											</div>
											<ProgressBar
												progress={video.percent}
												thin />
										</div>
									</div>
								{/if}
								<div>
									<div class="video-name">{video.title}</div>
									<div class="video-description">
										{video.description ?? '-'}
									</div>
								</div>
							</div>
						{/each}
					{:else}
						<div class="nocontent">
							There are no videos in this location
						</div>
					{/if}
				</div>
				{#if selectedElement}
					<div class="middle" bind:this={boxNode}>
						{#if selectedElement.videoUrl}
							<div style="width:100px">
								<VideoPlayer
									clickToFullScreen
									imageSrc={selectedElement.thumbnailUrl}
									on:fullscreen={() => {
										previewVideo = selectedElement;
										openVideoPreviewModal();
									}} />
							</div>
						{:else}
							<div class="image" />
						{/if}
						<div style="flex-grow:1">
							<div class="video-name">
								{selectedElement?.title}
							</div>
							<div class="video-description">
								{selectedElement?.description}
							</div>
						</div>
						<div
							class="material-icons buttons"
							on:click={() => {
								video = selectedElement;
								openVideoDialog();
							}}>
							edit
						</div>
						<div
							class="material-icons buttons"
							on:click={async () => {
								if (
									confirm(
										'Are you sure you want to completely delete this video?'
									)
								) {
									await deleteVideo(selectedElement.id);
									await loadVideos();
									selectedElement = null;
								}
							}}>
							delete
						</div>
						<div style="max-width:150px">
							<div class="video-name">In modules</div>
							<div style="font-size:10px">
								{#if selectedElement.modules.length > 0}
									{selectedElement.modules
										.map((m) => m.name)
										.filter((e, i, a) => a.indexOf(e) === i)
										.join(',')}
								{:else}
									-
								{/if}
							</div>
						</div>
					</div>
				{/if}
				<div class="bottom">
					<button class="type" on:click={handleNewVideoClick}>
						<div class="material-icons">videocam</div>
						<div class="add-text">Add video</div>
					</button>
					<div class="dropzone">
						Or drag a video here to upload...
					</div>
				</div>
			{:else if selected === 'documents'}
				<div class="container-video" class:infoOpen={selectedElement}>
					{#if documents.length > 0}
						{#each documents as document}
							<div
								class="element-video"
								draggable="true"
								on:dragstart={(e) =>
									e.dataTransfer.setData(
										'text',
										JSON.stringify(document)
									)}
								on:click|stopPropagation={() => {
									selectedElement = document;
								}}>
								<div
									class="material-icons"
									style={`color:${getMimeColor(
										document.mimeType
									)};`}>
									description
								</div>
								<div style="position:relative;width:100%">
									<div class="video-name">
										{document.name}
									</div>
								</div>
							</div>
						{/each}
					{:else}
						<div class="nocontent">
							There are no documents in this location
						</div>
					{/if}
				</div>
				{#if selectedElement}
					<div class="middle" bind:this={boxNode}>
						<div class="image">
							<div
								class="material-icons"
								style={`color:${getMimeColor(
									selectedElement.mimeType
								)};`}>
								description
							</div>
						</div>
						<div style="flex-grow:1">
							<div class="video-name">
								{selectedElement?.name}
							</div>
							<div class="video-description">
								{selectedElement?.mimeType}
							</div>
						</div>
						<div
							class="material-icons buttons"
							on:click={() => {
								document = selectedElement;
								openDocumentDialog();
							}}>
							edit
						</div>
						<div style="max-width:150px">
							<div class="video-name">In modules</div>
							<div style="font-size:10px">
								{#if selectedElement.modules.length > 0}
									{selectedElement.modules
										.map((m) => m.name)
										.filter((e, i, a) => a.indexOf(e) === i)
										.join(',')}
								{:else}
									-
								{/if}
							</div>
						</div>
					</div>
				{/if}
				<div class="bottom">
					<button class="type" on:click={handleNewDocumentClick}>
						<div class="material-icons">description</div>
						<div class="add-text">Add document</div>
					</button>
					<div class="dropzone">
						Or drag a document here to upload...
					</div>
				</div>
			{:else if selected === 'pictures'}
				<div class="container-video" class:infoOpen={selectedElement}>
					{#if pictures.length > 0}
						{#each pictures as picture}
							<div
								class="element-video"
								draggable="true"
								on:dragstart={(e) =>
									e.dataTransfer.setData(
										'text',
										JSON.stringify(picture)
									)}
								on:click|stopPropagation={() => {
									selectedElement = picture;
								}}>
								{#if picture.url}
									<img
										class="image"
										src={picture.url}
										alt="user-defined" />
								{:else}
									<div class="image" />
								{/if}
							</div>
						{/each}
					{:else}
						<div class="nocontent">
							There are no pictures in this location
						</div>
					{/if}
				</div>
				{#if selectedElement}
					<div class="middle" bind:this={boxNode}>
						{#if selectedElement.thumbnailUrl}
							<img
								class="image"
								src={selectedElement.thumbnailUrl}
								alt="user-defined" />
						{:else}
							<div class="image" />
						{/if}
						<div style="flex-grow:1" />
						<div style="max-width:150px">
							<div class="video-name">In modules</div>
							<div style="font-size:10px">
								{#if selectedElement.modules.length > 0}
									{selectedElement.modules
										.map((m) => m.name)
										.filter((e, i, a) => a.indexOf(e) === i)
										.join(', ')}
								{:else}
									-
								{/if}
							</div>
						</div>
					</div>
				{/if}
				<div class="bottom">
					<button class="type" on:click={handleNewPicutreClick}>
						<div class="material-icons">photo_camera</div>
						<div class="add-text">Add picture</div>
					</button>
					<div class="dropzone">
						Or drag a picture here to upload...
					</div>
				</div>
			{:else if selected === 'modules'}
				<div class="container-video" class:infoOpen={selectedElement}>
					{#if allModules.length > 0}
						{#each allModules as mod}
							<div
								class="element-video"
								draggable="true"
								on:dragstart={(e) =>
									e.dataTransfer.setData(
										'text',
										JSON.stringify(mod)
									)}
								on:click|stopPropagation={() => {
									selectedElement = mod;
								}}>
								{#if mod.thumbnailUrl}
									<img
										class="image"
										src={mod.thumbnailUrl}
										alt="user-defined" />
								{:else}
									<div class="image" />
								{/if}
								<div style="position:relative;width:100%">
									<div class="video-name">{mod.name}</div>
									<div class="video-description">
										{mod.description}
									</div>
								</div>
							</div>
						{/each}
					{:else}
						<div class="nocontent">
							There are no modules in this location
						</div>
					{/if}
				</div>
				{#if selectedElement}
					<div class="middle" bind:this={boxNode}>
						{#if selectedElement.thumbnailUrl}
							<img
								class="image"
								src={selectedElement.thumbnailUrl}
								alt="user-defined" />
						{:else}
							<div class="image" />
						{/if}
						<div style="flex-grow:1">
							<div class="video-name">
								{selectedElement?.name}
							</div>
							<div class="video-description">
								{selectedElement?.description}
							</div>
						</div>
						<div
							class="material-icons buttons"
							on:click={() => {
								module = selectedElement;
								openModuleDialog();
								fromAddButton = true;
							}}>
							edit
						</div>
					</div>
				{/if}
				<div class="bottom">
					<button
						class="type"
						on:click={() => {
							handleNewModuleClick();
							module.type = 'module';
							fromAddButton = true;
						}}>
						<div class="material-icons">folder</div>
						<div class="add-text">Add module</div>
					</button>
				</div>
			{:else if selected === 'items'}
				<div class="container-video" class:infoOpen={selectedElement}>
					{#if itemModules.length > 0}
						{#each itemModules as mod}
							<div
								class="element-video"
								style="width:80px;"
								draggable="true"
								on:dragstart={(e) =>
									e.dataTransfer.setData(
										'text',
										JSON.stringify(mod)
									)}
								on:click|stopPropagation={() => {
									selectedElement = mod;
								}}>
								{#if mod.thumbnailUrl}
									<img
										class="image"
										src={mod.thumbnailUrl}
										alt="user-defined" />
								{:else}
									<div class="image" />
								{/if}
								<div style="position:relative;width:100%">
									<div class="video-name">{mod.name}</div>
									<div class="video-description">
										{mod.description}
									</div>
								</div>
							</div>
						{/each}
					{:else}
						<div class="nocontent">
							There are no items in this location
						</div>
					{/if}
				</div>
				{#if selectedElement}
					<div class="middle" bind:this={boxNode}>
						{#if selectedElement.url}
							<img
								class="image"
								src={selectedElement.url}
								alt="user-defined" />
						{:else}
							<div class="image" />
						{/if}
						<div style="flex-grow:1">
							<div class="video-name">
								{selectedElement?.name}
							</div>
							<div class="video-description">
								{selectedElement?.description}
							</div>
						</div>
						<div
							class="material-icons buttons"
							on:click={() => {
								module = selectedElement;
								openModuleDialog();
								fromAddButton = true;
							}}>
							edit
						</div>
					</div>
				{/if}
				<div class="bottom">
					<button
						class="type"
						on:click={() => {
							handleNewModuleClick();
							module.type = 'item';
							fromAddButton = true;
						}}>
						<div class="material-icons">view_in_ar</div>
						<div class="add-text">Add item</div>
					</button>
				</div>
			{/if}
		</div>
	</div>
	<VideoDialog
		bind:open={openVideoDialog}
		bind:video
		hideChooser={hideVideoChooser}
		on:submit={handleVideoSubmit} />
	<DocumentDialog
		bind:open={openDocumentDialog}
		bind:document
		hideChooser={hideDocumentChooser}
		on:submit={handleDocumentSubmit} />
	<ModuleDialog
		bind:open={openModuleDialog}
		bind:module
		hideHideFromHome
		on:submit={handleModuleSubmit} />
	<TextboxDialog
		bind:open={openTextboxDialog}
		bind:textbox
		on:submit={handleTextboxSubmit} />
	<LinkDialog
		bind:open={openLinkDialog}
		bind:link
		bind:moduleId
		on:submit={handleLinkSubmit} />
	<PictureDialog
		bind:open={openPictureDialog}
		bind:picture
		on:submit={handlePictureSubmit} />
	<VideoPreviewModal bind:open={openVideoPreviewModal} video={previewVideo} />
	{#if selectBox}
		<div
			class="dragbox"
			style="
				left: {Math.min(selectBox.startX, selectBox.endX)}px;
				top: {Math.min(selectBox.startY, selectBox.endY)}px;
				width: {Math.abs(selectBox.endX - selectBox.startX)}px;
				height: {Math.abs(selectBox.endY - selectBox.startY)}px;" />
	{/if}
</Page>

<style>
	.centered-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		color: gray;
	}
	.stretch {
		width: 100%;
	}
	.empty {
		padding: 10px;
	}
	.crumbs {
		font-weight: 500;
		font-size: 12px;
		color: gray;
		margin-bottom: 4px;
		margin-top: 12px;
	}
	.crumb {
		cursor: pointer;
	}
	.crumbs > .last {
		color: #30465e;
	}
	.contentcontainer {
		display: flex;
		flex-direction: column;
		width: 500px;
		height: calc(100vh - 392px);
		border-radius: 8px 8px 0 0;
		font-size: 16px;
		background-color: #fff;
		border: 1px solid #ddd;
		overflow-y: scroll;
	}
	.contentbox {
		padding: 16px;
		display: flex;
		border-bottom: 1px solid #ddd;
		user-select: none;
	}
	.meta {
		flex-grow: 1;
		margin-left: 8px;
	}
	.name {
		margin: 0;
		padding: 4px;
		border: 1px solid transparent;
		width: 100%;
		font-size: 1rem;
		font-weight: 500;
	}
	.video-name {
		font-size: 14px;
		font-weight: 500;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.description {
		margin: 0;
		padding: 4px;
		border: 1px solid transparent;
		resize: none;
		width: 100%;
		font-size: 0.925rem;
	}
	.video-description {
		font-size: 12px;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.createdAt {
		font-size: 10px;
		padding-left: 4px;
		color: gray;
	}
	.image {
		width: 100px;
		height: 100px;
		background-color: white;
		box-shadow: 0 0 4px #ddd;
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.nocontent {
		margin: 0 auto;
		margin-top: 20px;
		text-align: center;
	}
	.processing {
		width: 80%;
		font-size: 12px;
	}
	.processing-text {
		font-size: 12px;
		margin-bottom: 8px;
	}
	.button-area {
		align-self: center;
	}
	.buttons {
		display: block;
		user-select: none;
		cursor: pointer;
		margin-bottom: 4px;
		margin-left: 8px;
		color: #5e7681;
	}
	.usergroup-manager {
		z-index: 1000;
		position: absolute;
		padding: 12px;
		border: 1px solid gray;
		border-radius: 8px;
		background-color: white;
	}
	.minibutt {
		font-size: 12px;
		border: 0;
		background-color: transparent;
		cursor: pointer;
		color: #30465e;
	}
	.button-container {
		display: flex;
		gap: 5px;
		margin-bottom: 14px;
	}
	.container {
		display: flex;
		gap: 20px;
	}
	.container-video {
		overflow-y: auto;
		height: calc(100vh - 392px);
		border: 1px solid #ddd;
		border-radius: 8px 8px 0 0;
		flex-wrap: wrap;
		display: flex;
		justify-content: left;
		align-content: flex-start;
	}
	.infoOpen {
		height: calc(100vh - 513px);
	}
	.container-video::-webkit-scrollbar,
	.contentcontainer::-webkit-scrollbar {
		width: 8px;

		border-radius: 6px;
	}
	.container-video::-webkit-scrollbar-thumb,
	.contentcontainer::-webkit-scrollbar-thumb {
		border-radius: 6px;
		background: #ddd;
	}
	.element-video {
		display: flex;
		flex-direction: column;
		text-align: center;
		width: 100px;
		padding: 6px;
		margin: 4px;
		gap: 5px;
		border-radius: 8px;
	}
	.element-video:hover {
		background-color: #eee;
	}
	.element-video:last-child {
		border-bottom: 0;
	}
	.right-bar {
		display: flex;
		gap: 8px;
		margin-bottom: 8px;
	}
	.search-text {
		width: auto;
		height: 34px;
		margin: 0;
		flex-grow: 1;
	}
	.dropper {
		opacity: 40%;
	}
	.location {
		width: 100%;
		padding: 10px;
		font-size: 16px;
		font-weight: 500;
		border-top: 1px solid #ddd;
		display: flex;
		align-items: center;
		gap: 4px;
		cursor: pointer;
	}
	.location:first-child {
		border-top: none;
	}
	.location:last-child {
		border-bottom: 1px solid #ddd;
	}
	.caret {
		user-select: none;
	}
	.location-videocount {
		font-size: 12px;
		color: #aaa;
	}
	.expander {
		display: flex;
		align-items: center;
		color: #30465e;
		font-size: 12px;
		font-weight: 500;
		margin-left: 10px;
		height: 24px;
		justify-content: end;
	}
	.global-select {
		margin: 0;
		height: 34px;
		padding: 6px;
	}
	.edit {
		padding: 4px;
		border: 1px solid #ccc;
	}
	input[type='text']:disabled,
	textarea:disabled {
		color: black;
		user-select: none;
		pointer-events: none;
	}
	.bottom {
		display: flex;
		margin-top: auto;
		border-left: 1px solid #ccc;
		border-right: 1px solid #ccc;
		border-bottom: 1px solid #ccc;
		border-radius: 0 0 8px 8px;
	}
	.middle {
		display: flex;
		gap: 10px;
		padding: 10px;
		height: 100px;
		border-left: 1px solid #ccc;
		border-right: 1px solid #ccc;
		border-bottom: 1px solid #ccc;
	}
	.type {
		background: none;
		border: none;
		font-size: 16px;
		margin: 10px;
		display: flex;
		flex-direction: column;
		align-items: center;
		cursor: pointer;
		flex-basis: 25%;
	}
	.add-text {
		margin-top: 4px;
	}
	.dropzone {
		font-size: 14px;
		color: gray;
		margin: auto;
		border: 1px dashed gray;
		border-radius: 8px;
		padding: 14px 30px;
	}
	.dragbox {
		position: absolute;
		background-color: rgba(0, 0, 145, 0.4);
		border: 1px solid rgba(0, 0, 200, 1);
	}
</style>
