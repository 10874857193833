<script>
	import {onMount} from 'svelte';
	import {fly} from 'svelte/transition';
	import Page from '../components/Page.svelte';

	import {getImagesForAnnotator} from '../api/images';
	import {getShortItems} from '../api/items';
	import Annotator from '../components/Annotator.svelte';

	const urlParams = new URLSearchParams(window.location.search);
	let queryImages = urlParams.get('images');
	if (queryImages) {
		queryImages = queryImages.split(',');
	} else {
		queryImages = [];
	}
	let items = [];
	let completedBatch = false;
	$: images = [];
	let itemId;
	let mltag;
	let imagesLoaded = false;
	let itemsLoaded = false;

	const fetchImages = async () => {
		const opts = {limit: 1};
		if (!completedBatch && queryImages.length) {
			opts.imageIds = queryImages;
		}

		try {
			const imgs = await getImagesForAnnotator(opts);
			return imgs;
		} catch (err) {
			if (err.code && err.code === 404) {
				return [];
			}
			console.error(err);
			return [];
		}
	};

	const onAnnotatorDepleted = async () => {
		completedBatch = true;
		images = await fetchImages();
		imagesLoaded = true;
	};

	onMount(async () => {
		items = await getShortItems();
		if (items.length) {
			itemId = items[0].id;
			mltag = items[0].tags[0];
		}
		itemsLoaded = true;
		images = await fetchImages();
		imagesLoaded = true;
	});
</script>

<Page title="Annotator" crumbs={['annotator']}>
	<div class="container" in:fly={{y: 10}}>
		{#if imagesLoaded && itemsLoaded}
			<Annotator bind:images onAnnotatorDepleted={onAnnotatorDepleted} />
		{/if}
	</div>
</Page>

<style>
	.container {
		display: flex;
		flex-direction: row;
	}
</style>
