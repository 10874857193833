<script>
	export let annotations;
	export let activated;
</script>

<div>
	{#each annotations as annotation}
		<div class="grid">
			<div
				class="img-overlay-wrap"
				class:selected={activated.includes(annotation.id)}
				on:click={() => {
					if (activated.includes(annotation.id)) {
						activated = activated.filter(
							(i) => i !== annotation.id
						);
					} else {
						activated = [...activated, annotation.id];
					}
				}}>
				<svg>
					<rect
						x="{(annotation.x - annotation.width / 2) * 100}%"
						y="{(annotation.y - annotation.height / 2) * 100}%"
						width="{annotation.width * 100}%"
						height="{annotation.height * 100}%"
						stroke="green"
						stroke-width="2"
						fill-opacity="0" />
					<rect
						x="{(annotation.x - annotation.width / 2) * 100}%"
						y="{(annotation.y - annotation.height / 2) * 100}%"
						width="{annotation.width * 100}%"
						height="{annotation.height * 100}%"
						fill="green"
						fill-opacity="0.4" />
				</svg>
				<img alt="mlimage" class="item" src={annotation.imageUrl} />
			</div>
		</div>
	{/each}
</div>

<style>
	.selected {
		transform: scale(0.9);
		filter: grayscale(100);
	}
	img {
		display: inline-block;
		width: 100px;
		height: 100px;
	}
	.img-overlay-wrap {
		position: relative;
		display: inline-block;
		transition: 0.2s;
	}
	.img-overlay-wrap svg {
		position: absolute;
		width: 100%;
		height: 100%;
	}
	.grid {
		display: inline-block;
		margin: 5px;
	}
</style>
