<script>
	import {createEventDispatcher} from 'svelte';
	import {router} from 'tinro';

	import {deleteBarcodeLink} from '../api/barcodelinks';
	import {convertToItem} from '../api/modules';

	import {module} from '../stores/module';
	import {localstore} from '../stores/localstore';
	import {shortUserGroups} from '../stores/short/usergroups';

	import Page from '../components/Page.svelte';
	import Button from '../components/Button.svelte';
	import Table from '../components/Table.svelte';
	import Topbar from '../components/Topbar.svelte';
	import BarcodeDialog from '../components/dialogs/BarcodeDialog.svelte';
	import ContentContainer from '../components/widgets/ContentContainer.svelte';

	export let moduleId;

	let error = '';
	let restrictToGroups = {};

	const fetchData = async () => {
		await module.fetch(moduleId);
		restrictToGroups = {};
		$module.userGroups.forEach((group) => {
			restrictToGroups[group.id] = true;
		});
	};

	const dispatch = createEventDispatcher();

	const getSelectedGroups = () => {
		return (
			Object.entries(restrictToGroups)
				.filter(([_, isSelected]) => isSelected)
				.map(([id, _]) => id)
				.join(',') || ''
		);
	};

	const onSubmit = async () => {
		try {
			const submitData = {
				...editModule,
				userGroups: getSelectedGroups(),
			};

			if (editModule.id) {
				await module.change(submitData);
			}
			error = '';

			dispatch('submit');
		} catch (e) {
			error = e.error ? e.error : e;
		}
	};
	fetchData();
	shortUserGroups.fetch();

	$: editModule = $module;
	$: restrictToGroups = restrictToGroups;

	export const onConvertToItem = async () => {
		if (
			!confirm(
				'Are you sure you want to convert this module into an item?'
			)
		)
			return;

		await convertToItem(moduleId);
		router.goto(`/location/${$localstore?.locationId}/items/${moduleId}`);
	};

	let openBarcodeDialog;
	const addBarcode = async () => {
		openBarcodeDialog();
	};
	const onDeleteBarcodeLink = async (link) => {
		await deleteBarcodeLink(link.id);
		fetchData();
	};

	const barcodeLinkColumns = [
		{
			title: 'Value',
			value: (barcodeLink) => barcodeLink.value,
		},
	];
	const barcodeLinkActions = [
		{
			title: 'Delete',
			icon: 'delete',
			action: onDeleteBarcodeLink,
		},
	];
</script>

{#if $module}
	<Page title={$module.name}>
		<svelte:fragment slot="toolbar">
			<Button on:click={onConvertToItem}>Convert to item</Button>
		</svelte:fragment>
		<div class="container">
			<div class="left-side">
				<form on:submit|preventDefault={onSubmit}>
					<Topbar small title="About this module" />
					<label>
						Name
						<input
							type="text"
							bind:value={editModule.name}
							required />
					</label>
					<label>
						Description
						<input
							type="text"
							bind:value={editModule.description} />
					</label>
					<label>
						{'Visibility on home screen'}
						<select
							type="text"
							bind:value={editModule.hideFromHome}>
							<option value={0}>Show</option>
							<option value={1}>Hide</option>
						</select>
					</label>
					<label>
						Thumbnail
						{#if editModule.thumbnailUrl}
							<img
								class="thumb"
								src={editModule.thumbnailUrl}
								alt="thumbnail" />
						{/if}
						<input
							accept="image/*"
							type="file"
							class={!editModule.imageUrl && 'fileupload'}
							bind:files={editModule.files} />
					</label>
					<br /><br />
					<label>
						Who can access this module?
						<select bind:value={editModule.restrictAccess}>
							<option value={0}>Everyone</option>
							<option value={1}>Users in these groups...</option>
						</select>
					</label>
					{#if editModule.restrictAccess}
						{#each Object.entries($shortUserGroups) as [userGroupId, name]}
							<label
								><input
									type="checkbox"
									bind:checked={restrictToGroups[
										userGroupId
									]} />{name}</label>
						{/each}
						<br />
					{/if}
					<div>{error}</div>
					<Button submit>Save module</Button>
				</form>
				<br />
				<h3>
					Barcodes linking to this module
					<Button on:click={addBarcode}>Add barcode</Button>
				</h3>
				<Table
					rows={editModule.barcodeLinks}
					columns={barcodeLinkColumns}
					actions={barcodeLinkActions}
					emptyMessage="No barcodes linked to module" />
			</div>
			<div class="right-side">
				<Topbar small title="Content" />
				{#if !editModule.content?.length}
					<p>
						<i>
							There's no content added to this module yet. Add
							some using the buttons below
						</i>
					</p>
				{/if}
				<ContentContainer
					module={editModule}
					onUpdate={fetchData}
					addNew />
			</div>
		</div>
	</Page>
	<BarcodeDialog
		moduleId={moduleId}
		onUpdate={fetchData}
		bind:open={openBarcodeDialog} />
{/if}

<style>
	.container {
		display: flex;
		justify-content: space-between;
	}
	.left-side {
		flex-basis: 40%;
	}
	.right-side {
		flex-basis: 55%;
	}
	.table-container {
		margin-bottom: 30px;
	}
	.thumb {
		max-width: 100%;
		max-height: 95px;
	}
	label {
		font-size: 14px;
		display: block;
	}
	@media screen and (max-width: 1300px) {
		.item-container {
			flex-direction: column;
		}
		.about-item {
			width: 100%;
		}
		.container {
			flex-direction: column;
			grid-template-columns: 100%;
		}
	}
</style>
