import {LocationApi} from './api';

export const getLinks = async (offset, limit) => {
	return get(`/links?offset=${offset}&limit=${limit}`);
};

export const getLink = async (linkId) => {
	return LocationApi.get(`/link/${linkId}`);
};

export const postLink = async (link, moduleId) => {
	return LocationApi.post('/link', {
		url: link.url,
		name: link.name,
		moduleId,
	});
};

export const updateLink = async (link) => {
	return LocationApi.update(`/link/${link.id}`, {
		url: link.url,
		name: link.name,
	});
};

export const deleteLink = async (id) => {
	return LocationApi.delete(`/link/${id}`);
};
