<script>
	import {fly} from 'svelte/transition';

	import StoreTable from '../components/StoreTable.svelte';
	import RoleEditDialog from '../components/dialogs/RoleEditDialog.svelte';
	import Button from '../components/Button.svelte';

	import {roles} from '../stores/roles';

	import Page from '../components/Page.svelte';

	let openRoleDialog;
	let selectedRole;

	const onEdit = (editRole) => {
		selectedRole = editRole;
		openRoleDialog();
	};

	const onAdd = () => {
		selectedRole = null;
		openRoleDialog();
	};

	const onUpdate = () => {
		roles.fetch();
	};

	const columns = [
		{
			title: 'Name',
			value: (row) => row.name,
		},
		{
			title: 'Permissions',
			hide: 'mobile',
			truncate: true,
			value: (row) =>
				row.permissions.map((permission) => permission.name).join(', '),
		},
	];

	const actions = [
		{
			title: 'Edit',
			icon: 'edit',
			action: onEdit,
		},
	];
</script>

<Page
	title="Roles"
	description="An overview of all the permissions each role in the system has.">
	<svelte:fragment slot="toolbar">
		<Button on:click={onAdd}>Add role</Button>
	</svelte:fragment>
	<div in:fly={{y: 10}}>
		<StoreTable
			columns={columns}
			store={roles}
			actions={actions}
			hidePaginator />
	</div>
	<RoleEditDialog
		bind:open={openRoleDialog}
		role={selectedRole}
		on:submit={onUpdate} />
</Page>
