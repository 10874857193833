import {writable} from 'svelte/store';

import {getTags, deleteTag, updateTag} from '../api/tags';

function createTags() {
	const {subscribe, set, update} = writable({count: 0, records: []});
	const fetchTags = async (offset, limit, orderBy) =>
		set(await getTags(offset, limit, orderBy));

	return {
		subscribe,
		fetch: (offset, limit, orderBy) => fetchTags(offset, limit, orderBy),
		delete: async (id) => {
			await deleteTag(id);
			update((arr) => ({
				count: arr.count,
				records: arr.records.filter((tags) => tags.id !== id),
			}));
		},
		change: async (tag) => {
			await updateTag(tag);
			fetchTags(tag.id);
		},
	};
}

export const tags = createTags();
