import {BaseApi} from './api';

export const getServices = async (offset, limit) => {
	return BaseApi.get(`/services?offset=${offset}&limit=${limit}`);
};

export const postService = async (service) => {
	return BaseApi.post('/service', {
		name: service.name,
		ip: service.ip,
		port: service.port,
	});
};

export const updateService = async (service) => {
	return BaseApi.update(`/service/${service.id}`, {
		name: service.name,
		ip: service.ip,
		port: service.port,
	});
};

export const deleteService = async (serviceId) => {
	return BaseApi.delete('/service/', serviceId);
};
