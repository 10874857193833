<script>
	import {onMount} from 'svelte';

	import Select from 'svelte-select';
	import Dialog from '../Dialog.svelte';

	import {
		getPublicModulesForMyLocations,
		linkContent,
	} from '../../api/modules';

	import {filterExisting} from '../../utils/filterExisting';

	let selected;
	let error = '';
	let close;
	export let open;
	export let moduleId;
	export let modules = [];
	export let modulesInModule = [];
	export let onUpdate = () => {};

	onMount(async () => {
		modules = await getPublicModulesForMyLocations(null, true);
		modules = modules.map((module) => {
			module.label = module.name;
			module.value = module.id;
			return module;
		});
	});

	const onSubmit = async () => {
		error = '';
		try {
			await linkContent({
				moduleId: selected.id,
				parentId: moduleId,
			});
			selected = null;
			onUpdate();
			close();
		} catch (e) {
			error = e.error ? e.error : e;
		}
	};
</script>

<form on:submit|preventDefault={onSubmit}>
	<Dialog title="Add module from library" bind:open bind:close bind:error>
		<Select
			bind:value={selected}
			items={filterExisting(modules, modulesInModule)} />
	</Dialog>
</form>

<style>
	:global(.videoLibraryDialog .selectContainer) {
		box-sizing: border-box;
	}
	:global(.videoLibraryDialog .selectContainer input) {
		padding-right: 0 !important;
	}
	:global(.videoLibraryDialog .listContainer) {
		box-sizing: border-box;
	}

	:global(.videoLibraryDialog .mdc-dialog__content),
	:global(.videoLibraryDialog .mdc-dialog__surface) {
		overflow: visible;
	}
	form {
		max-width: 100%;
	}
</style>
