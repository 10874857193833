import {writable} from 'svelte/store';

import {getUsersShort} from '../../api/users';

function createUsers() {
	const {subscribe, set} = writable({});
	return {
		subscribe,
		fetch: async () => {
			const users = await getUsersShort();
			const result = users.reduce((map, obj) => {
				map[obj.id] = obj.name;
				return map;
			}, {});
			return set(result);
		},
	};
}

export const shortUsers = createUsers();
