import {writable} from 'svelte/store';

import {getRoles, updateRole, deleteRole, postRole} from '../api/roles';

function createRoles() {
	const {subscribe, set, update} = writable();
	const fetchRoles = async () => set(await getRoles());

	return {
		subscribe,
		fetch: fetchRoles,
		add: async (role) => {
			await postRole(role);
			fetchRoles();
		},
		change: async (role) => {
			await updateRole(role);
			fetchRoles();
		},
		delete: async (id) => {
			await deleteRole(id);
			update((arr) => arr.filter((role) => role.id !== id));
		},
	};
}

export const roles = createRoles();
