import {LocationApi} from './api';

export const deleteCategory = async (categoryId) => {
	return LocationApi.delete('/category/', categoryId);
};

export const getCategories = async (offset, limit) => {
	return LocationApi.get(`/categories?offset=${offset}&limit=${limit}`);
};

export const getCategoriesShort = async () => {
	return LocationApi.get(`/categories?short=true`);
};

export const postCategory = async (category) => {
	return LocationApi.post('/category', {
		name: category.name,
	});
};

export const updateCategory = async (category) => {
	return LocationApi.update('/category/' + category.id, {
		name: category.name,
	});
};
