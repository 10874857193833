<script>
	import {createEventDispatcher} from 'svelte';
	const dispatch = createEventDispatcher();

	import Dialog from '../Dialog.svelte';

	import {categories} from '../../stores/categories';

	const onSubmit = async () => {
		try {
			if (category.id) {
				await categories.change(category);
			} else {
				await categories.add(category);
			}

			category = null;
			dispatch('submit');
			close();
		} catch (e) {
			error = e.error ? e.error : e;
		}
	};

	$: category = category || {
		id: undefined,
		name: '',
	};

	let error = '';
	let close;
	export let open;
	export let category;
</script>

<form on:submit|preventDefault={onSubmit}>
	<Dialog
		title="{category.id ? 'Edit' : 'Add'} category"
		bind:open
		bind:close
		bind:error>
		<label>
			Name
			<input type="text" bind:value={category.name} required />
		</label>
	</Dialog>
</form>
