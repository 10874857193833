<script>
	import * as mime from 'mime';

	import Page from '../components/Page.svelte';
	import Button from '../components/Button.svelte';
	import StoreTable from '../components/StoreTable.svelte';
	import DocumentDialog from '../components/dialogs/DocumentDialog.svelte';
	import LinkContentDialog from '../components/dialogs/LinkContentDialog.svelte';

	import {documents} from '../stores/documents';

	let openDocumentDialog;

	let onUpdate;
	let selectedDocument;
	let openLinkContentDialog;

	const onAdd = () => {
		selectedDocument = null;
		openDocumentDialog();
	};

	const onEdit = (editDocument) => {
		selectedDocument = editDocument;
		openDocumentDialog();
	};
	const onLink = (document) => {
		selectedDocument = document;
		openLinkContentDialog();
	};

	const onDelete = async (document) => {
		if (
			!confirm(
				`Are you sure you want to delete ${
					document.name || document.id
				}?`
			)
		) {
			return;
		}
		await documents.delete(document.id);
		onUpdate();
	};

	const columns = [
		{
			title: 'Title',
			value: (document) => document.name,
			truncate: true,
			orderKey: 'name',
		},
		{
			title: 'Filetype',
			value: (document) => {
				const extension = mime.getExtension(document.mimeType);
				if (extension) {
					return '.' + extension;
				}
			},
			truncate: true,
		},
		{
			title: 'Creator',
			value: (document) => document.owner.name,
			truncate: true,
			orderKey: 'ownerId',
			hide: 'medium,',
		},
	];

	const actions = [
		{
			title: 'Edit',
			icon: 'edit',
			action: onEdit,
		},
		{
			title: 'Delete',
			icon: 'delete',
			action: onDelete,
		},
		{
			title: 'Download',
			icon: 'download',
			href: (document) => document.url,
		},
		{
			title: 'Link to module',
			icon: 'add_link',
			action: onLink,
		},
	];
</script>

<Page
	title="Documents"
	description="Upload and manage documents here."
	crumbs={['Documents']}>
	<svelte:fragment slot="toolbar">
		<Button on:click={onAdd}>Add document</Button>
	</svelte:fragment>
	<StoreTable
		store={documents}
		columns={columns}
		actions={actions}
		bind:onUpdate />
	<DocumentDialog
		bind:open={openDocumentDialog}
		bind:document={selectedDocument}
		on:submit={onUpdate} />
	<LinkContentDialog
		bind:open={openLinkContentDialog}
		documentId={selectedDocument?.id} />
</Page>
