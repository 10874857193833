import {writable} from 'svelte/store';

import {getLocationsShort} from '../../api/locations';

function createLocations() {
	const {subscribe, set} = writable({});
	return {
		subscribe,
		fetch: async () => {
			const locations = await getLocationsShort();
			const result = locations.reduce((map, obj) => {
				map[obj.id] = obj.name;
				return map;
			}, {});
			return set(result);
		},
	};
}

export const shortLocations = createLocations();
