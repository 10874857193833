<script>
	import {router} from 'tinro';
	import {onMount} from 'svelte';
	import {createEventDispatcher} from 'svelte';
	import {localstore} from '../stores/localstore';

	const dispatch = createEventDispatcher();

	export let routing = true;
	export let count;
	export let limit;
	export let offset = 0;

	if (routing && $router.query.page) {
		offset = Number($router.query.page - 1) * limit;
	}

	onMount(() => {
		if ($localstore?.limit) {
			limit = $localstore.limit;
		}
	});

	const onPrev = () => {
		if (offset > 0) {
			offset -= limit;
			dispatch('previous');
			if (routing) {
				router.location.query.set('page', offset / limit + 1);
			}
		}
	};

	const onNext = () => {
		if (offset + limit < count) {
			offset += limit;
			dispatch('next');
			if (routing) {
				router.location.query.set('page', offset / limit + 1);
			}
		}
	};

	const onValueChange = () => {
		$localstore.limit = limit;
	};
</script>

<div class="wrapper">
	<div>
		<button
			class="material-icons"
			disabled={offset === 0}
			on:click={onPrev}>
			navigate_before
		</button>
		<span class="numbers">
			{`${offset / limit + 1} / ${Math.ceil(count / limit) || 1}`}
		</span>
		<button
			class="material-icons"
			disabled={offset + limit >= count}
			on:click={onNext}>
			navigate_next
		</button>
	</div>
	<select bind:value={limit} on:change={onValueChange}>
		<option value={5}>5 rows</option>
		<option value={10}>10 rows</option>
		<option value={20}>20 rows</option>
		<option value={30}>30 rows</option>
		<option value={50}>50 rows</option>
		<option value={100}>100 rows</option>
	</select>
</div>

<style>
	.wrapper {
		display: flex;
		justify-content: space-between;
		margin-top: 8px;
		color: gray;
	}
	button {
		outline: 0;
		min-width: 0px;
		vertical-align: middle;
		background-color: transparent;
		border: 1px solid;
		border-radius: 4px;
		padding: 4px 6px;
		font-size: 20px;
	}
	button:hover {
		cursor: pointer;
	}
	.numbers {
		padding: 0px 10px;
		font-size: 16px;
	}
	select {
		margin: 0;
		padding: 5px;
		width: 90px;
		border: 1px solid;
		border-radius: 4px;
		outline: 0;
	}
</style>
