import {MLApi} from './api';

export const getTags = async (offset, limit, orderBy) => {
	return MLApi.get(
		`/tags?offset=${offset}&limit=${limit}&orderBy=${orderBy}`
	);
};

export const getTag = async (tagId) => {
	return MLApi.get(`/tags/${tagId}`);
};

export const postTag = async ({itemId, name}) => {
	return MLApi.post('/tag/', {itemId, name});
};

export const updateTag = async (tag) => {
	return MLApi.update('/tag/' + tag.id, tag);
};

export const getTrainingAnnotations = async (tagId, offset, limit) => {
	return MLApi.get(
		`/tags/${tagId}/annotations?offset=${offset}&limit=${limit}&dataset=training`
	);
};

export const getEvaluationAnnotations = async (tagId, offset, limit) => {
	return MLApi.get(
		`/tags/${tagId}/annotations?offset=${offset}&limit=${limit}&dataset=evaluation`
	);
};

export const getTagImages = async (tagId, offset, limit) => {
	return MLApi.get(`/tags/${tagId}/images?offset=${offset}&limit=${limit}`);
};

export const moveAnnotation = async (annotation) => {
	return MLApi.post(`/annotations/move`, {
		id: annotation.id,
		dataset: annotation.dataset,
	});
};

export const deleteAnnotation = async (annotationId) => {
	return MLApi.delete(`/annotation/`, annotationId);
};

export const deleteTag = async (tagId) => {
	return MLApi.delete(`/tags/${tagId}`);
};

export const getTagsShort = async () => {
	return MLApi.get(`/tags?short=true`);
};
