<script>
	export let title;
	export let description = null;
	export let small = false;
</script>

<div class="topbar">
	{#if title}
		<h2 class:small>{title}</h2>
	{/if}
	<div class="button-bar">
		<slot />
	</div>
</div>
{#if description}
	<p class="description">{description}</p>
{/if}

<style>
	.topbar {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 10px 0;
	}
	.button-bar {
		display: flex;
		flex-wrap: wrap;
		gap: 5px;
	}
	h2 {
		font-size: 40px;
		margin: 0;
	}
	.description {
		font-size: 16px;
		margin: 0 0 20px 0;
		font-weight: 400;
	}
	.small {
		font-size: 24px;
	}
	@media (max-width: 600px) {
		h2 {
			font-size: 50px;
		}
		.topbar {
			flex-direction: column;
			align-items: flex-start;
		}
	}
</style>
