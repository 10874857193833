import {writable} from 'svelte/store';

import {
	deleteGlobalItem,
	getAllItems,
	updateGlobalItem,
	postGlobalItem,
} from '../api/items';

function createGlobalItems() {
	const {subscribe, set, update} = writable();
	const fetchGlobalItems = async (offset, limit, orderBy) => {
		return set(await getAllItems(offset, limit, orderBy));
	};

	return {
		subscribe,
		fetch: (offset, limit, orderBy) =>
			fetchGlobalItems(offset, limit, orderBy),
		add: async (item) => {
			await postGlobalItem(item);
		},
		change: async (item) => {
			await updateGlobalItem(item);
		},
		delete: async (id) => {
			try {
				const response = await deleteGlobalItem(id);
				update((arr) => ({
					count: arr.count,
					records: arr.records.filter((item) => item.id !== id),
				}));
				return response;
			} catch (e) {
				return e;
			}
		},
	};
}

export const globalItems = createGlobalItems();
