<script>
	import {createEventDispatcher} from 'svelte';
	const dispatch = createEventDispatcher();

	import Dialog from '../Dialog.svelte';
	import {updateUser} from '../../api/users';
	import {shortRoles} from '../../stores/short/roles';
	import {shortUserGroups} from '../../stores/short/usergroups';

	shortRoles.fetch();
	shortUserGroups.fetch();

	let error = '';
	let close;
	export let open;
	export let user;

	const onSubmit = async () => {
		user.userGroups = user.userGroups.map((g) => g.id);

		try {
			await updateUser(user);

			user = null;
			dispatch('submit');
			close();
		} catch (e) {
			error = e.error ? e.error : e;
		}
	};

	$: user = user || {
		id: undefined,
		name: '',
		email: '',
		roleId: $shortRoles[0],
		userGroups: [],
	};

	const onClick = (groupId) => {
		if (user.userGroups.find((g) => g.id === Number(groupId))) {
			user.userGroups = user.userGroups.filter(
				(g) => g.id !== Number(groupId)
			);
		} else {
			user.userGroups = [...user.userGroups, {id: Number(groupId)}];
		}
	};
</script>

<form on:submit|preventDefault={onSubmit}>
	<Dialog title="Edit user" bind:open bind:close bind:error>
		<label>
			Name
			<input type="text" bind:value={user.name} />
		</label>
		<label>
			Email
			<input type="text" bind:value={user.email} />
		</label>
		<label>
			Role
			<select bind:value={user.roleId}>
				{#each Object.entries($shortRoles) as [roleId, roleName]}
					<option value={Number(roleId)}>{roleName}</option>
				{/each}
			</select>
		</label>
		{#if Object.entries($shortUserGroups).length}
			<p class="no-margin-bot">User groups</p>
		{/if}
		<div class="group-options">
			{#each Object.entries($shortUserGroups) as [groupId, groupName]}
				<label class="group-option">
					<input
						type="checkbox"
						checked={user.userGroups.some(
							(g) => g.id === Number(groupId)
						)}
						on:click={onClick(groupId)} />
					{groupName}
				</label>
			{/each}
		</div>
	</Dialog>
</form>

<style>
	.group-option {
		display: block;
		margin-bottom: 5px;
	}
	.group-options {
		max-height: 100px;
		overflow-y: auto;
	}
	.no-margin-bot {
		margin-bottom: 5px;
	}
</style>
