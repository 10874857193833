<script>
	import BaseWidget from './BaseWidget.svelte';
	import Engagements from '../Engagements.svelte';
	import {unlinkContent, unpinContent, pinContent} from '../../api/modules';

	export let content;
	export let afterThis;

	export let onUpdate;
	export let onMouseDown;

	const onDelete = async () => {
		if (confirm('Are you sure you want to remove this video?')) {
			await unlinkContent(content.id);
			if (onUpdate) {
				onUpdate();
			}
		}
	};

	const onPin = async () => {
		pinContent(content.id);
		if (onUpdate) {
			onUpdate();
		}
	};

	const onUnpin = async () => {
		unpinContent(content.id);
		if (onUpdate) {
			onUpdate();
		}
	};
</script>

<BaseWidget
	title={content.video.title}
	icon="videocam"
	content={content}
	afterThis={afterThis}
	onMouseDown={onMouseDown}
	onUnlink={onDelete}
	pinned={content.isPinned}
	onPin={onPin}
	onUnpin={onUnpin}>
	<div slot="expanded">
		<!-- svelte-ignore component-name-lowercase -->
		<video src={content.video.videoUrl} controls
			><track kind="captions" /></video>
		<Engagements engagements={content.video.engagements} />
	</div>
</BaseWidget>

<style>
	video {
		max-width: 100%;
		margin-top: 10px;
	}
</style>
