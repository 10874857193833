<script>
	import {onMount} from 'svelte';
	import {
		getPublicModulesForMyLocations,
		linkContent,
	} from '../../api/modules';
	import {stripUndefined} from '../../utils/stripUndefined';
	import {filterExisting} from '../../utils/filterExisting';

	import Dialog from '../Dialog.svelte';
	import Select from 'svelte-select';

	let selected;
	let error = '';
	let close;
	export let open;
	export let moduleId;
	export let videoId;
	export let textboxId;
	export let documentId;
	export let linkId;
	export let modules = [];
	export let linkedModules = [];
	export let onUpdate = () => {};

	onMount(async () => {
		modules = await getPublicModulesForMyLocations();
		modules = modules.map((module) => {
			module.label = module.name;
			module.value = module.id;
			return module;
		});
	});

	const onSubmit = async () => {
		error = '';
		const content = {
			moduleId,
			videoId,
			textboxId,
			documentId,
			linkId,
		};
		const strippedContent = stripUndefined(content);
		if (Object.keys(strippedContent).length !== 1) {
			throw new Error('Only one type of content can be specified');
		}
		const data = {...strippedContent, parentId: selected.id};

		try {
			await linkContent(data);
			selected = null;
			onUpdate();
			close();
		} catch (e) {
			error = e.error ? e.error : e;
		}
	};
</script>

<form on:submit|preventDefault={onSubmit}>
	<Dialog title="Link content to module" bind:open bind:close bind:error>
		<Select
			bind:value={selected}
			items={filterExisting(modules, linkedModules)} />
	</Dialog>
</form>
