<script>
	export let tasks = [];

	$: getCompletePercentage = () => {
		const completedTasks = tasks.filter((task) => task.isCompleted());

		const incompleteTasks = tasks.filter((task) => !task.isCompleted());

		let partialTaskProgress = 0;
		for (const task of incompleteTasks) {
			const progress = task.progress && task.progress();
			if (progress) {
				partialTaskProgress += progress;
			}
		}

		return Math.min(
			//Ensure that the progress will never exceed 100
			100,
			Math.floor(
				(completedTasks.length / tasks.length) * 100 +
					(partialTaskProgress / tasks.length) * 100
			)
		);
	};

	//Initialize with last value so they don't have to hide every time
	let showTasks = localStorage.getItem('showTasks') !== 'false';
	const toggleShowTasks = () => {
		showTasks = !showTasks;
		localStorage.setItem('showTasks', showTasks);
	};
</script>

<div class="ProgressBox">
	<div class="progressbar-area">
		<div class="ProgressBox__header">
			<p
				class="progressbar__text"
				class:completedAllTasks={getCompletePercentage() >= 100}>
				This item is {getCompletePercentage()}% complete
			</p>
			<p class="ProgressBox__toggle" on:click={toggleShowTasks}>
				{#if showTasks}
					&#9650 Collapse
				{:else}
					&#9660 Expand
				{/if}
			</p>
		</div>
		<div class="progressbar">
			<div
				class="progressbar__inner"
				class:complete={getCompletePercentage() === 100}
				style="width:{getCompletePercentage()}%" />
		</div>
		{#if showTasks}
			<p class="tasklist__header">TASKS</p>
			<div class="tasklist">
				{#each tasks as task}
					<div class="task" class:completed={task.isCompleted()}>
						<div class="task__indicator" />
						<div>
							<p class="task__text">{task.title()}</p>
							{#if task.learnMore}
								<p class="task__learn-more">Learn more</p>
							{/if}
						</div>
					</div>
				{/each}
			</div>
		{/if}
	</div>
</div>

<style>
	.ProgressBox {
		border-radius: 15px;
		border: solid 2px #d3d3d3;
		color: #000;
		padding: 15px;
		box-sizing: border-box;
	}
	.ProgressBox__header {
		display: flex;
		justify-content: space-between;
	}
	.ProgressBox__toggle {
		margin-top: 0;
		margin-bottom: 5px;
		font-size: 14px;
		color: #969696;
		text-transform: uppercase;
	}
	.ProgressBox__toggle:hover {
		cursor: pointer;
		color: rgb(102, 102, 102);
	}
	.progressbar__text {
		margin-top: 0;
		margin-bottom: 5px;
		font-size: 14px;
		color: #ff842c;
		text-transform: uppercase;
	}
	.progressbar {
		width: 100%;
		height: 20px;
		border-radius: 10px;
		overflow: hidden;
		background-color: #000;
	}
	.progressbar__inner {
		width: 70%;
		border-radius: 10px;
		height: 20px;
		background-color: #ff9140;
		transition: 0.5s all;
	}

	.task {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: 5px;
	}
	.task__indicator {
		background-image: url('/check-white.png');
		background-repeat: no-repeat;
		background-position: center;
		margin-right: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 30px;
		height: 30px;
		border-radius: 15px;
		border: solid 3px #ff9140;
		box-sizing: border-box;
	}
	.task__text {
		flex: 1;
		margin: 0;
	}
	.task__learn-more {
		margin: 0;
		color: #999;
	}
	.task.completed .task__text {
		color: #32c732;
	}
	.completedAllTasks {
		color: #32c732;
	}
	.complete {
		background-color: #32c732;
	}
	.task.completed .task__indicator {
		background-color: #32c732;
		border-color: #32c732;
	}
</style>
