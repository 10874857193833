<script>
	import {onMount} from 'svelte';
	import Chart from 'svelte-frappe-charts';
	import {getLoginCounts} from '../../api/logs';

	const date = new Date();

	const day = date.getDay();

	const days = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
	];

	for (let i = 0; i <= day; i++) {
		days.push(days.shift());
	}

	days[days.length - 1] = 'Today';

	onMount(async () => {
		updateChart();
	});

	export const updateChart = async () => {
		const logs = await getLoginCounts(0, 7);

		const values = [0, 0, 0, 0, 0, 0, 0];
		logs.forEach((l) => {
			values[l.daysAgo % 7] = l.loginCount;
		});

		data.datasets[0].values = values.reverse();
	};

	const data = {
		labels: days,
		datasets: [{values: [0, 0, 0, 0, 0, 0, 0]}],
	};
</script>

<Chart data={data} type="line" />
