import {MLApi} from './api';

export const getDataset = async (datasetId) => {
	return MLApi.get(`/datasets/${datasetId}`);
};

export const getDatasets = async (offset, limit, orderBy) => {
	return MLApi.get(
		`/datasets?offset=${offset}&limit=${limit}&orderBy=${orderBy}`
	);
};

export const getDatasetsShort = async () => {
	return MLApi.get(`/datasets?short=true`);
};

export const postDataset = async (dataset) => {
	return MLApi.post('/datasets', {
		name: dataset.name,
	});
};

export const updateDataset = async (dataset) => {
	return MLApi.update(`/datasets/${dataset.id}`, {
		name: dataset.name,
	});
};

export const deleteDataset = async (datasetId) => {
	return MLApi.delete(`/datasets/${datasetId}`);
};

export const getTrainingAnnotations = async (id, offset, limit) => {
	return MLApi.get(
		`/datasets/${id}/tags?offset=${offset}&limit=${limit}&dataset=training`
	);
};

export const getEvaluationAnnotations = async (id, offset, limit) => {
	return MLApi.get(
		`/datasets/${id}/tags?offset=${offset}&limit=${limit}&dataset=evaluation`
	);
};

export const tagDelete = async (datasetId, tagName) => {
	return MLApi.delete(`/datasets/${datasetId}/tag/`, tagName);
};

export const importTagFromItem = async (datasetId, itemId) => {
	return MLApi.post(`/datasets/${datasetId}/importfromitem/${itemId}`);
};

export const importTagFromLocation = async (datasetId, locationId) => {
	return MLApi.post(
		`/datasets/${datasetId}/importfromlocation/${locationId}`
	);
};

export const moveAnnotation = async (annotation) => {
	return MLApi.post(`/datasets/annotations/move`, {
		id: annotation.id,
		dataset: annotation.dataset,
	});
};

export const deleteAnnotation = async (id) => {
	return MLApi.delete(`/datasets/annotations/`, id);
};
