<script>
	export let name;
	export let icon;
	export let href;
	export let handleClick;
	export let activated = null;
</script>

<a href={href} class="sidebar-item" class:activated on:click={handleClick}>
	<div class="material-icons">{icon}</div>
	<p class="sidebar-item__name">{name}</p>
</a>

<style>
	.sidebar-item {
		text-decoration: none;
		display: flex;
		align-items: center;
		padding: 7px;
		font-size: 14px;
		margin: 10px;
		border-radius: 3px;
		color: #585b5e;
	}
	.sidebar-item:hover {
		background-color: #eff0f1;
		cursor: pointer;
	}
	.sidebar-item:active {
		background-color: #dfe5ec;
		color: #435a74;
	}

	.sidebar-item.activated {
		background-color: #e4e8ec;
		color: #435a74;
	}
	.sidebar-item.activated:hover {
		background-color: #d3dde6;
		cursor: pointer;
	}
	.sidebar-item.activated:active {
		background-color: #ced7e2;
	}
	.sidebar-item__name {
		margin: 0;
		margin-left: 25px;
		font-size: 14px;
	}
</style>
