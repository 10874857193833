import {writable} from 'svelte/store';

import {getGlobalOverview} from '../api/globalOverview';

function createGlobalOverview() {
	const {subscribe, set} = writable(null);
	const fetchGlobalOverview = async (offset, limit) => {
		const globalOverview = await getGlobalOverview(offset, limit);
		set(globalOverview);
	};

	return {
		subscribe,
		fetch: (offset, limit) => fetchGlobalOverview(offset, limit),
	};
}

export const globalOverview = createGlobalOverview();
