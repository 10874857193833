import {writable} from 'svelte/store';

import {
	deleteCategory,
	getCategories,
	postCategory,
	updateCategory,
} from '../api/categories';

function createCategories() {
	const {subscribe, set, update} = writable({count: 0, records: []});
	const fetchCategories = async (offset, limit) => {
		return set(await getCategories(offset, limit));
	};

	return {
		subscribe,
		fetch: (offset, limit) => fetchCategories(offset, limit),
		add: async (category) => {
			await postCategory(category);
		},
		change: async (category) => {
			await updateCategory(category);
			fetchCategories();
		},
		delete: async (id) => {
			await deleteCategory(id);
			update((arr) => ({
				count: arr.count,
				records: arr.records.filter(
					(categories) => categories.id !== id
				),
			}));
		},
	};
}

export const categories = createCategories();
