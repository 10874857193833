<script>
	import {router} from 'tinro';

	import {fly} from 'svelte/transition';

	import Paginator from './Paginator.svelte';
	import TableHeading from './TableHeading.svelte';
	import {timeSince, fullTime} from '../utils/timeSince';

	import {shortRoles} from '../stores/short/roles';
	import {shortLocations} from '../stores/short/locations';
	import {shortCategories} from '../stores/short/categories';
	import {shortUsers} from '../stores/short/users';

	export let columns = [];
	export let actions = [];
	export let rows;
	export let count;
	export let hidePaginator = false;

	export let selected = null;
	export let showId = false;
	export let onPause = null;
	export let onPlay = null;
	export let onUpdate = null;

	export let routing = true;
	export let limit = 10;
	export let offset = 0;
	export let orderBy = 'name';
	export let orderByDirection = 'asc';
	export let emptyMessage = 'No records in table';

	if ($router.query.sort) {
		orderBy = $router.query.sort.split(':')[0];
		orderByDirection = $router.query.sort.split(':')[1];
	}

	const handleKeydown = (e) => {
		if (e.key === 'ScrollLock') {
			showId = !showId;
		}
	};

	let actionClicked = 0;
	const onActionClick = () => {
		actionClicked++;
		if (actionClicked > 4) {
			showId = actionClicked % 2 === 1;
		}
	};

	const handleCheck = (e) => {
		if (e.target.checked) {
			selected = [...rows.records];
		} else {
			selected = [];
		}
	};

	const handleSelect = (e, row) => {
		if (e.target.checked) {
			selected = [...selected, row];
		} else {
			selected = selected.filter((oldRow) => oldRow.id !== row.id);
		}
	};

	// only fetch these if we use them in the table
	const columnTypes = columns.map((col) => col.type);
	if (columnTypes.includes('category')) {
		shortCategories.fetch();
	}
	if (columnTypes.includes('role')) {
		shortRoles.fetch();
	}
	if (columnTypes.includes('location')) {
		shortLocations.fetch();
	}
	if (columnTypes.includes('user')) {
		shortUsers.fetch();
	}

	export const onSort = (newOrderBy) => {
		if (newOrderBy === orderBy) {
			if (orderByDirection === 'asc') {
				orderByDirection = 'desc';
			} else if (orderByDirection === 'desc') {
				orderByDirection = 'asc';
			}
		} else {
			orderBy = newOrderBy;
			orderByDirection = 'asc';
		}
		/*if (onUpdate) {
			onUpdate();
		}*/
		router.location.query.set('sort', `${orderBy}:${orderByDirection}`);
	};
</script>

<svelte:window on:keydown={handleKeydown} />

{#await rows then _}
	<div in:fly={{y: 10}}>
		<div class="outer">
			<table>
				<tr class="header-row">
					{#if selected}
						<td width="30px">
							<input type="checkbox" on:change={handleCheck} />
						</td>
					{/if}
					{#if showId}
						<td width="1">
							<TableHeading
								orderBy={orderBy}
								orderByDirection={orderByDirection}
								sortKey="id"
								name="ID"
								onSort={onSort} />
						</td>
					{/if}
					{#each columns as column}
						<td
							class:hide-mobile={column.hide === 'mobile'}
							class:hide-small={column.hide === 'small'}
							class:hide-medium={column.hide === 'medium'}>
							<TableHeading
								orderBy={orderBy}
								orderByDirection={orderByDirection}
								sortKey={column.orderKey}
								name={column.title}
								onSort={onSort} />
						</td>
					{/each}
					{#if actions.length}
						<td width="1" on:click={onActionClick}> Actions </td>
					{/if}
				</tr>
				{#if !rows.length}
					<tr class="row"
						><td colspan={columns.length}>{emptyMessage}</td></tr>
				{/if}
				{#each rows as row}
					<tr
						class="row"
						class:selected={selected &&
							selected.find((e) => e.id === row.id)}>
						{#if selected}
							<td>
								<input
									type="checkbox"
									checked={selected.find(
										(e) => e.id === row.id
									)}
									on:change={(e) => handleSelect(e, row)} />
							</td>
						{/if}
						{#if showId}
							<td>{row.id || 'no id'}</td>
						{/if}
						{#each columns as column}
							<td
								class:hide-mobile={column.hide === 'mobile'}
								class:hide-small={column.hide === 'small'}
								class:hide-medium={column.hide === 'medium'}
								class:truncatable={column.truncate}
								title={column.type === 'time'
									? fullTime(column.value(row))
									: column.value(row)}>
								{#if column.href}
									<a class="link" href={column.href(row)}
										>{column.value(row)}</a>
								{:else if column.type === 'category'}
									{$shortCategories[column.value(row)] ||
										'No category'}
								{:else if column.type === 'role'}
									{$shortRoles[column.value(row)] ||
										'No Role'}
								{:else if column.type === 'location'}
									{$shortLocations[column.value(row)] ||
										'No location'}
								{:else if column.type === 'user'}
									{$shortUsers[column.value(row)] || '-'}
								{:else if column.type === 'time'}
									{timeSince(column.value(row)) || '-'}
								{:else if column.type === 'button'}
									{#if onPause && column.value(row)}
										<div
											class="icon-button material-icons"
											title="Pause"
											on:click={() => onPause(row)}>
											pause
										</div>
									{/if}

									{#if onPlay && !column.value(row)}
										<div
											class="icon-button material-icons"
											title="Start"
											on:click={() => onPlay(row)}>
											play_arrow
										</div>
									{/if}
								{:else}{column.value(row) || '-'}{/if}
							</td>
						{/each}
						{#if actions.length}
							<td>
								{#each actions as action}
									{#if action.hasOwnProperty('isVisible') ? action.isVisible(row) : true}
										{#if action.href}
											<a href={action.href(row)}
												><div
													class="material-icons icon-button"
													title={action.title}>
													{action.icon}
												</div></a>
										{:else}
											<div
												class="material-icons icon-button"
												title={action.title}
												on:click={() =>
													action.action(row)}>
												{action.icon}
											</div>
										{/if}
									{/if}
								{/each}
							</td>
						{/if}
					</tr>
				{/each}
			</table>
		</div>
		{#if !hidePaginator}
			<Paginator count={count} bind:limit bind:offset bind:routing />
		{/if}
	</div>
{/await}

<style>
	.outer {
		border-radius: 4px;
		border: 1px solid rgba(0, 0, 0, 0.12);
		background-color: white;
	}
	table {
		width: 100%;
		white-space: nowrap;
		border-collapse: collapse;
	}
	.header-row {
		height: 56px;
	}
	td {
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		font-size: 0.875rem;
		padding-right: 16px;
		padding-left: 16px;
		overflow: hidden;
	}
	td.truncatable {
		max-width: 200px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.row {
		border-top-color: rgba(0, 0, 0, 0.12);
		border-top-width: 1px;
		border-top-style: solid;
		height: 52px;
	}
	.row:hover {
		background-color: rgba(0, 0, 0, 0.04);
	}

	@media screen and (max-width: 1200px) {
		.hide-medium {
			display: none;
		}
	}

	@media screen and (max-width: 700px) {
		.hide-small {
			display: none;
		}
	}

	@media screen and (max-width: 400px) {
		.hide-mobile {
			display: none;
		}
	}
	input[type='checkbox'] {
		width: 20px;
		height: 20px;
		cursor: pointer;
	}
	.row.selected {
		background-color: rgba(48, 70, 94, 0.04);
	}
</style>
