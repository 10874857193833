<script>
	import {fade, fly} from 'svelte/transition';

	import Button from './Button.svelte';

	export let title;
	export let className = '';
	export let error = '';
	export let noCancel = false;
	export let noSubmit = false;
	export let wide = false;
	export let canScroll = false;

	export let cancelName = 'Cancel';
	export let submitName = 'Save';

	// You can either just set this variable to open and close
	export let visible = false;
	let content;
	let mouseDownOnBackground = false;

	// Or bind these functions (e.g: let myOpenFunc; then bind:open={myOpenFunc})
	export const open = (...args) => {
		visible = true;
		onOpen(...args);
	};
	export const close = () => {
		visible = false;
	};

	export let onOpen = () => {};

	const onBackgroundClick = () => {
		if (mouseDownOnBackground) {
			close();
		}
		mouseDownOnBackground = false;
	};

	const onKeyDown = (e) => {
		if (e.key === 'Escape') {
			close();
		}
	};

	$: if (visible === true) {
		error = '';
		content?.querySelector('input')?.focus();
	}
</script>

<svelte:window on:keydown={onKeyDown} />

{#if visible}
	<div
		class="background"
		in:fade={{duration: 150}}
		out:fade={{duration: 50}}
		on:mousedown|self={() => (mouseDownOnBackground = true)}
		on:click|self={onBackgroundClick}>
		<div
			class={`content ${className}`}
			class:scroll={canScroll}
			bind:this={content}
			in:fly={{duration: 150, y: -20}}
			out:fly={{y: -20}}
			class:wide>
			<div class="header">
				<h2>{title}</h2>
				<button
					type="button"
					on:click={close}
					class="material-icons close-button">
					close
				</button>
			</div>
			<slot />
			{#if error}
				<div in:fly={{y: -5}} class="errortext">{error}</div>
			{/if}
			<div class="button-bar">
				<slot name="button-bar" />
				{#if !noCancel}
					<Button on:click={close}>{cancelName}</Button>
				{/if}
				{#if !noSubmit}
					<Button submit>{submitName}</Button>
				{/if}
			</div>
		</div>
	</div>
{/if}

<style>
	.background {
		position: fixed;
		z-index: 1000;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, 0.33);
		top: 0;
		left: 0;
	}
	.header {
		display: flex;
		justify-content: space-between;
		margin: 5px 0 24px 0;
	}
	h2 {
		margin: 0;
	}
	.close-button {
		border: 0;
		background: transparent;
		cursor: pointer;
		width: 24px;
	}
	.content {
		box-sizing: border-box;
		padding: 20px 24px;
		background: white;
		max-width: 400px;
		max-height: calc(100% - 32px);
		border-radius: 4px;
		width: 100%;
	}
	.scroll {
		overflow: auto;
	}
	.button-bar {
		display: flex;
		justify-content: flex-end;
		gap: 10px;
		margin-top: 10px;
	}
	.errortext {
		color: #f85555;
		font-size: 16px;
	}
	@media (max-width: 600px) {
		.content {
			width: calc(100% - 20px);
		}
	}
	.wide {
		max-width: 100%;
	}
</style>
