import {writable} from 'svelte/store';

import {deleteUser, getUsers, updateUser} from '../api/users';

function createUsers() {
	const {subscribe, set, update} = writable();

	return {
		subscribe,
		fetch: async (offset, limit, order) => {
			const users = await getUsers(offset, limit, order);
			users.records = users.records.map((user) => {
				user.refreshVisible = Boolean(!user.name);
				return user;
			});
			set(users);
		},
		change: async (user) => {
			await updateUser(user);
			fetchUsers();
		},
		delete: async (id) => {
			await deleteUser(id);
			update((arr) => ({
				count: arr.count,
				records: arr.records.filter((user) => user.id !== id),
			}));
		},
	};
}

export const users = createUsers();
