<script>
	import Dialog from '../Dialog.svelte';

	let close;
	export let open;
</script>

<Dialog title="Hotkeys" bind:open bind:close noCancel noSubmit>
	<div class="container">
		<div class="icon-container">
			<div class="mouse">
				<div class="mouse-left active" />
				<div class="mouse-right" />
			</div>
		</div>
		Annotate
	</div>
	<div class="container">
		<div class="icon-container">
			<div class="mouse">
				<div class="mouse-left" />
				<div class="mouse-right active" />
			</div>
		</div>
		Delete most recent annotation
	</div>
	<div class="container">
		<div class="icon-container">
			<div class="hotkey">⏎</div>
		</div>
		Next image
	</div>
	<div class="container">
		<div class="icon-container">
			<div class="hotkey">Delete</div>
		</div>
		Delete image
	</div>
	<div class="container">
		<div class="icon-container">
			<div class="hotkey">▲</div>
		</div>
		Scroll up in recent tags
	</div>
	<div class="container">
		<div class="icon-container">
			<div class="hotkey">▼</div>
		</div>
		Scroll down in recent tags
	</div>
</Dialog>

<style>
	.icon-container {
		width: 95px;
	}
	.container {
		display: flex;
		align-items: center;
		padding: 0;
	}
	.hotkey {
		box-sizing: border-box;
		display: inline-block;
		margin-top: 5px;
		margin-right: 5px;
		padding: 5px 8px;
		text-align: center;
		line-height: 1;
		font-weight: 800;
		font-family: sans-serif;
		border-radius: 5px;
		font-size: 15px;
		border-top: solid 3px #333;
		border-left: solid 3px #333;
		border-right: solid 6px #333;
		border-bottom: solid 8px #333;
	}
	.mouse {
		vertical-align: top;
		display: inline-flex;
		margin-top: 5px;
		margin-right: 5px;
		width: 22px;
		height: 33px;
		border-radius: 10px 10px 25px 25px;
		border: solid 2px #333;
	}
	.mouse-left {
		flex: 1;
		height: 15px;
	}
	.mouse-right {
		flex: 1;
		height: 15px;
	}
	.active {
		background-color: black;
	}
</style>
