<script>
	import {createEventDispatcher, onMount} from 'svelte';
	import {user} from '../../stores/user';
	const dispatch = createEventDispatcher();

	import Dialog from '../Dialog.svelte';

	import {locations} from '../../stores/locations';
	import {getModelList} from '../../api/models';

	export let open;
	export let location;

	let models = [];
	let error = '';
	let close;

	onMount(async () => {
		models = await getModelList();
	});

	const onSubmit = async () => {
		try {
			if (location.id) {
				await locations.change(location);
			} else {
				await locations.add(location);
			}

			location = null;
			dispatch('submit');
			close();
		} catch (e) {
			error = e.error ? e.error : e;
		}
	};

	$: location = location || {
		id: undefined,
		name: '',
		description: '',
		modelId: null,
	};
</script>

<form on:submit|preventDefault={onSubmit}>
	<Dialog
		title="{location.id ? 'Edit' : 'Add'} location"
		canScroll
		bind:open
		bind:close
		bind:error>
		<label>
			Name
			<input type="text" bind:value={location.name} required />
		</label>
		<label text="Description">
			Description
			<input type="text" bind:value={location.description} />
		</label>
		{#if location.id}
			<label>
				Model
				<select bind:value={location.modelId} label="Model">
					<option value={null}>No model</option>
					{#each models as model}
						<option value={model.id}>{model.name}</option>
					{/each}
				</select>
			</label>
		{/if}
		{#if $user.admin}
			<label>
				Location type
				<select bind:value={location.isPremium}>
					<option value={0}>Standard</option>
					<option value={1}>Premium</option>
				</select>
			</label>
		{/if}
	</Dialog>
</form>
