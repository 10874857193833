const ANNOTATION_COLORS = [
	'#f44336',
	'#9C27B0',
	'#3F51B5',
	'#03A9F4',
	'#009688',
	'#4CAF50',
	'#CDDC39',
	'#FFC107',
	'#E91E63',
	'#673AB7',
	'#2196F3',
	'#00BCD4',
	'#8BC34A',
	'#FFEB3B',
	'#FF9800',
	'#FF5722',
];

export const getTagColor = (tagId) => {
	return ANNOTATION_COLORS[tagId % (ANNOTATION_COLORS.length - 1)];
};
