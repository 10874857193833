import {writable} from 'svelte/store';

import {
	deleteServiceMessage,
	getServiceMessages,
	postServiceMessage,
} from '../api/servicemessages';

function createServiceMessages() {
	const {subscribe, set, update} = writable();
	const fetchServiceMessages = async (
		offset,
		limit,
		orderBy,
		unresolvedOnly
	) => {
		return set(
			await getServiceMessages(offset, limit, orderBy, unresolvedOnly)
		);
	};

	return {
		subscribe,
		fetch: (offset, limit, orderBy, unresolvedOnly) =>
			fetchServiceMessages(offset, limit, orderBy, unresolvedOnly),
		add: async (serviceMessage) => {
			await postServiceMessage(serviceMessage);
		},
		delete: async (id) => {
			try {
				const response = await deleteServiceMessage(id);
				update((arr) => ({
					count: arr.count,
					records: arr.records.filter(
						(serviceMessage) => serviceMessage.id !== id
					),
				}));
				return response;
			} catch (e) {
				return e;
			}
		},
	};
}

export const serviceMessages = createServiceMessages();
