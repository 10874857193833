const getTimeName = (str, value, suffix) => {
	const pluralSuffix = value === 1 ? '' : 's';
	return value + ' ' + str + pluralSuffix + ' ' + suffix;
};

export const timeSince = (date, compareDate = new Date()) => {
	if (!date) {
		return null;
	}
	const seconds = Math.floor((compareDate - new Date(date)) / 1000);
	const absoluteSeconds = Math.abs(seconds);
	const suffix = seconds >= 0 ? 'ago' : 'until';

	let interval = Math.floor(absoluteSeconds / 31536000);
	if (interval > 1) {
		return `${getTimeName('year', interval, suffix)}`;
	}

	interval = Math.floor(absoluteSeconds / 2592000);
	if (interval > 1) {
		return `${getTimeName('month', interval, suffix)}`;
	}

	interval = Math.floor(absoluteSeconds / 86400);
	if (interval > 1) {
		return `${getTimeName('day', interval, suffix)}`;
	}

	interval = Math.floor(absoluteSeconds / 3600);
	if (interval > 1) {
		return `${getTimeName('hour', interval, suffix)}`;
	}

	interval = Math.floor(absoluteSeconds / 60);
	if (interval > 1) {
		return `${getTimeName('minute', interval, suffix)}`;
	}

	interval = Math.floor(absoluteSeconds);
	return `${getTimeName('second', interval, suffix)}`;
};

export const fullTime = (date) => {
	if (!date) {
		return null;
	}
	return new Date(date).toLocaleString('en-GB');
};

export default timeSince;
