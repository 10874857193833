import {MLApi} from './api';

export const getClassificationRuns = async (offset, limit, orderBy) => {
	return MLApi.get(
		`/classificationruns?offset=${offset}&limit=${limit}&orderBy=${orderBy}`
	);
};

export const deleteClassificationRun = async (runId) => {
	return MLApi.delete(`/classificationruns/${runId}`);
};

export const startClassificationRun = async (locationId) => {
	return MLApi.post('/classificationruns/start', {locationId});
};
