<script>
	import {onMount} from 'svelte';
	import {createEventDispatcher} from 'svelte';
	import {router} from 'tinro';

	import {getItem, updateItem} from '../api/items';
	import {deleteBarcodeLink} from '../api/barcodelinks';
	import {convertToModule} from '../api/modules';

	import {categories} from '../stores/categories';
	import {localstore} from '../stores/localstore';

	import Page from '../components/Page.svelte';
	import Table from '../components/Table.svelte';
	import Button from '../components/Button.svelte';
	import Topbar from '../components/Topbar.svelte';
	import Progressbox from '../components/ProgressBox.svelte';
	import WarningLabel from '../components/WarningLabel.svelte';
	import ContentContainer from '../components/widgets/ContentContainer.svelte';
	import BarcodeDialog from '../components/dialogs/BarcodeDialog.svelte';

	let error = '';
	let item;
	let itemName;

	export let itemId;

	const updateData = async () => {
		item = await getItem(itemId);
		itemName = item.name;
	};

	$: item = item;

	onMount(async () => {
		await updateData();
		categories.fetch();
	});

	const dispatch = createEventDispatcher();

	const onSubmit = async () => {
		try {
			if (item.id) {
				await updateItem(item);
				await updateData();
			}
			error = '';

			dispatch('submit');
		} catch (e) {
			error = e.error ? e.error : e;
		}
	};

	$: tasks = [
		{
			title: () => 'Add a name and description',
			isCompleted: () => item.name && item.description,
		},
		{
			title: () => 'Add an item display image',
			isCompleted: () => item.thumbnailUrl,
		},
		{
			title: () => `Add content about this item`,
			isCompleted: () => item && item.content?.length > 0,
		},
		/*{
			title: () =>
				`Add at least 100 images (${item.annotationCount || 0} / 100)`,
			progress: () => (item.annotationCount || 0) / 100,
			isCompleted: () => (item.annotationCount || 0) >= 100,
			learnMore: true,
		},*/
	];

	const onConvertToModule = async () => {
		if (!confirm('Are you sure you want to convert this item to a module?'))
			return;

		await convertToModule(itemId);
		router.goto(`/location/${$localstore?.locationId}/modules/${itemId}`);
	};

	let openBarcodeDialog;
	const addBarcode = async () => {
		openBarcodeDialog();
	};
	const onDeleteBarcodeLink = async (link) => {
		await deleteBarcodeLink(link.id);
		updateData();
	};

	const barcodeLinkColumns = [
		{
			title: 'Value',
			value: (barcodeLink) => barcodeLink.value,
		},
	];
	const barcodeLinkActions = [
		{
			title: 'Delete',
			icon: 'delete',
			action: onDeleteBarcodeLink,
		},
	];
</script>

{#if item}
	<Page title={itemName} crumbs={['items', itemName]}>
		<svelte:fragment slot="toolbar">
			<Button on:click={onConvertToModule}>Convert to module</Button>
		</svelte:fragment>
		<div class="container">
			<div class="left-side">
				<Progressbox tasks={tasks} />
				<div class="about-item">
					<form on:submit|preventDefault={onSubmit}>
						<Topbar small title="About this item" />
						<label>
							Name
							<input
								type="text"
								bind:value={item.name}
								required />
						</label>
						<label>
							Description
							<input type="text" bind:value={item.description} />
						</label>
						<label>
							Access
							<select bind:value={item.access}>
								<option value="public">Global</option>
								<option value="private">
									This location only
								</option>
							</select>
							{#if item.access === 'private'}
								<WarningLabel>
									Note: We advise that all items are acessible
									globally unless the item itself is highly
									specific to this location or somehow is
									classified. <b
										>Videos and documents in this item will
										still remain private regardless of this
										settings</b>
								</WarningLabel>
							{/if}
						</label>
						<label>
							{'Visibility on home screen'}
							<select type="text" bind:value={item.hideFromHome}>
								<option value={0}>Show</option>
								<option value={1}>Hide</option>
							</select>
						</label>
						Thumbnail
						<label class="inline-input">
							{#if item.thumbnailUrl}
								<img
									class="thumb"
									src={item.thumbnailUrl}
									alt="thumbnail" />
							{/if}
							<input
								accept="image/*"
								type="file"
								class={!item.imageUrl && 'fileupload'}
								bind:files={item.files} />
						</label>
						<div>{error}</div>
						<Button submit>Save item</Button>
					</form>
					<br />
					<h3>
						Barcodes linking to this module
						<Button on:click={addBarcode}>Add barcode</Button>
					</h3>
					<Table
						rows={item.barcodeLinks}
						columns={barcodeLinkColumns}
						actions={barcodeLinkActions}
						emptyMessage="No barcodes linked to item"
						hidePaginator />
				</div>
			</div>
			<div class="right-side">
				<Topbar small title="Content" />
				{#if !item.content?.length}
					<p>
						<i>
							There's no content added to this module yet. Add
							some using the buttons below
						</i>
					</p>
				{/if}
				<ContentContainer module={item} onUpdate={updateData} addNew />
			</div>
		</div>
	</Page>
	<BarcodeDialog
		moduleId={itemId}
		onUpdate={updateData}
		bind:open={openBarcodeDialog} />
{/if}

<style>
	.container {
		display: flex;
		justify-content: space-between;
	}
	.left-side {
		flex-basis: 40%;
	}
	.right-side {
		flex-basis: 55%;
	}
	.table-container {
		margin-bottom: 30px;
	}
	.thumb {
		max-width: 100%;
		max-height: 95px;
	}
	.inline-input {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.inline-input img {
		border-radius: 5px;
		margin-right: 10px;
	}
	.inline-input input {
		display: inline-block;
	}
	label {
		font-size: 14px;
	}
	@media screen and (max-width: 1300px) {
		.item-container {
			flex-direction: column;
		}
		.about-item {
			width: 100%;
		}
		.container {
			flex-direction: column;
			grid-template-columns: 100%;
		}
	}
</style>
