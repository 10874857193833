<script>
	import BaseWidget from './BaseWidget.svelte';
	import {unlinkContent, unpinContent, pinContent} from '../../api/modules';

	export let onUpdate;
	export let content;
	export let afterThis;
	export let onMouseDown;

	const onDelete = async () => {
		if (confirm('Are you sure you want to remove this module?')) {
			await unlinkContent(content.id);
			if (onUpdate) {
				onUpdate();
			}
		}
	};

	const onPin = async () => {
		pinContent(content.id);
		if (onUpdate) {
			onUpdate();
		}
	};

	const onUnpin = async () => {
		unpinContent(content.id);
		if (onUpdate) {
			onUpdate();
		}
	};

	const onTitleClick = () => {
		const locUrl = location.href.split('/modules/')[0];
		location.href = `${locUrl}/modules/${content.module.id}`;
	};
</script>

<BaseWidget
	expanded
	title={content.module.name}
	icon="folder"
	content={content}
	afterThis={afterThis}
	onMouseDown={onMouseDown}
	onUnlink={onDelete}
	onTitleClick={onTitleClick}
	pinned={content.isPinned}
	onPin={onPin}
	onUnpin={onUnpin}>
	<div slot="expanded">
		{#if content.module.description}
			<p>{content.module.description}</p>
		{/if}
	</div>
</BaseWidget>

<style>
	p {
		padding-left: 60px;
		margin: 0;
	}
</style>
