<script>
	import {onMount} from 'svelte';
	import {getShortItems} from '../../api/items';

	onMount(async () => {
		shortItems = await getShortItems();
		console.log(shortItems);
	});

	let shortItems = [];

	export let value;
</script>

<select bind:value>
	<option value={null}>Multiple items</option>
	{#each shortItems as shortItem}
		<option value={Number(shortItem.id)}>{shortItem.name}</option>
	{/each}
</select>
