import {LocationApi} from './api';

export const deleteImageAnnotation = async (imageId) => {
	return LocationApi.delete('/image/' + imageId + '/annotations');
};

export const deleteImageAnnotations = (images) => {
	return Promise.all(images.map((image) => deleteImageAnnotation(image.id)));
};

export const getAnnotations = async () => {
	return LocationApi.get('/annotations');
};

export const postAnnotationsForImage = async (imageId, annotations) => {
	return LocationApi.post(`/annotations/${imageId}`, {annotations});
};
