<script>
	import Dialog from '../Dialog.svelte';
	import AnnotatedImage from '../AnnotatedImage.svelte';

	import {round} from '../../utils/round';

	let close;
	export let open;
	export let images = [];
	export let shownId = 0;
	export let tags = [];
	let imageElement;
	$: image = images.length ? images[shownId] : null;
	$: creationDate = new Date(image?.createdAt);

	let visible;

	const DEBUG_ANNOTATIONS = false;

	const nextSlide = () => {
		if (shownId === images.length - 1) {
			shownId = 0;
		} else {
			shownId += 1;
		}
	};

	const prevSlide = () => {
		if (shownId === 0) {
			shownId = images.length - 1;
		} else {
			shownId -= 1;
		}
	};

	const onKeyDown = (e) => {
		if (visible) {
			if (e.code === 'ArrowRight') {
				nextSlide();
			}
			if (e.code === 'ArrowLeft') {
				prevSlide();
			}
		}
	};

	const ANNOTATION_COLORS = [
		'#f44336',
		'#9C27B0',
		'#3F51B5',
		'#03A9F4',
		'#009688',
		'#4CAF50',
		'#CDDC39',
		'#FFC107',
		'#E91E63',
		'#673AB7',
		'#2196F3',
		'#00BCD4',
		'#8BC34A',
		'#FFEB3B',
		'#FF9800',
		'#FF5722',
	];

	const getTagColor = (tagId) => {
		return ANNOTATION_COLORS[(tagId % ANNOTATION_COLORS.length) - 1];
	};
</script>

<svelte:window on:keydown={onKeyDown} />

<Dialog
	bind:open
	bind:close
	bind:visible
	noCancel
	noSubmit
	wide
	title="Image Preview">
	<div class="container">
		<button
			class="material-icons"
			title="Previous Image"
			on:click={prevSlide}>arrow_back</button>
		<AnnotatedImage image={image} bind:imageElement />
		<button class="material-icons" title="Next Image" on:click={nextSlide}
			>arrow_forward</button>
	</div>
	<div class="img-text">
		Resolution: {imageElement?.naturalHeight}x{imageElement?.naturalWidth}px
		<br />
		Created at: {creationDate.toLocaleDateString()}
		<br />
		Tags:
		{[
			...new Set(
				image.annotations.map((annotation) => annotation.tag.name)
			),
		].join(', ')}
	</div>
</Dialog>

<style>
	.container {
		display: flex;
		align-items: center;
	}
	button {
		background-color: transparent;
		border: 0;
		border-radius: 4px;
		padding: 4px 6px;
		font-size: 20px;
		width: 40px;
		height: 40px;
	}

	button:hover {
		cursor: pointer;
		background-color: #efefef;
	}

	.img-text {
		font-size: 14px;
		padding: 0 40px;
	}

	.img-overlay-wrap {
		position: relative;
		display: inline-block;
		transition: 0.2s;
	}
	.img-overlay-wrap svg {
		position: absolute;
		width: 100%;
		height: 100%;
	}
</style>
