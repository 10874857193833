import {writable} from 'svelte/store';

import {deleteVideo, getVideos, updateVideo, postVideo} from '../api/videos';

function createVideos() {
	const {subscribe, set, update} = writable();

	return {
		subscribe,
		fetch: async (offset, limit, order) => {
			return set(await getVideos(offset, limit, order));
		},
		add: async (video) => {
			await postVideo(video);
		},
		change: async (video) => {
			await updateVideo(video);
		},
		delete: async (id) => {
			await deleteVideo(id);
			update((arr) => ({
				count: arr.count,
				records: arr.records.filter((video) => video.id !== id),
			}));
		},
	};
}

export const videos = createVideos();
