<script>
	import Page from '../components/Page.svelte';
	import StoreTable from '../components/StoreTable.svelte';
	import TagDialog from '../components/dialogs/TagDialog.svelte';

	import {tags} from '../stores/tags';

	const onTagEdit = async (editTag) => {
		tag = editTag;
		openTagDialog();
	};

	const onDelete = async (editTag) => {
		if (
			!confirm(
				`Are you sure you want to delete ${editTag.name || editTag.id}?`
			)
		) {
			return;
		}
		await tags.delete(editTag.id);
		onUpdate();
	};

	const tagColumns = [
		{
			title: 'Name',
			value: (tag) => tag.name,
			href: (tag) => `/machine-learning/tags/${tag.id}`,
			orderKey: 'name',
		},
		{
			title: 'Annotations',
			value: (tag) => tag.annotationCount,
			orderKey: 'annotationCount',
		},
		{
			title: 'Training',
			value: (tag) => tag.trainingCount,
			orderKey: 'trainingCount',
		},
		{
			title: 'Evaluation',
			value: (tag) => tag.evaluationCount,
			orderKey: 'evaluationCount',
		},
		{
			title: 'Images',
			value: (tag) => tag.imageCount,
			orderKey: 'imageCount',
		},
		{
			title: 'Time',
			type: 'time',
			value: (tag) => tag.createdAt,
			orderKey: 'createdAt',
		},
	];

	const tagActions = [
		{
			title: 'Edit',
			icon: 'edit',
			action: onTagEdit,
		},
		{
			title: 'Delete',
			icon: 'delete',
			action: onDelete,
		},
	];

	let tag;
	let onUpdate;
	let openTagDialog;
</script>

<Page title="Tags">
	<StoreTable
		store={tags}
		columns={tagColumns}
		actions={tagActions}
		bind:onUpdate />
	<TagDialog bind:open={openTagDialog} bind:tag on:submit={onUpdate} />
</Page>
