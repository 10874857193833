import jwtDecode from 'jwt-decode';
import {derived} from 'svelte/store';
import {router} from 'tinro';

import {token} from './token';
import {localstore} from './localstore';

export const user = derived([token, localstore], ([$token, $localstore]) => {
	var pathname = window.location.pathname;

	if ($token === 'null' || !$token) {
		if (
			pathname.includes('location') ||
			pathname.includes('admin') ||
			pathname.includes('machine-learning') ||
			pathname.includes('profile')
		) {
			router.goto('/login');
		}
		return null;
	}

	return {...jwtDecode($token), ...$localstore};
});
