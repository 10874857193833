<script>
	/* eslint-disable */
	//Disabled cause these are produced in build scripts
	const buildType = myapp.env.BUILD_TYPE;
	const buildVersion = myapp.env.BUILD_VERSION;
	/* eslint-enable */
</script>

<svelte:head>
	{#if buildType === 'STAGING'}
		<title>(Staging) Unifractal Dashboard</title>
	{:else if buildType !== 'PRODUCTION'}
		<title>(Local) Unifractal Dashboard</title>
	{/if}
</svelte:head>

{#if buildType !== 'PRODUCTION'}
	<div
		class="version-banner"
		style={buildType === 'STAGING'
			? 'background-color: #FF9140'
			: 'background-color: #1E88E5'}>
		<p class="version-text">
			{buildType}
			build
			<span class="version-num">({buildVersion})</span>
		</p>
	</div>
{/if}

<style>
	.version-banner {
		height: 45px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.version-text {
		color: white;
		margin: 0 auto;
		text-transform: lowercase;
		vertical-align: middle;
	}
	.version-num {
		color: lightgray;
		font-size: 12px;
		text-transform: uppercase;
	}
</style>
