<script>
	import Button from '../components/Button.svelte';
	import {resetPassword} from '../api/authentication';
	import {router} from 'tinro';

	import EnvironmentBanner from '../components/EnvironmentBanner.svelte';

	let error = '';
	let password1 = '';
	let password2 = '';
	let sent = false;

	const onSubmit = async () => {
		try {
			if (password1 !== password2) {
				error = 'Passwords must match';
				return;
			}

			if (!$router.query.token) {
				throw new Error('Token must be set');
			}

			await resetPassword({
				token: $router.query.token,
				password: password1,
			});
			error = '';
			sent = true;
		} catch (e) {
			error = e.error ? e.error : e;
		}
	};
</script>

<EnvironmentBanner />
<header class="login-header">
	<a href="/login">
		<img src="logo-white.svg" alt="Unifractal" class="header-logo" />
	</a>
</header>
{#if sent}
	<div class="login-page">
		<div class="login-form">
			<h1>Password reset!</h1>
			<br />
			<a class="link" href="/login">Click here to return to login</a>
			<br />
		</div>
	</div>
{:else}
	<div class="login-page">
		<div class="login-form">
			<h1>Password Reset</h1>
			<form on:submit|preventDefault={onSubmit}>
				<div>
					<label>
						{'Password'}
						<input type="password" bind:value={password1} />
					</label>
					<label>
						{'Repeat Password'}
						<input type="password" bind:value={password2} />
					</label>
					<a class="link" href="/login"
						>Already know your password?</a>
					<br />
					<div class="errortext">{error}</div>
					<br />
					<Button submit>Reset</Button>
				</div>
			</form>
		</div>
	</div>
{/if}

<style>
	.header-logo {
		padding-left: 20px;
	}
</style>
