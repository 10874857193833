<script>
	import {createEventDispatcher} from 'svelte';
	import Dialog from '../Dialog.svelte';

	import {createServiceAccount} from '../../api/users';
	const dispatch = createEventDispatcher();

	let error = '';
	let close;
	export let open;
	export let accountName;

	let showCancel = true;
	let cancelName = 'Cancel';

	let presentResponse = false;
	let serviceToken = 'Unexpected error';

	const onSubmit = async () => {
		try {
			const response = await createServiceAccount(
				accountName || 'Service account'
			);
			if (response && response.serviceToken) {
				serviceToken = response.serviceToken;

				presentResponse = true;
				accountName = '';
				cancelName = 'Close';
				showCancel = false;
			}
			dispatch('submit');
		} catch (e) {
			error = e.error ? e.error : e;
		}
	};

	$: accountName = accountName || '';
	$: showCancel = showCancel;
</script>

<form on:submit|preventDefault={onSubmit}>
	<Dialog
		title="Add Service Account"
		submitName="Create Account"
		cancelName={cancelName}
		noSubmit={!showCancel}
		bind:open
		bind:close
		bind:error>
		{#if !presentResponse}
			<p>
				Service accounts have access to do any modification in your
				location and are used for external integrations. <i
					>Do not give the token to someone who shouldn't have access.</i>
			</p>
			<label>
				Name
				<input type="text" bind:value={accountName} required />
			</label>
		{:else}
			<h3>Service account created successfully!</h3>
			<p>
				Please copy the key below and save it in a secure place. It will
				not be possible to retrieve this key later, and you will have to
				create a new service account if you lose it.
			</p>
			<p class="key">{serviceToken}</p>
		{/if}
	</Dialog>
</form>

<style>
	p {
		font-size: 14px;
	}
	.key {
		background: #d9d9d9;
		border: solid 1px #ccc;
		color: black;
		border-radius: 5px;
		padding: 20px 10px;
	}
</style>
