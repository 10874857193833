import {writable} from 'svelte/store';

import {deleteInvite, getInvites} from '../api/invites';

function createInvites() {
	const {subscribe, set, update} = writable();

	return {
		subscribe,
		fetch: async (offset, limit) => {
			const invites = await getInvites(offset, limit);

			set(invites);
		},
		delete: async (id) => {
			await deleteInvite(id);
			update((arr) => ({
				count: arr.count,
				records: arr.records.filter((user) => user.id !== id),
			}));
		},
	};
}

export const invites = createInvites();

export default invites;
