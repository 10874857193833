<script>
	import AddTextboxWidget from '../widgetdialogs/AddTextboxWidget.svelte';
	import AddVideoWidget from '../widgetdialogs/AddVideoWidget.svelte';
	import AddLinkWidget from '../widgetdialogs/AddLinkWidget.svelte';
	import AddDocumentWidget from '../widgetdialogs/AddDocumentWidget.svelte';
	import AddModuleWidget from '../widgetdialogs/AddModuleWidget.svelte';

	let isExpanded = true;
	const toggleExpanded = () => (isExpanded = !isExpanded);
	export let moduleId;
	export let videosInModule = [];
	export let documentsInModule = [];
	export let modulesInModule = [];

	export let onUpdate = () => {};

	let openAddVideoWidget = () => {};
	let openAddTextboxWidget = () => {};
	let openAddLinkWidget = () => {};
	let openAddDocumentWidget = () => {};
	let openAddModuleWidget = () => {};

	const availableTypes = [
		{
			name: 'Video',
			icon: 'videocam',
			action: () => openAddVideoWidget(),
		},
		{
			name: 'Document',
			icon: 'description',
			action: () => openAddDocumentWidget(),
		},
		{
			name: 'Textbox',
			icon: 'subject',
			action: () => openAddTextboxWidget(),
		},
		{
			name: 'Link',
			icon: 'link',
			action: () => openAddLinkWidget(),
		},
		{
			name: 'Module',
			icon: 'folder',
			action: () => openAddModuleWidget(),
		},
	];
</script>

<div class="AddWidget">
	{#if !isExpanded}
		<div on:click={toggleExpanded}>Click me to add more widgets</div>
	{:else}
		<div on:click={toggleExpanded}>Please select what type to add</div>
		<div class="type-list">
			{#each availableTypes as type}
				<button class="type" on:click={type.action}>
					<div class="material-icons">{type.icon}</div>
					<p>{type.name}</p>
				</button>
			{/each}
		</div>
	{/if}
</div>
<AddVideoWidget
	moduleId={moduleId}
	bind:videosInModule
	bind:open={openAddVideoWidget}
	bind:onUpdate />
<AddTextboxWidget
	moduleId={moduleId}
	bind:open={openAddTextboxWidget}
	bind:onUpdate />
<AddLinkWidget
	moduleId={moduleId}
	bind:open={openAddLinkWidget}
	bind:onUpdate />
<AddDocumentWidget
	moduleId={moduleId}
	bind:documentsInModule
	bind:open={openAddDocumentWidget}
	bind:onUpdate />
<AddModuleWidget
	moduleId={moduleId}
	bind:modulesInModule
	bind:open={openAddModuleWidget}
	bind:onUpdate />

<style>
	.AddWidget .type-list {
		display: flex;
	}
	.AddWidget .type {
		background: none;
		border: none;
		font-size: 16px;
		margin: 10px;
		display: flex;
		flex-direction: column;
		vertical-align: middle;
		align-items: center;
	}
	.AddWidget .type:hover {
		cursor: pointer;
	}
	.AddWidget .type :global(.material-icons) {
		font-size: 30px;
	}
	.AddWidget .type p {
		margin: 0;
	}
</style>
