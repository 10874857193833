import {MLApi, mlPost} from './api';

export const getRuns = async (offset, limit, orderBy) => {
	return MLApi.get(
		`/runs?offset=${offset}&limit=${limit}&orderBy=${orderBy}`
	);
};

export const deleteRun = async (runId) => {
	return MLApi.delete('/runs/', runId);
};

export const stopRun = async (run) => {
	return mlPost('/stop', {runId: run.id});
};

export const resumeRun = async (run) => {
	return mlPost('/resume', {runId: run.id});
};
