<script>
	import {onMount} from 'svelte';
	import {classificationRuns} from '../stores/classificationRuns';

	import Page from '../components/Page.svelte';
	import Button from '../components/Button.svelte';
	import StoreTable from '../components/StoreTable.svelte';
	import ClassificationDialog from '../components/dialogs/ClassificationDialog.svelte';

	let onUpdate;
	let openDialog;

	// Update table every 5 seconds
	onMount(() => {
		const looper = setInterval(() => {
			onUpdate();
		}, 10000);

		return () => clearInterval(looper);
	});

	const onDelete = (run) => {
		if (confirm(`Are you sure you want to delete run ${run.id}?`)) {
			classificationRuns.delete(run.id);
		}
	};

	const columns = [
		{
			title: 'ID',
			value: (v) => v.id,
			orderKey: 'id',
		},
		{
			title: 'Status',
			value: (v) => v.status,
			orderKey: 'status',
		},
		{
			title: 'Location',
			type: 'location',
			value: (v) => v.locationId,
			orderKey: 'locationId',
		},
		{
			title: 'Started',
			value: (v) => v.createdAt,
			orderKey: 'createdAt',
			type: 'time',
		},
	];

	const actions = [
		{
			title: 'Delete',
			icon: 'delete',
			action: onDelete,
		},
	];
</script>

<Page title="Classification Runs">
	<svelte:fragment slot="toolbar">
		<Button on:click={openDialog}>New run</Button>
	</svelte:fragment>
	<StoreTable
		store={classificationRuns}
		columns={columns}
		actions={actions}
		orderBy="id"
		orderByDirection="desc"
		bind:onUpdate />
	<ClassificationDialog bind:open={openDialog} on:submit={onUpdate} />
</Page>
