<script>
	import pLimit from 'p-limit';

	import Table from '../Table.svelte';
	import Dialog from '../Dialog.svelte';
	import Button from '../Button.svelte';
	import ProgressBar from '../ProgressBar.svelte';
	import ItemSelector from '../selectors/ItemSelector.svelte';

	import {cleanMediaPost} from '../../api/api.js';
	import {getImages, getImageUrls} from '../../api/images';
	import {postTag} from '../../api/tags';

	const limit = pLimit(10);

	let error = '';
	let progress = 0;
	let uploader;

	let files = [];
	let itemId = null;
	let fileCount = 0;
	let confirmNoPersonalData = false;

	let showProgress = false;
	let showFileChooser = true;
	let showTable = false;

	let close;
	export let open;
	export let onUpdate = () => {};

	const onClear = () => {
		showTable = false;
		showFileChooser = true;
		files = [];
		confirmNoPersonalData = false;
	};

	const onProgress = (event, file) => {
		file.progress = Math.floor((event.loaded / event.total) * 100);
		files = files;
		if (file.progress === 100) {
			progress += 100 / fileCount;
			// Remove file from list
			files = files.filter((ffile) => ffile !== file);
		}
	};

	const onSubmit = async () => {
		error = '';
		try {
			if (!files.length) {
				throw new Error(
					'You must select images using the button above'
				);
			}

			if (!confirmNoPersonalData) {
				throw new Error(
					'You must confirm no personal data is being uploaded'
				);
			}

			let tagId = null;
			if (itemId) {
				const tag = await postTag({name: `${itemId}-batch`, itemId});
				tagId = tag.id;
			}

			showFileChooser = false;
			showProgress = true;

			fileCount = files.length;
			console.log(tagId);

			const urls = await getImageUrls(
				fileCount,
				confirmNoPersonalData,
				tagId
			);

			await Promise.all(
				urls.map((iUrl, index) => {
					const file = files[index];
					const url = iUrl.url;
					return limit(() =>
						cleanMediaPost(url, file.file, {
							onUploadProgress: (event) =>
								onProgress(event, file),
						})
					);
				})
			);

			onUpdate();

			setTimeout(() => {
				showProgress = false;
				showFileChooser = true;
				showTable = false;
				confirmNoPersonalData = false;
			}, 2000);
		} catch (e) {
			error = e.error ? e.error : e;
		}
	};

	const tableColumns = [
		{
			title: 'Filename',
			value: (element) => element.name,
			truncate: true,
		},
	];

	const tableActions = [
		{
			title: 'Delete',
			icon: 'delete',
			action: (row) => (files = files.filter((file) => file !== row)),
		},
	];

	const onFileChange = async (e) => {
		showFileChooser = false;
		files = [];
		for (const file of e.target.files) {
			files.push({
				name: file.name,
				file: file,
			});
		}
		showTable = true;
	};
</script>

<form on:submit|preventDefault={onSubmit}>
	<Dialog title="Upload Images" bind:open bind:close bind:error>
		{#if showTable}
			<div class="scrollable">
				<Table
					actions={tableActions}
					columns={tableColumns}
					rows={files}
					hidePaginator />
			</div>
		{/if}

		{#if showFileChooser}
			<input
				on:change={onFileChange}
				accept="image/*"
				type="file"
				multiple />
		{/if}

		<ItemSelector bind:value={itemId} />

		{#if !showProgress}
			<div class="field">
				<label>
					<input
						class="bigCheck"
						type="checkbox"
						bind:checked={confirmNoPersonalData}
						bind:this={uploader} />
					I confirm that the images I'm uploading does not contain any
					personal or otherwise sensitive information.
				</label>
			</div>
		{:else}
			<div class="field">
				<ProgressBar progress={progress} />
			</div>
		{/if}
		<svelte:fragment slot="button-bar">
			<Button on:click={onClear}>Reset</Button>
		</svelte:fragment>
	</Dialog>
</form>

<style>
	.bigCheck {
		margin-right: 10px;
		margin-top: 10px;
	}
	.field {
		display: flex;
		margin-top: 10px;
	}
	.scrollable {
		overflow-y: auto;
		max-height: 250px;
	}
</style>
