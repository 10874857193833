<script>
	import {onMount} from 'svelte';
	import Button from '../components/Button.svelte';
	import AnnotatorHotkeyDialog from './dialogs/AnnotatorHotkeyDialog.svelte';

	import {postAnnotationsForImage} from '../api/annotations';
	import {deleteImage, rotateImage} from '../api/images';
	import {getShortItems} from '../api/items';

	import {round} from '../utils/round';
	import {getTagColor} from '../utils/getTagColor';

	const ANNOTATOR_PADDING = 40;
	const DEBUG_ANNOTATIONS = false;

	export let onAnnotatorDepleted = () => {};

	//Input data
	export let images = [];
	let image;
	let items = [];
	let tags = [];
	let itemId;
	let tagId;

	//Annotation stuff
	let nextId = 1;
	let currentIndex = 0;
	let annotations = [];
	let recentTags = [];

	//Bounding box stuff
	let imageElement;
	let imageRectangle = null;
	let clickActive;
	let cursorPlacement = {x: 0, y: 0};
	let firstCoords;
	let secondCoords;

	let openHotkeyDialog;

	$: recentTags = [
		...new Set([...recentTags, tagId].filter((i) => i !== undefined)),
	];

	const onHotkeys = () => {
		openHotkeyDialog();
	};

	const convertCoordsToBBox = (firstCoords, secondCoords) => {
		const x = (firstCoords.x + secondCoords.x) / 2;
		const y = (firstCoords.y + secondCoords.y) / 2;
		const width = Math.abs(firstCoords.x - secondCoords.x);
		const height = Math.abs(firstCoords.y - secondCoords.y);

		return {x, y, width, height};
	};

	const convertBBoxToCoords = (bbox) => {
		const x = bbox.x - bbox.width / 2;
		const y = bbox.y - bbox.height / 2;
		const width = bbox.width;
		const height = bbox.height;

		return {x, y, width, height};
	};

	const getTagById = (tagId) => {
		return tags.find((tag) => tag.id === tagId) || null;
	};

	const loadAnnotations = (img) => {
		const _img = img || image;
		if (!_img) {
			return;
		}
		annotations = _img.annotations.map((annotation) => {
			return {
				...annotation,
				...convertBBoxToCoords(annotation),
				id: nextId++,
				existing: true,
			};
		});
	};

	const submitAndNext = async () => {
		if (!image) {
			return;
		}

		const fixedAnnotations = annotations.map((annotation) => ({
			...convertCoordsToBBox(
				{
					x: annotation.x,
					y: annotation.y,
				},
				{
					x: annotation.x + annotation.width,
					y: annotation.y + annotation.height,
				}
			),
			tagId: annotation.tagId,
			imageId: image.id,
		}));

		await postAnnotationsForImage(image.id, fixedAnnotations);
		currentIndex++;
		loadAnnotations();
		nextId = 0;
		if (currentIndex >= images.length) {
			onAnnotatorDepleted();
			currentIndex = 0;
		}
	};

	const getAnnotationText = (annotation) => {
		if (!DEBUG_ANNOTATIONS) {
			return `${getTagById(annotation.tagId).name} (#${annotation.id})`;
		}
		const h = round(annotation.height, 3),
			w = round(annotation.width, 3),
			x = round(annotation.x, 3),
			y = round(annotation.y, 3);

		return `${annotation.mltag} (#${annotation.id}) X: ${x} Y: ${y} W: ${w} H: ${h}`;
	};

	$: getPositionInRectangle = ({x, y}) => {
		if (!imageRectangle) {
			return {x: 0, y: 0};
		}
		const deltaX = x - imageRectangle.x;
		const clampedX = Math.min(Math.max(deltaX, 0), imageRectangle.width);

		const deltaY = y - imageRectangle.y;
		const clampedY = Math.min(Math.max(deltaY, 0), imageRectangle.height);

		return {
			x: clampedX / imageRectangle.width,
			y: clampedY / imageRectangle.height,
		};
	};
	$: ratioToPixelsX = (inputX) => {
		return inputX * imageRectangle.width + ANNOTATOR_PADDING;
	};
	$: ratioToPixelsY = (inputY) => {
		return inputY * imageRectangle.height + ANNOTATOR_PADDING;
	};

	$: getActiveRectColor = () => {
		const width = Math.abs(secondCoords.x - firstCoords.x);
		const height = Math.abs(secondCoords.y - firstCoords.y);
		if (width < 0.05 || height < 0.05) {
			return 'red';
		}
		return 'green';
	};

	$: image = (() => {
		const image = images[currentIndex];
		loadAnnotations(image);
		return image;
	})();

	const handleMouseMove = (e) => {
		if (!imageElement) {
			return;
		}
		const {x, y} = getPositionInRectangle({x: e.pageX, y: e.pageY});
		if (clickActive) {
			secondCoords = {x, y};
		} else {
			cursorPlacement = {x, y};
		}
	};

	const handleMouseDown = (e) => {
		if (clickActive || e.button !== 0) {
			return;
		}
		const {x, y} = getPositionInRectangle({x: e.pageX, y: e.pageY});
		clickActive = true;
		firstCoords = {x, y};
		secondCoords = {x, y};
	};

	const handleMouseUp = (e) => {
		if (e.button === 2) {
			annotations.pop();
			annotations = annotations;
			nextId--;
			return;
		}
		if (e.button !== 0) {
			return;
		}

		if (!firstCoords || !secondCoords) {
			return;
		}

		cursorPlacement = secondCoords;
		clickActive = false;
		const newAnnotation = {
			x: Math.min(firstCoords.x, secondCoords.x),
			y: Math.min(firstCoords.y, secondCoords.y),
			width: Math.abs(secondCoords.x - firstCoords.x),
			height: Math.abs(secondCoords.y - firstCoords.y),
			id: ++nextId,
			color: 'green',
			tagId,
			itemId,
		};

		// Throw out anntations that are too small :)
		if (newAnnotation.width < 0.05 || newAnnotation.height < 0.05) {
			return;
		}

		annotations = [...annotations, newAnnotation];
	};

	const updateRectangleRect = () =>
		(imageRectangle = imageElement
			? imageElement.getBoundingClientRect()
			: null);

	const onDeleteImage = async () => {
		await deleteImage(image.id);
		if (!image) {
			return;
		}
		currentIndex++;
		loadAnnotations();
		nextId = 0;
		if (currentIndex >= images.length) {
			onAnnotatorDepleted();
			currentIndex = 0;
		}
	};

	const onRotateLeft = async () => {
		await rotateImage({id: image.id, angle: -90});
		annotations = [];
	};

	const onRotateRight = async () => {
		await rotateImage({id: image.id, angle: 90});
		annotations = [];
	};

	function handleKeydown(event) {
		if (event.code === 'Enter') {
			if (annotations.length > 0) {
				submitAndNext();
			}
		} else if (event.code === 'Delete') {
			onDeleteImage();
		} else if (event.code === 'ArrowUp') {
			const idx = recentTags.indexOf(tagId);
			if (idx > 0) {
				tagId = recentTags[idx - 1];
			}
		} else if (event.code === 'ArrowDown') {
			const idx = recentTags.indexOf(tagId);
			if (idx < recentTags.length - 1) {
				tagId = recentTags[idx + 1];
			}
		}
	}

	const handleContext = (e) => {
		e.preventDefault();
	};

	/* 	const handleScroll = (e) => {
		e.preventDefault();
		const idx = recentTags.indexOf(tagId);
		if (e.deltaY > 0) {
			if (idx < recentTags.length - 1) {
				tagId = recentTags[idx + 1];
			}
		} else if (idx > 0) {
			tagId = recentTags[idx - 1];
		}
	}; */

	onMount(async () => {
		items = await getShortItems();
		tags = [];
		//Get all the available tags
		if (items.length) {
			items.forEach((item) => {
				item.tags.forEach((tag) => {
					if (!tags.some((tag2) => tag.id === tag2.id)) {
						tags.push(tag);
					}
				});
			});
			itemId = items[0].id;
			tagId = items[0].tags[0].id;
		}
		loadAnnotations();
	});
</script>

<svelte:window on:resize={updateRectangleRect} on:keydown={handleKeydown} />

<div class="sidebar">
	<p>Images loaded: {images.length}</p>
	<div class="button-bar">
		<Button on:click={onDeleteImage}>Delete</Button>
		<Button on:click={onRotateLeft}>
			<div class="material-icons nomargin">rotate_left</div>
		</Button>
		<Button on:click={onRotateRight}>
			<div class="material-icons nomargin">rotate_right</div>
		</Button>
		<Button on:click={submitAndNext}>Next</Button>
		<Button on:click={onHotkeys}>Hotkeys</Button>
	</div>

	<div>
		<div class="nopad">Item</div>
		<!-- svelte-ignore a11y-no-onchange -->
		<select
			class="styled"
			bind:value={itemId}
			on:change={() =>
				(tagId = items.find((i) => i.id === itemId).tags[0].id)}>
			{#if items && items.length}
				{#each items as item}
					<option value={item.id} selected={itemId === item.id}>
						{item.name}
					</option>
				{/each}
			{:else}
				<option value={undefined}>No items found in location</option>
			{/if}
		</select>
	</div>

	<div>
		<div class="nopad">Tag</div>
		<select class="styled" bind:value={tagId}>
			{#if items && itemId && items.length}
				{#each items.find((i) => i.id === itemId).tags as tag}
					<option value={tag.id}>{tag.name}</option>
				{/each}
			{:else}
				<option value={undefined}>No tags found in location</option>
			{/if}
		</select>
	</div>

	<div>
		<div class="nopad">Recent tags</div>
		{#if items && itemId && items.length}
			{#each [...recentTags] as tag}
				<label>
					<input type="radio" bind:group={tagId} value={tag} />
					{getTagById(tag).name}
				</label>
				<br />
			{/each}
		{/if}
	</div>

	<div>
		<div class="nopad">Annotations <i>(click to delete)</i></div>
		{#each annotations as annotation}
			<div
				class="tag"
				on:hover={() => (annotation.color = 'red')}
				on:click={() =>
					(annotations = annotations.filter(
						(a) => a.id !== annotation.id
					))}>
				{annotation.id}:
				{getTagById(annotation.tagId).name}
			</div>
		{/each}
	</div>
</div>

<div class="imagearea">
	{#if image && items.length}
		<div
			class="img-overlay-wrap"
			on:mousemove={handleMouseMove}
			on:mousedown={handleMouseDown}
			on:mouseup={handleMouseUp}
			on:contextmenu={handleContext}>
			<img
				class="image"
				draggable="false"
				alt="Missing file"
				src={image.imageURL}
				bind:this={imageElement}
				on:load={updateRectangleRect} />
			{#if imageRectangle}
				<svg on:error={() => {}}>
					{#each annotations as annotation}
						<rect
							x={ratioToPixelsX(annotation.x)}
							y={ratioToPixelsY(annotation.y)}
							width={annotation.width * imageRectangle.width}
							height={annotation.height * imageRectangle.height}
							stroke={getTagColor(annotation.tagId)}
							fill={getTagColor(annotation.tagId)}
							fill-opacity="0.2" />
						<rect
							x={ratioToPixelsX(annotation.x)}
							y={ratioToPixelsY(annotation.y)}
							width={annotation.width * imageRectangle.width}
							height={16}
							stroke={getTagColor(annotation.tagId)}
							fill={getTagColor(annotation.tagId)}
							fill-opacity="0.8" />
						<text
							x={ratioToPixelsX(annotation.x) + 2}
							y={ratioToPixelsY(annotation.y) + 12}
							class="annotation-text"
							fill="white">
							{getAnnotationText(annotation)}
						</text>
					{/each}
					{#if clickActive}
						<rect
							x={ratioToPixelsX(
								Math.min(firstCoords.x, secondCoords.x)
							)}
							y={ratioToPixelsY(
								Math.min(firstCoords.y, secondCoords.y)
							)}
							width={Math.abs(secondCoords.x - firstCoords.x) *
								imageRectangle.width}
							height={Math.abs(secondCoords.y - firstCoords.y) *
								imageRectangle.height}
							stroke={getActiveRectColor()}
							fill={getActiveRectColor()}
							stroke-opacity="1"
							fill-opacity="0.1" />
						<rect
							x={ratioToPixelsX(
								Math.min(firstCoords.x, secondCoords.x)
							)}
							y={ratioToPixelsY(
								Math.min(firstCoords.y, secondCoords.y)
							)}
							width={Math.abs(secondCoords.x - firstCoords.x) *
								imageRectangle.width}
							height={Math.abs(secondCoords.y - firstCoords.y) *
								imageRectangle.height}
							fill="green"
							fill-opacity="0.2" />
					{:else}
						<rect
							x={ratioToPixelsX(cursorPlacement.x)}
							width="2"
							height="10000"
							fill="green" />
						<rect
							y={ratioToPixelsY(cursorPlacement.y)}
							width="10000"
							height="2"
							fill="green" />
					{/if}
				</svg>
			{/if}
		</div>
	{:else if !items.length}
		<div class="no-images">
			<div class="annotation-cheer">
				<div class="annotation-cheer-bg" />
				<p class="annotation-progress">-</p>
				<p class="progress-label">No annotations</p>
			</div>
			<p>
				There are no items in this location, go create some so you can
				annotate!
			</p>
		</div>
	{:else}
		<div class="no-images">
			<div class="annotation-cheer">
				<div class="annotation-cheer-bg" />
				<p class="annotation-progress">100%</p>
				<p class="progress-label">Annotation Completed</p>
			</div>
			<p>Good job, You have annotated all the images!</p>
		</div>
	{/if}
</div>

<AnnotatorHotkeyDialog bind:open={openHotkeyDialog} />

<style>
	.button-bar {
		display: inline-grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		gap: 10px;
	}
	.nomargin {
		font-size: 18px;
		margin: 0;
	}
	.annotation-cheer {
		position: relative;
		border-radius: 50%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		align-self: center;
		width: 150px;
		height: 150px;
	}
	.annotation-cheer-bg {
		z-index: 1;
		position: absolute;
		width: 150px;
		height: 150px;
		background-color: #69f5a1;
		border-radius: 10px;
	}
	.annotation-progress {
		z-index: 2;
		margin: 0;
		font-size: 40px;
		position: relative;
	}
	.progress-label {
		position: relative;
		z-index: 2;
		margin: 0;
		font-size: 15px;
	}
	.imagearea {
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.no-images {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
	.no-images p {
		text-align: center;
		max-width: 200px;
	}
	.sidebar {
		width: 280px;
		display: flex;
		flex-direction: column;
		grid-gap: 1rem;
		margin-right: 10px;
	}
	.nopad {
		padding: 0;
		margin: 0;
		color: gray;
		font-size: 18px;
	}
	.img-overlay-wrap {
		position: relative;
		display: inline-block;
		padding: 40px; /*This should match the ANNOTATOR_PADDING value*/
		background-color: #cccc;
	}
	.img-overlay-wrap svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		color: limegreen;
	}
	img {
		border-radius: 5px;
		user-select: none;
		max-width: 100%;
	}
	.tag {
		width: 80%;
		height: 20px;
		padding: 5px;
		margin: 5px;
		cursor: pointer;
		border: solid 1px green;
		border-radius: 5px;
		user-select: none;
	}
	.annotation-text {
		font-size: 12px;
		user-select: none;
	}
</style>
