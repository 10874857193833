<script>
	import {createEventDispatcher} from 'svelte';
	import {postBarcodeLink} from '../../api/barcodelinks';

	const dispatch = createEventDispatcher();

	import Dialog from '../Dialog.svelte';

	let error = '';
	let close;

	export let open;
	export let moduleId;

	let barcodeLink = null;

	export let onUpdate = () => {};

	const onSubmit = async () => {
		try {
			await postBarcodeLink({moduleId, value: barcodeLink.value});

			barcodeLink = null;
			dispatch('submit');
			close();
			onUpdate();
		} catch (e) {
			error = e.error ? e.error : e;
		}
	};

	$: barcodeLink = barcodeLink || {
		value: '',
	};
</script>

<form on:submit|preventDefault={onSubmit}>
	<Dialog title="Register barcode" bind:open bind:close bind:error>
		<label>
			Value
			<input type="text" bind:value={barcodeLink.value} required />
		</label>
	</Dialog>
</form>
