<script>
	import {onMount} from 'svelte';
	import {createEventDispatcher} from 'svelte';

	import {getGlobalItem, updateGlobalItem} from '../api/items';

	import Page from '../components/Page.svelte';
	import Button from '../components/Button.svelte';
	import Topbar from '../components/Topbar.svelte';

	let error = '';
	let item;
	let itemName;

	export let itemId;

	const updateData = async () => {
		item = await getGlobalItem(itemId);
		itemName = item.name;
	};

	onMount(async () => {
		await updateData();
	});

	const dispatch = createEventDispatcher();

	const onSubmit = async () => {
		try {
			if (item.id) {
				await updateGlobalItem(item);
				await updateData();
			}
			error = '';

			dispatch('submit');
		} catch (e) {
			error = e.error ? e.error : e;
		}
	};
</script>

{#if item}
	<Page title={itemName}>
		<div class="container">
			<div class="left-side">
				<div clas="about-item">
					<form on:submit|preventDefault={onSubmit}>
						<Topbar small title="About this item" />
						<label>
							Name
							<input
								type="text"
								bind:value={item.name}
								required />
						</label>
						<label>
							Description
							<input type="text" bind:value={item.description} />
						</label>
						Thumbnail
						<label class="inline-input">
							{#if item.thumbnailUrl}
								<img
									class="thumb"
									src={item.thumbnailUrl}
									alt="thumbnail" />
							{/if}
							<input
								accept="image/*"
								type="file"
								class={!item.imageUrl && 'fileupload'}
								bind:files={item.files} />
						</label>
						<div>{error}</div>
						<Button submit>Save item</Button>
					</form>
				</div>
			</div>
		</div>
	</Page>
{/if}

<style>
	.container {
		display: flex;
		justify-content: space-between;
	}
	.left-side {
		flex-basis: 40%;
	}
	.right-side {
		flex-basis: 55%;
	}
	.table-container {
		margin-bottom: 30px;
	}
	.thumb {
		max-width: 100%;
		max-height: 95px;
	}
	.inline-input {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.inline-input img {
		border-radius: 5px;
		margin-right: 10px;
	}
	.inline-input input {
		display: inline-block;
	}
	label {
		font-size: 14px;
	}
	@media screen and (max-width: 1300px) {
		.item-container {
			flex-direction: column;
		}
		.about-item {
			width: 100%;
		}
		.container {
			flex-direction: column;
			grid-template-columns: 100%;
		}
	}
</style>
