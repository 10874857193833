<script>
	import Page from '../components/Page.svelte';
	import Button from '../components/Button.svelte';
	import StoreTable from '../components/StoreTable.svelte';
	import LocationDialog from '../components/dialogs/LocationDialog.svelte';

	//This has its own implementation for locations to avoid putting a limit on the location switcher and other places locations are used
	import {locations} from '../stores/locationsManager';
	import {shortLocations} from '../stores/short/locations';

	let openLocationDialog;
	let clickedLocation;
	let onUpdate;

	const onAdd = () => {
		clickedLocation = null;
		openLocationDialog();
	};

	const onEdit = (location) => {
		clickedLocation = {
			...location,
			modelId: location.model?.id || null,
		};
		openLocationDialog();
	};

	const onUpdatePre = () => {
		shortLocations.fetch();
		onUpdate();
	};

	const onDelete = async (location) => {
		if (
			!confirm(
				`Are you sure you want to delete location ${location.name}?`
			)
		) {
			return;
		}
		try {
			await locations.delete(location.id);
		} catch (e) {
			if (e.error) alert(e.error);
		}
		onUpdate();
	};

	const columns = [
		{
			title: 'Name',
			value: (location) => location.name,
			orderKey: 'name',
			href: (location) => `/location/${location.id}`,
		},
		{
			title: 'Description',
			value: (location) => location.description,
			hide: 'medium',
			orderKey: 'description',
		},
		/*{
			title: 'Items',
			value: (location) => location.items.length,
			hide: 'small',
		},*/
	];

	const actions = [
		{
			title: 'Edit',
			icon: 'edit',
			action: onEdit,
		},
		{
			title: 'Delete',
			icon: 'delete',
			action: onDelete,
		},
	];
</script>

<Page title="Locations">
	<svelte:fragment slot="toolbar">
		<Button on:click={onAdd}>Add location</Button>
	</svelte:fragment>
	<StoreTable
		store={locations}
		columns={columns}
		actions={actions}
		bind:onUpdate />
	<LocationDialog
		location={clickedLocation}
		bind:open={openLocationDialog}
		on:submit={onUpdatePre} />
</Page>
