<script>
	import {router} from 'tinro';

	import {logout} from '../api/authentication';
	import {user} from '../stores/user';
	import {token} from '../stores/token';
	import {localstore} from '../stores/localstore';

	import Drawer from './Drawer.svelte';
	import SideBarItem from './SideBarItem.svelte';
	import LocationSwitcher from './LocationSwitcher.svelte';
	import EnvironmentBanner from './EnvironmentBanner.svelte';

	import {PERMISSIONS, checkPerms, checkGlobalPerms} from '../permissions';

	const logo = '/logo.svg';

	$: active = $router.path;

	const checker = () => {
		const regex = /(?:\/location\/)(\d+)/;
		const matches = $router.path.match(regex);
		if (matches) {
			$localstore.locationId = Number(matches[1]);
		}
	};

	checker();

	let miniWindow;
	let openDrawer;
	let closeDrawer;

	/* eslint-disable */
	//Disables cause these are produced in build scripts
	const buildType = myapp.env.BUILD_TYPE;
	/* eslint-enable */
</script>

<page>
	<div class="unifractal-top-bar" variant="static">
		{#if miniWindow}
			<div class="icon-button material-icons" on:click={openDrawer}>
				menu
			</div>
		{/if}
	</div>

	<div class="page-wrapper">
		<Drawer bind:open={openDrawer} bind:close={closeDrawer} bind:miniWindow>
			<div class="drawer-content">
				<div class="menu-top">
					<h1 class="drawer-title">
						<a href="/">
							<img
								src={logo}
								alt="Unifractal"
								class="appbar-logo" />
						</a>
					</h1>
					<div class="drawer-list">
						{#if !checkPerms($user, $user?.locationId, PERMISSIONS.READ)}
							<SideBarItem
								handleClick={closeDrawer}
								icon="dashboard"
								name="Welcome to Unifractal"
								href={`/location/${$user?.locationId}`}
								activated={active ===
									`/location/${$user?.locationId}`} />
						{/if}
						{#if checkPerms($user, $user?.locationId, PERMISSIONS.READ_OVERVIEW)}
							<SideBarItem
								handleClick={closeDrawer}
								icon="dashboard"
								name="Overview"
								href={`/location/${$user?.locationId}`}
								activated={active ===
									`/location/${$user?.locationId}`} />
						{/if}
						{#if checkPerms($user, $user?.locationId, PERMISSIONS.MANAGE_CONTENT)}
							<SideBarItem
								handleClick={closeDrawer}
								icon="toc"
								name="Content"
								href={`/location/${$user?.locationId}/content`}
								activated={active ===
									`/location/${$user?.locationId}/content`} />
						{/if}
						{#if checkPerms($user, $user?.locationId, PERMISSIONS.MANAGE_USERS)}
							<SideBarItem
								handleClick={closeDrawer}
								icon="group"
								name="Users"
								href={`/location/${$user?.locationId}/users`}
								activated={active ===
									`/location/${$user?.locationId}/users`} />
						{/if}
						{#if checkPerms($user, $user?.locationId, PERMISSIONS.MANAGE_USERS)}
							<SideBarItem
								handleClick={closeDrawer}
								icon="group"
								name="User Groups"
								href={`/location/${$user?.locationId}/usergroups`}
								activated={active ===
									`/location/${$user?.locationId}/usergroups`} />
						{/if}
						{#if checkPerms($user, $user?.locationId, PERMISSIONS.MANAGE_IMAGES)}
							<SideBarItem
								handleClick={closeDrawer}
								icon="image"
								name="Images"
								href={`/location/${$user?.locationId}/images`}
								activated={active ===
									`/location/${$user?.locationId}/images`} />
						{/if}
						<!--{#if checkPerms($user, $user?.locationId, PERMISSIONS.ANNOTATE_IMAGES)}
							<SideBarItem
								handleClick={closeDrawer}
								icon="loyalty"
								name="Image Annotator"
								href={`/location/${$user?.locationId}/annotator`}
								activated={active ===
									`/location/${$user?.locationId}/annotator`} />
						{/if}-->
						{#if checkPerms($user, $user?.locationId, PERMISSIONS.MANAGE_CONTENT) && buildType !== 'PRODUCTION'}
							<!-- TODO SERVICE MESSAGES: REMOVE BUILD TYPE CHECK WHEN IMPLEMENTED IN APP -->
							<SideBarItem
								handleClick={closeDrawer}
								icon="support_agent"
								name="Service Messages"
								href={`/location/${$user?.locationId}/servicemessages`}
								activated={active ===
									`/location/${$user?.locationId}/servicemessages`} />
						{/if}
						{#if checkPerms($user, $user?.locationId, PERMISSIONS.MANAGE_ITEMS)}
							<SideBarItem
								handleClick={closeDrawer}
								icon="settings"
								name="Location Settings"
								href={`/location/${$user?.locationId}/settings`}
								activated={active ===
									`/location/${$user?.locationId}/settings`} />
						{/if}
						{#if checkPerms($user, $user?.locationId, PERMISSIONS.ADMIN)}
							<h3>Admin</h3>
							<SideBarItem
								handleClick={closeDrawer}
								icon="dashboard"
								name="Global Overview"
								href={`/admin/global-overview`}
								activated={active ===
									`/admin/global-overview`} />
							<SideBarItem
								handleClick={closeDrawer}
								icon="place"
								name="Locations"
								href={`/admin/locations`}
								activated={active === `/admin/locations`} />
							<SideBarItem
								handleClick={closeDrawer}
								icon="group"
								name="Roles"
								href={`/admin/roles`}
								activated={active === `/admin/roles`} />
							<SideBarItem
								handleClick={closeDrawer}
								icon="dashboard"
								name="Global Item Manager"
								href={`/admin/global-items`}
								activated={active === `/admin/global-items`} />
							<!--<SideBarItem
								handleClick={closeDrawer}
								icon="dns"
								name="Services"
								href={`/admin/services`}
								activated={active === `/admin/services`} />-->
						{/if}
						{#if checkGlobalPerms($user, PERMISSIONS.ADMIN)}
							<h3>Machine Learning</h3>
						{/if}
						{#if checkGlobalPerms($user, PERMISSIONS.ADMIN)}
							<!--<SideBarItem
								handleClick={closeDrawer}
								icon="model_training"
								name="Datasets"
								href={`/machine-learning/datasets`}
								activated={active ===
									`/machine-learning/datasets`} />-->
							<SideBarItem
								handleClick={closeDrawer}
								icon="model_training"
								name="Models"
								href={`/machine-learning/models`}
								activated={active ===
									`/machine-learning/models`} />
							<!--<SideBarItem
								handleClick={closeDrawer}
								icon="model_training"
								name="Runs"
								href={`/machine-learning/runs`}
								activated={active ===
									`/machine-learning/runs`} />-->
							<SideBarItem
								handleClick={closeDrawer}
								icon="model_training"
								name="Runs"
								href={`/machine-learning/classificationruns`}
								activated={active ===
									`/machine-learning/classificationruns`} />
							<SideBarItem
								handleClick={closeDrawer}
								icon="loyalty"
								name="Tags"
								href={`/machine-learning/tags`}
								activated={active ===
									`/machine-learning/tags`} />
						{/if}
					</div>
				</div>
				<div class="menu-bottom">
					<p class="location-switch-label">Switch Location</p>
					<div style="margin-left:10px;margin-right:10px;">
						<LocationSwitcher />
					</div>
					<SideBarItem
						handleClick={closeDrawer}
						icon="place"
						name="Create new Location"
						href={`/location/create`}
						activated={active === `/location/create`} />
					<SideBarItem
						handleClick={closeDrawer}
						icon="account_circle"
						name="Profile"
						href={`/profile`}
						activated={active === `/profile`} />
					<SideBarItem
						handleClick={async () => {
							$token = null;
							await logout();
							closeDrawer();
						}}
						href=""
						icon="logout"
						name="Sign out" />
				</div>
			</div>
		</Drawer>
		<div class="page-content" on:click={closeDrawer}>
			<EnvironmentBanner />
			<slot />
		</div>
	</div>
</page>

<style>
	:global(.unifractal-top-bar) {
		background-color: white;
		color: #30465e;
		position: sticky;
		top: 0;
		box-shadow: #0003 2px 2px 5px;
		z-index: 9;
	}
	.drawer-title {
		margin: 10px;
		vertical-align: middle;
	}
	.drawer-title img {
		vertical-align: middle;
		margin-bottom: 5px;
	}
	.drawer-content {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.drawer-list {
		overflow-y: auto;
		max-height: calc(100vh - 320px);
		border-bottom: 1px solid #ddd;
		border-top: 1px solid #ddd;
	}
	.drawer-list h3 {
		margin-bottom: 0;
		margin-left: 15px;
		font-size: 14px;
		font-weight: 600;
		color: #777;
	}

	.menu-top {
		display: flex;
		flex-direction: column;
		flex-shrink: 1;
	}
	.menu-bottom {
		flex-shrink: 0;
	}

	.location-switch-label {
		margin-bottom: 5px;
		margin-left: 15px;
		font-size: 14px;
		font-weight: 600;
		color: #777;
	}
	.page-content {
		width: 100%;
	}
	.page-wrapper {
		width: 100%;
		display: flex;
		min-height: 100vh;
	}
	page {
		display: flex;
		flex-flow: column;
		height: 100%;
	}

	.appbar-logo {
		width: 75%;
		max-width: 300px;
		margin-top: 10px;
	}
</style>
