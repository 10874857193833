import {writable} from 'svelte/store';

import {
	deleteService,
	getServices,
	postService,
	updateService,
} from '../api/services';

function createServices() {
	const {subscribe, set, update} = writable();
	const fetchServices = async (offset, limit) => {
		return set(await getServices(offset, limit));
	};

	return {
		subscribe,
		fetch: (offset, limit) => fetchServices(offset, limit),
		add: async (service) => {
			await postService(service);
		},
		change: async (service) => {
			await updateService(service);
		},
		delete: async (id) => {
			await deleteService(id);
			update((arr) => ({
				count: arr.count,
				records: arr.records.filter((service) => service.id !== id),
			}));
		},
	};
}

export const services = createServices();
