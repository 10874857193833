<script>
	import Dialog from '../Dialog.svelte';
	import {postTextbox} from '../../api/textboxes';

	let close;
	export let open;

	export let onUpdate = () => {};
	export let moduleId;

	let textbox = {
		title: '',
		text: '',
	};

	const clearFields = () => {
		textbox = {title: '', text: ''};
	};

	const onSubmit = async () => {
		await postTextbox(textbox, moduleId);
		onUpdate();
		clearFields();
		close();
	};
</script>

<form on:submit|preventDefault={onSubmit}>
	<Dialog title="Add textbox" bind:open bind:close>
		<label>
			Title
			<input type="text" bind:value={textbox.title} required />
		</label>
		<label>
			Text
			<textarea bind:value={textbox.text} required />
		</label>
	</Dialog>
</form>

<style>
</style>
