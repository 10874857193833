<script>
	import {onMount} from 'svelte';
	import {router} from 'tinro';
	import {fly} from 'svelte/transition';

	import Page from '../components/Page.svelte';
	import Button from '../components/Button.svelte';
	import Paginator from '../components/Paginator.svelte';
	import ImageDialog from '../components/dialogs/ImageDialog.svelte';
	import ImagePreviewModal from '../components/modals/ImagePreviewModal.svelte';

	import {deleteImage, getImages, rotateImage} from '../api/images';
	import {deleteImageAnnotation} from '../api/annotations';
	import {user} from '../stores/user';

	let openImagePreviewModal;

	let limit = 119;
	let openImageDialog;
	let selected = [];
	let images;

	// Mouse support
	let gridParent;
	let startX = 0;
	let startY = 0;
	let mouseButtonDown = false;

	$: offset = ($router.query.page - 1) * limit || 0;

	const onUpdate = async () => {
		images = await getImages(offset, limit);
	};

	onMount(async () => {
		await onUpdate();
	});

	const onUpload = async () => {
		openImageDialog();
		await onUpdate();
	};

	const onSelect = (image) => {
		selected = selected.includes(image)
			? selected.filter((i) => i !== image)
			: [...selected, image];
	};

	const onRotateLeft = async () => {
		await Promise.all(
			selected.map(async (image) => {
				await rotateImage({angle: -90, id: image.id});
			})
		);
		images = await getImages(offset, limit);
		selected = [];
	};

	const onRotateRight = async () => {
		await Promise.all(
			selected.map(async (image) => {
				await rotateImage({angle: 90, id: image.id});
			})
		);
		images = await getImages(offset, limit);
		selected = [];
	};

	const onDelete = async () => {
		if (
			!confirm(
				`Are you sure you want to delete ${selected.length} images?`
			)
		) {
			return;
		}
		await Promise.all(
			selected.map(async (image) => {
				return deleteImage(image.id);
			})
		);
		images = await getImages(offset, limit);
		selected = [];
	};

	const onDeleteAnnotations = async () => {
		if (
			!confirm(
				`Are you sure you want to delete annotations from ${selected.length} images?`
			)
		) {
			return;
		}
		await Promise.all(
			selected.map(async (image) => {
				return deleteImageAnnotation(image.id);
			})
		);
		images = await getImages(offset, limit);
		selected = [];
	};

	const openInAnnotator = () => {
		const ids = selected.map((image) => image.id).join(',');
		location.href = `/location/${$user?.locationId}/annotator?images=${ids}`;
	};

	const ANNOTATION_COLORS = [
		'#f44336',
		'#9C27B0',
		'#3F51B5',
		'#03A9F4',
		'#009688',
		'#4CAF50',
		'#CDDC39',
		'#FFC107',
		'#E91E63',
		'#673AB7',
		'#2196F3',
		'#00BCD4',
		'#8BC34A',
		'#FFEB3B',
		'#FF9800',
		'#FF5722',
	];

	const getTagColor = (tagId) => {
		return ANNOTATION_COLORS[(tagId % ANNOTATION_COLORS.length) - 1];
	};

	const onPreview = async () => {
		openImagePreviewModal();
	};

	const mouseDown = (e) => {
		startX = e.x;
		startY = e.y;
		mouseButtonDown = true;
	};

	const mouseUp = () => {
		mouseButtonDown = false;
	};

	const mouseMove = (e) => {
		if (!mouseButtonDown) {
			return;
		}

		selected = [];

		const x1 = Math.min(startX, e.x);
		const y1 = Math.min(startY, e.y);
		const x2 = Math.max(startX, e.x);
		const y2 = Math.max(startY, e.y);

		gridParent.childNodes.forEach((item) => {
			if (typeof item.getBoundingClientRect !== 'function') {
				return;
			}

			const box = item.getBoundingClientRect();
			const x = box.x + box.width / 2;
			const y = box.y + box.height / 2;

			if (x > x1 && y > y1 && x < x2 && y < y2) {
				onSelect(
					images.records.find((img) => img.id === Number(item.id))
				);
			}
		});
	};
</script>

<Page title="Images" crumbs={['images']}>
	<svelte:fragment slot="toolbar">
		{#if selected.length > 0}
			<Button on:click={onPreview}>Preview</Button>
			<Button on:click={openInAnnotator}>Open in annotator</Button>
			<Button on:click={onRotateLeft}>
				<div class="material-icons nomargin">rotate_left</div>
			</Button>
			<Button on:click={onRotateRight}>
				<div class="material-icons nomargin">rotate_right</div>
			</Button>
			<Button danger on:click={onDeleteAnnotations}>
				Delete annotations
			</Button>
			<Button danger on:click={onDelete}
				>Delete image{selected.length === 1 ? '' : 's'}</Button>
		{/if}
		<Button on:click={onUpload}>Upload</Button>
	</svelte:fragment>
	<div
		in:fly={{y: 10}}
		on:mousemove={mouseMove}
		on:mousedown={mouseDown}
		on:mouseup={mouseUp}
		bind:this={gridParent}>
		{#if images}
			{#each images.records as image}
				<div class="grid" id={image.id}>
					<div
						class="img-overlay-wrap"
						on:click={() => onSelect(image)}
						style={selected.includes(image) &&
							'transform: scale(0.9);filter: grayscale(100);'}>
						<svg>
							{#each image.annotations as annotation}
								<rect
									x="{(annotation.x - annotation.width / 2) *
										100}%"
									y="{(annotation.y - annotation.height / 2) *
										100}%"
									width="{annotation.width * 100}%"
									height="{annotation.height * 100}%"
									stroke={getTagColor(annotation.tagId)}
									stroke-width="2"
									fill-opacity="0" />
							{/each}
						</svg>
						<img alt="mlimage" class="item" src={image.url} />
					</div>
				</div>
			{/each}
			<Paginator
				count={images.count}
				bind:limit
				bind:offset
				on:next={onUpdate}
				on:previous={onUpdate} />
			<ImageDialog bind:open={openImageDialog} onUpdate={onUpdate} />
		{/if}
	</div>
	<ImagePreviewModal bind:open={openImagePreviewModal} images={selected} />
</Page>

<style>
	img {
		display: inline-block;
		width: 80px;
		height: 80px;
	}
	.img-overlay-wrap {
		position: relative;
		display: inline-block;
		transition: 0.2s;
		height: 80px;
	}
	.img-overlay-wrap svg {
		position: absolute;
		width: 100%;
		height: 100%;
	}
	.grid {
		display: inline-block;
		margin: 5px;
		user-select: none;
	}
	:global(.material-icons.nomargin) {
		margin: 0 !important;
		font-size: 14px;
	}
</style>
