<script>
	import {createEventDispatcher} from 'svelte';
	import Dialog from '../Dialog.svelte';
	import ProgressBar from '../ProgressBar.svelte';
	import {stripExtension} from '../../utils/fileUtils';
	import {postPicture} from '../../api/pictures';

	const dispatch = createEventDispatcher();

	let uploader;

	let progress = 0;
	let progressHidden = true;

	export let error = '';
	export let open;
	let close;
	export let onUpdate = () => {};

	export let picture;
	export let moduleId = null;
	export let hideChooser = false;

	$: picture = picture || {
		id: undefined,
		title: '',
		description: '',
		files: null,
		orderWeight: 1,
		access: 'private',
	};

	const onProgress = (event) => {
		progressHidden = false;
		progress = (event.loaded / event.total) * 100;
	};

	const onFileChange = (e) => {
		if (!e.target?.files?.length) {
			return;
		}
		const fileName = stripExtension(e.target.files[0].name);
		if (!picture.title || picture.title === '') {
			picture.title = fileName;
		}
	};

	const onSubmit = async () => {
		try {
			if (picture.files) {
				if (moduleId) {
					picture.moduleId = moduleId;
				}
				await postPicture(picture, onProgress);
			} else {
				throw new Error(
					'You must upload a picture using the button above'
				);
			}

			picture = null;
			progressHidden = true;

			if (uploader) {
				uploader.value = null;
			}

			dispatch('submit');
			onUpdate();
			close();
		} catch (e) {
			error = e.error ? e.error : e;
		}
	};
</script>

<form on:submit|preventDefault={onSubmit}>
	<Dialog
		title={picture.id ? 'Edit picture' : 'Add picture'}
		canScroll
		bind:open
		bind:close
		bind:error>
		{#if !picture.id && !hideChooser}
			<label>
				Picture
				<input
					bind:this={uploader}
					accept="image/*"
					type="file"
					class="fileupload"
					on:change={onFileChange}
					bind:files={picture.files} />
			</label>
		{/if}
		{#if !progressHidden}
			<ProgressBar progress={progress} closed={progressHidden} />
		{/if}
	</Dialog>
</form>
