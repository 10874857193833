<script>
	import {router} from 'tinro';

	import Table from './Table.svelte';

	export let columns = [];
	export let actions = [];
	export let selected = null;
	export let store;
	export let hidePaginator = false;

	export let onPause = null;
	export let onPlay = null;

	export let limit = 10;

	export let orderBy = 'name';
	export let orderByDirection = 'asc';
	export let emptyMessage = 'No records in table';

	export const onUpdate = () => {
		fetcher = store.fetch(
			($router.query.page - 1) * limit || 0,
			limit,
			$router.query.sort
		);
	};

	$: fetcher = store.fetch(
		($router.query.page - 1) * limit || 0,
		limit,
		$router.query.sort
	);
</script>

{#await fetcher then _}
	<Table
		columns={columns}
		actions={actions}
		rows={$store.records}
		count={$store.count}
		onUpdate={onUpdate}
		bind:onPause
		bind:onPlay
		bind:selected
		bind:emptyMessage
		bind:hidePaginator
		bind:limit />
{/await}
