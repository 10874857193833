import {BaseApi, LocationApi} from './api';

export const getServiceMessages = async (
	offset,
	limit,
	orderBy,
	unresolvedOnly
) => {
	const params = new URLSearchParams();
	params.set('offset', offset);
	params.set('limit', limit);
	if (orderBy) {
		params.set('orderBy', orderBy);
	}
	if (unresolvedOnly) {
		params.set('unresolvedOnly', unresolvedOnly);
	}

	return LocationApi.get(`/servicemessages?${params}`);
};

export const getServiceMessage = async (offset, limit, orderBy, messageId) => {
	const params = new URLSearchParams();
	if (offset) params.set('offset', offset);
	if (limit) params.set('limit', limit);
	if (orderBy) {
		params.set('orderBy', orderBy);
	}
	return LocationApi.get(`/servicemessages/${messageId}?${params}`);
};

export const getServiceMessagesByUserId = async (
	userId,
	offset,
	limit,
	orderBy,
	unresolvedOnly
) => {
	const params = new URLSearchParams();
	params.set('offset', offset);
	paramts.set('limit', limit);
	if (orderBy) {
		params.set('orderBy', orderBy);
	}
	if (unresolvedOnly) {
		params.set('unresolvedOnly', unresolvedOnly);
	}
	return BaseApi.get(`/servicemessages/user/${userId}`, params);
};

export const postServiceMessage = async (message) => {
	const data = new FormData();

	if (message.message) {
		data.append('message', message.message);
	}

	return LocationApi.post('/servicemessage', data);
};

export const postServiceMessageReply = async (reply) => {
	const data = new FormData();
	const messageId = reply.serviceMessageId;

	if (reply.message) {
		data.append('message', reply.message);
	}

	return LocationApi.post(`/servicemessage/${messageId}/reply`, data);
};

export const deleteServiceMessage = async (messageId) => {
	return LocationApi.delete(`/servicemessages/${messageId}`);
};

export const deleteServiceMessageReply = async (messageId, replyId) => {
	return LocationApi.delete(`/servicemessages/${messageId}/reply/${replyId}`);
};

export const resolveServiceMessage = async (messageId) => {
	return LocationApi.put(`/servicemessages/${messageId}/resolve`);
};

export const unresolveServiceMessage = async (messageId) => {
	return LocationApi.put(`/servicemessages/${messageId}/unresolve`);
};
