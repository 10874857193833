<script>
	import Page from '../components/Page.svelte';
	import Button from '../components/Button.svelte';
	import Table from '../components/Table.svelte';

	import {getUserInLocation} from '../api/users';
	import {
		getShortUserGroups,
		postAddUserToGroup,
		removeUserFromGroup,
	} from '../api/usergroups';

	export let userId;

	let shortUserGroups = [];
	let selectedUserGroup = null;

	let user = {
		name: '',
		loginLogs: [],
		userGroups: [],
	};
	const updateData = async () => {
		user = await getUserInLocation(userId);
		shortUserGroups = await getShortUserGroups();
		let filtered = filterGroups(shortUserGroups);
		if (filtered.length) selectedUserGroup = filtered[0];
	};
	updateData();

	const onDeleteUserGroup = async (userGroup) => {
		await removeUserFromGroup(userGroup.id, userId);
		updateData();
	};
	const userGroupColumns = [
		{
			title: 'Name',
			value: (record) => record.name,
		},
	];
	const userGroupActions = [
		{
			title: 'Delete',
			icon: 'delete',
			action: onDeleteUserGroup,
		},
	];

	const addUserToGroup = async () => {
		if (!selectedUserGroup) return;

		await postAddUserToGroup(selectedUserGroup.id, userId);
		updateData();
	};

	const filterGroups = (groups) => {
		return groups.filter(
			(group) => !user.userGroups.find((g1) => g1.id === group.id)
		);
	};
</script>

<Page title={user.name} crumbs={['users', user.name]}>
	<div class="userinfo">
		<p>Name: {user.name}</p>
		<p>Email: {user.email}</p>
		<p>Last login: {user.lastLogin}</p>
	</div>
	<br />
	<h2>User Groups</h2>
	<Table
		rows={user.userGroups}
		columns={userGroupColumns}
		actions={userGroupActions}
		count={user.userGroups.length}
		hidePaginator />

	<p class="no-bottom-margin">Add user to group</p>
	<select class="inline" bind:value={selectedUserGroup}>
		{#each filterGroups(shortUserGroups) as userGroup}
			<option value={userGroup}>{userGroup.name}</option>
		{/each}
	</select>
	<Button filled on:click={addUserToGroup}>Add user group</Button>
</Page>

<style>
	.no-bottom-margin {
		margin-bottom: 5px;
	}
	.inline {
		width: auto;
		padding-top: 6px;
		padding-bottom: 6px;
		margin-right: 5px;
		min-width: 150px;
	}
</style>
