import {writable} from 'svelte/store';

import {
	deleteClassificationRun,
	getClassificationRuns,
} from '../api/classificationRuns';

function createClassificationRuns() {
	const {subscribe, set, update} = writable();
	const fetchRuns = async (offset, limit, orderBy) => {
		return set(await getClassificationRuns(offset, limit, orderBy));
	};

	return {
		subscribe,
		fetch: (offset, limit, orderBy = 'id:asc') =>
			fetchRuns(offset, limit, orderBy),
		delete: async (id) => {
			await deleteClassificationRun(id);
			update((arr) => ({
				count: arr.count,
				records: arr.records.filter((runs) => runs.id !== id),
			}));
		},
	};
}

export const classificationRuns = createClassificationRuns();
