import {writable} from 'svelte/store';

import {getOverview} from '../api/overview';

function createOverview() {
	const {subscribe, set} = writable(null);
	const fetchOverview = async (offset, limit) => {
		const overview = await getOverview(offset, limit);
		set(overview);
	};

	return {
		subscribe,
		fetch: (offset, limit) => fetchOverview(offset, limit),
	};
}

export const overview = createOverview();
