import {BaseApi, LocationApi, mediaPost} from './api';

export const deleteVideo = async (videoId) => {
	return LocationApi.delete(`/video/${videoId}`);
};

export const getVideos = async (offset, limit, sort) => {
	return LocationApi.get(
		`/videos?offset=${offset}&limit=${limit}&orderBy=${sort}`
	);
};

export const getVideosFromLocation = async ({id, offset, limit, sort}) => {
	return BaseApi.get(
		`/location/${id}/videos?offset=${offset}&limit=${limit}&orderBy=${sort}`
	);
};

export const getVideo = async (id) => {
	return LocationApi.get(`/video/${id}`);
};

export const postVideo = async (video, onUploadProgress) => {
	const data = new FormData();
	if (video.title) {
		data.append('title', video.title);
	}
	if (video.description) {
		data.append('description', video.description);
	}
	if (video.moduleId) {
		data.append('moduleId', video.moduleId);
	}
	if (video.locationId) {
		data.append('locationId', video.locationId);
	}
	if (video.access) {
		data.append('access', video.access);
	}

	const config = {
		onUploadProgress,
	};
	return mediaPost('/video', data, video.files[0], config);
};

export const updateVideo = async (video) => {
	return LocationApi.update('/video/' + video.id, video);
};

export const getPublicForMyLocations = async () => {
	return LocationApi.get(`/videos/fromlocations`);
};

export const relateToLocation = async (videoId) => {
	return LocationApi.post(`video/relatelocation`, {videoId});
};
