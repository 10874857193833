<script>
	import {fly} from 'svelte/transition';

	import Page from '../components/Page.svelte';
	import StoreTable from '../components/StoreTable.svelte';
	import Button from '../components/Button.svelte';
	import ModuleLibraryDialog from '../components/dialogs/ModuleLibraryDialog.svelte';
	import LinkContentDialog from '../components/dialogs/LinkContentDialog.svelte';
	import DeleteDialog from '../components/dialogs/DeleteDialog.svelte';

	import {user} from '../stores/user';
	import {modules} from '../stores/modules';
	import ModuleDialog from '../components/dialogs/ModuleDialog.svelte';
	import {moveModuleUp, moveModuleDown} from '../api/modules';

	console.log('loading module manger');

	let selected = [];

	let openDeleteDialog;
	let openModuleDialog;
	let openLibraryDialog;
	let openLinkContentDialog;
	let clickedModule;

	let onUpdate;

	const onAdd = () => {
		clickedModule = null;
		openModuleDialog();
	};

	const onAddFromLibrary = () => {
		openLibraryDialog();
	};

	const onEdit = (item) => {
		clickedModule = item;
		openModuleDialog();
	};

	const onDelete = async (module) => {
		clickedModule = module;
		openDeleteDialog();
	};

	const onMoveUp = async (module) => {
		await moveModuleUp(module.id, 'module');
		onUpdate();
	};

	const onMoveDown = async (module) => {
		await moveModuleDown(module.id, 'module');
		onUpdate();
	};

	const onDeleteMany = async () => {
		if (
			!confirm(
				`Are you sure you want to delete ${selected.length} modules ?`
			)
		) {
			return;
		}
		await Promise.all(
			selected.map(async (module) => {
				return modules.delete(module.id);
			})
		);
		selected = [];

		onUpdate();
	};

	const onLink = async (module) => {
		clickedModule = module;
		openLinkContentDialog();
	};

	const columns = [
		{
			title: 'Name',
			value: (row) => row.name,
			href: (row) => `/location/${$user?.locationId}/modules/${row.id}`,
			orderKey: 'name',
			truncate: true,
		},
		{
			title: 'Description',
			value: (row) => row.description,
			orderKey: 'description',
			truncate: true,
		},
		{
			title: 'Videos',
			value: (row) => row.videoCount,
			hide: 'medium',
			orderKey: 'videoCount',
		},
		{
			title: 'Documents',
			value: (row) => row.documentCount,
			hide: 'medium',
			orderKey: 'documentCount',
		},
		{
			title: 'Textboxes',
			value: (row) => row.textboxCount,
			hide: 'medium',
			orderKey: 'textboxCount',
		},
		{
			title: 'Links',
			value: (row) => row.linkCount,
			hide: 'medium',
			orderKey: 'linkCount',
		},
	];

	const actions = [
		{
			title: 'Move up',
			icon: 'arrow_upward',
			action: onMoveUp,
		},
		{
			title: 'Move down',
			icon: 'arrow_downward',
			action: onMoveDown,
		},
		{
			title: 'Edit',
			icon: 'edit',
			action: onEdit,
		},
		{
			title: 'Delete',
			icon: 'delete',
			action: onDelete,
		},
		{
			title: 'Link to module',
			icon: 'add_link',
			action: onLink,
		},
	];
</script>

<Page
	title="Modules"
	description="Manage your modules or add new ones."
	crumbs={['modules']}>
	<svelte:fragment slot="toolbar">
		<Button on:click={onAdd}>Add module</Button>
		<Button on:click={onAddFromLibrary}>Browse library</Button>

		{#if selected.length > 0}
			<span in:fly>
				<Button on:click={onDeleteMany}>delete</Button>
			</span>
		{/if}
	</svelte:fragment>

	<StoreTable
		store={modules}
		columns={columns}
		actions={actions}
		bind:onUpdate />

	<ModuleDialog
		bind:open={openModuleDialog}
		module={clickedModule}
		on:submit={onUpdate} />
	<ModuleLibraryDialog bind:open={openLibraryDialog} on:submit={onUpdate} />
	<LinkContentDialog
		bind:open={openLinkContentDialog}
		moduleId={clickedModule?.id} />
	<DeleteDialog
		bind:open={openDeleteDialog}
		module={clickedModule}
		on:submit={onUpdate} />
</Page>
