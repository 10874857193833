<script>
	export let orderByDirection;
	export let orderBy;
	export let onSort;
	export let sortKey;
	export let name;
</script>

{#if sortKey}
	{#if orderByDirection === 'asc' && orderBy === sortKey}
		<button class="btn" on:click={() => onSort(sortKey)}>
			{name} &#9650;</button>
	{:else if orderByDirection === 'desc' && orderBy === sortKey}
		<button class="btn" on:click={() => onSort(sortKey)}>
			{name} &#9660;</button>
	{:else}
		<button class="btn" on:click={() => onSort(sortKey)}> {name}</button>
	{/if}
{:else}
	{name}
{/if}

<style>
	.btn {
		font-size: 0.875rem;
		padding: 0;
		border: none;
		background-color: inherit;
		cursor: pointer;
		outline: 0;
	}
</style>
