<script>
	import {createEventDispatcher, onMount} from 'svelte';

	import {getVideo, updateVideo} from '../api/videos';
	import {locations} from '../stores/locations';

	import Page from '../components/Page.svelte';
	import Button from '../components/Button.svelte';

	export let videoId;
	const dispatch = createEventDispatcher();

	let error = '';

	let video;
	let videoTitle;

	locations.fetch();

	const updateData = async () => {
		video = await getVideo(videoId);
		videoTitle = video.title;
	};

	onMount(async () => {
		await updateData();
	});

	const onEdit = async () => {
		try {
			await updateVideo(video);
			await updateData();
			dispatch('submit');
		} catch (e) {
			error = e.error;
		}
	};
</script>

{#if video}
	<Page title={videoTitle} crumbs={['videos', videoTitle]}>
		<div class="container">
			<div class="left-side">
				<div class="video-container">
					{#if video.videoUrl}
						<!-- svelte-ignore component-name-lowercase -->
						<video class="video" src={video.videoUrl} controls
							><track kind="captions" /></video>
					{:else}
						<div class="processing">
							Video processing, please wait
						</div>
					{/if}
				</div>

				<div class="info-container">
					<form on:submit|preventDefault={onEdit}>
						<label>
							{'Title'}
							<input
								type="text"
								bind:value={video.title}
								required />
						</label>
						<label>
							{'Description'}
							<input type="text" bind:value={video.description} />
						</label>
						<label>
							{'Access'}
							<select bind:value={video.access}>
								<option value="private">Location-only</option>
								<option value="public">Global</option>
							</select>
						</label>
						<label>
							{'Order weight'}
							<input
								type="number"
								bind:value={video.orderWeight}
								required />
						</label>
						<div class="dialog-btn">
							<Button submit>Save</Button>
						</div>
						<div class="errortext">{error}</div>
					</form>
				</div>
			</div>

			<div class="right-side">
				<!--
				<div class="table-container">
					<Topbar small title="Items" />
					<Table
						rows={video.itemmodules}
						columns={itemColumns}
						hidePaginator
						bind:limit={limit1}
						bind:offset={offset1} />
				</div>
				-->
				<!--
				<div class="table-container">
					<Topbar small title="Locations" />
					<Table
						rows={video.locations}
						columns={locationColumns}
						hidePaginator
						bind:limit={limit2}
						bind:offset={offset2} />
				</div>
				-->
			</div>
		</div>
	</Page>
{/if}

<style>
	.container {
		display: flex;
		justify-content: space-between;
	}
	.left-side {
		flex-basis: 40%;
	}
	.right-side {
		flex-basis: 55%;
	}
	.video-container {
		margin-bottom: 20px;
		background-color: black;
		height: 300px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 4px;
	}
	video {
		margin: auto;
		display: block;
		width: 100%;
		height: 100%;
	}
	.processing {
		color: white;
	}
	@media screen and (max-width: 1300px) {
		.container {
			flex-direction: column;
			grid-template-columns: 100%;
		}
	}
</style>
