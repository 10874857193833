<script>
	import {createEventDispatcher} from 'svelte';
	import Dialog from '../Dialog.svelte';
	import {postVideo, updateVideo} from '../../api/videos';
	import ProgressBar from '../ProgressBar.svelte';
	import {stripExtension} from '../../utils/fileUtils';

	const dispatch = createEventDispatcher();

	let uploader;

	let progress = 0;
	let progressHidden = true;

	export let error = '';
	export let open;
	let close;
	export let onUpdate = () => {};

	export let video;
	export let moduleId = null;
	export let hideChooser = false;

	$: video = video || {
		id: undefined,
		title: '',
		description: '',
		files: null,
		orderWeight: 1,
		access: 'private',
	};

	const onProgress = (event) => {
		progressHidden = false;
		progress = (event.loaded / event.total) * 100;
	};

	const onFileChange = (e) => {
		if (!e.target?.files?.length) {
			return;
		}
		const fileName = stripExtension(e.target.files[0].name);
		if (!video.title || video.title === '') {
			video.title = fileName;
		}
	};

	const onSubmit = async () => {
		try {
			if (video.id) {
				await updateVideo(video);
			} else if (video.files) {
				if (moduleId) {
					video.moduleId = moduleId;
				}
				await postVideo(video, onProgress);
			} else {
				throw new Error(
					'You must upload a video using the button above'
				);
			}

			video = null;
			progressHidden = true;

			if (uploader) {
				uploader.value = null;
			}

			dispatch('submit');
			onUpdate();
			close();
		} catch (e) {
			error = e.error ? e.error : e;
		}
	};
</script>

<form on:submit|preventDefault={onSubmit}>
	<Dialog
		title={video.id ? 'Edit video' : 'Add video'}
		canScroll
		bind:open
		bind:close
		bind:error>
		<label>
			Title
			<input type="text" bind:value={video.title} required />
		</label>
		<label>
			Description
			<input type="text" bind:value={video.description} />
		</label>
		<label>
			Access
			<select bind:value={video.access}>
				<option value="private">This location only</option>
				<option value="public">Global</option>
			</select>
		</label>
		{#if !video.id && !hideChooser}
			<label>
				Video
				<input
					bind:this={uploader}
					accept="video/*"
					type="file"
					class="fileupload"
					on:change={onFileChange}
					bind:files={video.files} />
			</label>
		{/if}
		{#if !progressHidden}
			<ProgressBar progress={progress} closed={progressHidden} />
		{/if}
	</Dialog>
</form>
