<script>
	import validator from 'email-validator';
	import {createEventDispatcher} from 'svelte';
	const dispatch = createEventDispatcher();

	import Dialog from '../Dialog.svelte';

	import {sendInvite} from '../../api/invites';
	import {shortRoles} from '../../stores/short/roles';
	import {shortUserGroups} from '../../stores/short/usergroups';
	import {user} from '../../stores/user';

	shortRoles.fetch();
	shortUserGroups.fetch();

	let emails = '';
	let roleId;
	const selectedUserGroups = {};
	let customTemplateId = '';

	let showCustomTemplateField = false;

	let error = '';
	let close;
	export let open;

	const onSubmit = async () => {
		// Returns an array of userGroupIds e.g. [1,2,3]
		const userGroups = Object.entries(selectedUserGroups)
			.filter(([, value]) => value) //Filter: Only get entries with a truthy value
			.map(([id]) => Number(id)); //Map: Return only the id as a number

		try {
			// Filter out blank and repeated entries
			const emailArray = [
				...new Set(
					emails
						.split('\n')
						.filter((email) => validator.validate(email))
				),
			];
			emails = emailArray.join('\n');

			await Promise.all(
				emailArray.map(async (email) => {
					await sendInvite(
						email,
						roleId,
						userGroups,
						customTemplateId
					);
					console.log(`successfully invited: ${email}`);
				})
			);

			emails = '';
			dispatch('submit');
			close();
		} catch (e) {
			error = e.error ? e.error : e;
		}
	};
</script>

<form on:submit|preventDefault={onSubmit}>
	<Dialog title="Bulk invite users" bind:open bind:close bind:error canScroll>
		<label>
			Emails
			<textarea rows="10" bind:value={emails} />
		</label>
		<p class="tooltip">One email address per line</p>
		<label>
			Role
			<select bind:value={roleId}>
				{#each Object.entries($shortRoles) as [roleId, roleName]}
					<option value={Number(roleId)}>
						{roleName}
					</option>
				{/each}
			</select>
		</label>
		<br />
		{#if Object.entries($shortUserGroups).length}
			<p class="no-margin-bot">Add user to these groups:</p>
		{/if}
		<div class="group-options">
			{#each Object.entries($shortUserGroups) as [groupId, groupName]}
				<label class="group-option">
					<input
						type="checkbox"
						bind:checked={selectedUserGroups[groupId]} />
					{groupName}
				</label>
			{/each}
		</div>

		{#if $user.admin}
			{#if !showCustomTemplateField}
				<button
					class="linkbutton"
					type="button"
					on:click={() => (showCustomTemplateField = true)}>
					Use custom template id (advanced)
				</button>
			{:else}
				<br />
				<label>
					Custom template id:
					<input type="text" bind:value={customTemplateId} />
				</label>
				<button
					class="linkbutton"
					type="button"
					on:click={() => {
						showCustomTemplateField = false;
						customTemplateId = '';
					}}>
					Don't use custom template id
				</button>
			{/if}
		{/if}
	</Dialog>
</form>

<style>
	.group-option {
		display: block;
		margin-bottom: 5px;
	}

	.no-margin-bot {
		margin-bottom: 5px;
	}
	.tooltip {
		margin: -15px 0 10px 0;
		color: gray;
		font-size: 12px;
	}

	.linkbutton {
		border: none;
		background: transparent;
		color: #0000ff;
	}
	.linkbutton:hover {
		cursor: pointer;
		color: #000096;
	}
	.group-options {
		max-height: 100px;
		overflow-y: auto;
	}
</style>
