<script>
	import Page from '../components/Page.svelte';
	import Button from '../components/Button.svelte';
	import StoreTable from '../components/StoreTable.svelte';
	import DatasetDialog from '../components/dialogs/DatasetDialog.svelte';

	import {datasets} from '../stores/datasets';

	let openDatasetDialog;
	let clickedDataset;
	let onUpdate;

	const onAdd = () => {
		clickedDataset = null;
		openDatasetDialog();
	};

	const onEdit = (dataset) => {
		clickedDataset = dataset;
		openDatasetDialog();
	};

	const onDelete = async (dataset) => {
		if (
			!confirm(`Are you sure you want to delete dataset ${dataset.id}?`)
		) {
			return;
		}

		await datasets.delete(dataset.id);
		onUpdate();
	};

	const columns = [
		{
			title: 'Name',
			value: (v) => v.name,
			href: (v) => `/machine-learning/datasets/${v.id}`,
			orderKey: 'name',
		},
		{
			title: 'Tags',
			value: (v) => v.tagCount,
			orderKey: 'tagCount',
		},
		{
			title: 'Annotations',
			value: (v) => v.annotationCount,
			orderKey: 'annotationCount',
		},
		{
			title: 'By user',
			value: (v) => v.user.name || 'No user',
			orderKey: 'users.name',
		},
		{
			title: 'Created',
			value: (v) => v.createdAt,
			orderKey: 'createdAt',
			type: 'time',
		},
	];

	const actions = [
		{
			title: 'Edit',
			icon: 'edit',
			action: onEdit,
		},
		{
			title: 'Delete',
			icon: 'delete',
			action: onDelete,
		},
	];
</script>

<Page title="Datasets">
	<svelte:fragment slot="toolbar">
		<Button on:click={onAdd}>New dataset</Button>
	</svelte:fragment>
	<StoreTable
		store={datasets}
		columns={columns}
		actions={actions}
		orderBy="id"
		orderByDirection="desc"
		bind:onUpdate />
	<DatasetDialog
		bind:open={openDatasetDialog}
		dataset={clickedDataset}
		on:submit={onUpdate} />
</Page>
