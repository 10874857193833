import {mediaPost, LocationApi, BaseApi} from './api';

export const deleteDocument = async (documentId) => {
	return LocationApi.delete(`/document/${documentId}`);
};

export const updateDocument = async (document) => {
	return LocationApi.update('/document/' + document.id, document);
};

export const postDocument = async (document, onUploadProgress) => {
	if (!document.files || !document.files.length) {
		return Promise.reject('Missing file');
	}

	const data = {};
	data.name = document.name;
	if (document.moduleId) {
		data.moduleId = document.moduleId;
	}
	data.mimeType = document.files[0].type;

	const config = {
		onUploadProgress,
	};
	return mediaPost('/document', data, document.files[0], config);
};

export const getPublicForMyLocations = async () => {
	return LocationApi.get(`/documents/fromlocations`);
};

export const addFromLibrary = async (documentId) => {
	return LocationApi.post(`document/relate`, {documentId});
};

export const getDocuments = async (offset, limit, orderBy) => {
	return LocationApi.get(
		`/documents?offset=${offset}&limit=${limit}&orderBy=${orderBy}`
	);
};

export const getDocumentsFromLocation = async ({id, offset, limit, sort}) => {
	return BaseApi.get(
		`/location/${id}/documents?offset=${offset}&limit=${limit}&orderBy=${sort}`
	);
};
