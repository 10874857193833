<script>
	import Dialog from '../Dialog.svelte';
	import Button from '../Button.svelte';
	import DocumentDialog from '../dialogs/DocumentDialog.svelte';
	import DocumentLibraryDialog from '../dialogs/DocumentLibraryDialog.svelte';

	let close;
	export let open;

	export let moduleId;
	export let error = '';
	export let documentsInModule = [];

	let openDocumentDialog;
	let openLibraryDialog;
	export let onUpdate = () => {};

	export let onUpload = () => {
		openDocumentDialog();
		close();
	};
	export let onBrowseLibrary = () => {
		openLibraryDialog();
		close();
	};
</script>

<Dialog title="Add document" bind:open bind:close bind:error noCancel noSubmit>
	<svelte:fragment slot="button-bar">
		<Button on:click={onUpload}>Upload new</Button>
		<Button on:click={onBrowseLibrary}>Browse library</Button>
	</svelte:fragment>
	<p>Do you want to upload a new document or select one from the library?</p>
</Dialog>
<DocumentDialog
	bind:open={openDocumentDialog}
	moduleId={moduleId}
	bind:onUpdate />
<DocumentLibraryDialog
	bind:open={openLibraryDialog}
	moduleId={moduleId}
	bind:onUpdate
	bind:documentsInModule />

<style>
	:global(.option-button) {
		padding: 10px;
		margin: 0 10px;
	}
</style>
