export const getMimeColor = (mime) => {
	switch (mime) {
		case 'application/pdf':
			return '#d93025';
		case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
		case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
			return '#4285f4';
		case 'text/csv':
			return '#34a853';
		default:
			return 'gray';
	}
};
