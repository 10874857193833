<script>
	import Dialog from '../Dialog.svelte';
	import Button from '../Button.svelte';
	import VideoDialog from '../dialogs/VideoDialog.svelte';
	import VideoLibraryDialog from '../dialogs/VideoLibraryDialog.svelte';

	export let moduleId;
	export let open;
	let close;
	export let error = '';

	export let videosInModule = [];

	let openVideoDialog;
	let openLibraryDialog;

	export let onUpdate = () => {};

	export let onUpload = () => {
		openVideoDialog();
		close();
	};
	export let onBrowseLibrary = () => {
		openLibraryDialog();
		close();
	};
</script>

<Dialog title="Add video" bind:open bind:close bind:error noCancel noSubmit>
	<svelte:fragment slot="button-bar">
		<Button on:click={onUpload}>Upload new</Button>
		<Button on:click={onBrowseLibrary}>Browse library</Button>
	</svelte:fragment>
	<p>Do you want to upload a new video or select one from the library?</p>
</Dialog>
<VideoDialog bind:open={openVideoDialog} moduleId={moduleId} bind:onUpdate />
<VideoLibraryDialog
	bind:open={openLibraryDialog}
	bind:videosInModule
	moduleId={moduleId}
	bind:onUpdate />

<style>
	:global(.option-button) {
		padding: 10px;
		margin: 0 10px;
	}
</style>
