<script>
	import {createEventDispatcher} from 'svelte';
	import Dialog from '../Dialog.svelte';

	import {modules} from '../../stores/modules';

	const dispatch = createEventDispatcher();

	export let module;
	export let open;
	let close;
	let error = '';
	export let hideHideFromHome = false;

	const onSubmit = async () => {
		try {
			if (module.id) {
				await modules.change(module);
			} else {
				await modules.add(module);
			}

			close();
			module = null;
			error = '';

			dispatch('submit');
		} catch (e) {
			error = e.error;
		}
	};

	$: module = module || {
		id: undefined,
		name: '',
		thumbnailUrl: null,
		tags: [],
		access: 'private',
		files: null,
		hideFromHome: 0,
	};
</script>

<form on:submit|preventDefault={onSubmit}>
	<Dialog
		title={`${module.id ? 'Edit' : 'Add'} module`}
		canScroll
		bind:open
		bind:close
		bind:error>
		<label>
			{'Name'}
			<input type="text" bind:value={module.name} required />
		</label>

		<label>
			{'Description'}
			<input type="text" bind:value={module.description} />
		</label>
		{#if !hideHideFromHome}
			<label>
				{'Hide from home'}
				<select type="text" bind:value={module.hideFromHome}>
					<option value={1}>True</option>
					<option value={0}>False</option>
				</select>
			</label>
		{/if}
		{#if module.thumbnailUrl}
			<img class="thumb" src={module.thumbnailUrl} alt="thumbnail" />
		{/if}
		<label>
			Thumbnail
			<input
				accept="image/*"
				type="file"
				class={!module.imageUrl && 'fileupload'}
				bind:files={module.files} />
		</label>
	</Dialog>
</form>

<style>
</style>
