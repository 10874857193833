import {BaseApi, LocationApi, mediaPost} from './api';

export const getPictures = async (offset, limit, sort) => {
	return LocationApi.get(
		`/pictures?offset=${offset}&limit=${limit}&orderBy=${sort}`
	);
};

export const getPicturesFromLocation = async ({id, offset, limit, sort}) => {
	return BaseApi.get(
		`/location/${id}/pictures?offset=${offset}&limit=${limit}&orderBy=${sort}`
	);
};

export const postPicture = async (picture, onUploadProgress) => {
	const data = new FormData();
	if (picture.moduleId) {
		data.append('moduleId', picture.moduleId);
	}
	if (picture.locationId) {
		data.append('locationId', picture.locationId);
	}

	const config = {
		onUploadProgress,
	};
	return mediaPost('/picture', data, picture.files[0], config);
};
