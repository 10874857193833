<script>
	import Page from '../components/Page.svelte';
</script>

<Page title="Welcome to Unifractal">
	<p>
		We recommend that you get started by downloading the app and add your
		content there. <br />You can also
		<a href="/location/create"> create a location</a> and set it up from here
		in the dashboard if you want
	</p>
	<p>
		If you want to change your settings, go to <a href="/profile">
			profile
		</a>
	</p>
	<h3>Download the app</h3>
	<p>
		From the Unifractal app, you can manage your location, upload content,
		and manage your location's users. Make Unifractal your own and create
		your own location or join someone else's from the app
	</p>
	<a
		href="https://play.google.com/store/apps/details?id=com.unifractal.android">
		<img src="/google-play-badge.png" alt="Google play store" />
	</a>
	<a href="https://apps.apple.com/app/unifractal/id1528498002">
		<img src="/app-store-badge.png" alt="Apple App store" />
	</a>
</Page>

<style>
	a {
		color: blue;
		text-decoration: none;
	}
	img {
		height: 60px;
	}
</style>
