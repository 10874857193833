<script>
	import {resumeRun, stopRun} from '../api/runs';
	import {runs} from '../stores/runs';

	import Page from '../components/Page.svelte';
	import StoreTable from '../components/StoreTable.svelte';

	let onUpdate;

	const onDelete = (run) => {
		if (confirm(`Are you sure you want to delete run ${run.id}?`)) {
			runs.delete(run.id);
		}
	};

	const onPlay = async (run) => {
		await resumeRun(run);
		await onUpdate();
	};

	const onPause = async (run) => {
		await stopRun(run);
		await onUpdate();
	};

	const columns = [
		{
			title: 'ID',
			value: (v) => v.id,
			orderKey: 'id',
		},
		{
			title: 'Dataset',
			value: (v) => v.dataset.name,
			href: (v) => `/machine-learning/datasets/${v.dataset.id}`,
			orderKey: 'datasetId',
		},
		{
			title: 'Steps',
			value: (v) => v.steps,
			orderKey: 'steps',
		},
		{
			title: 'Loss',
			value: (v) => v.loss,
			orderKey: 'loss',
		},
		{
			title: 'Status',
			value: (v) => v.status,
			orderKey: 'status',
		},
		{
			title: 'Start / Stop',
			type: 'button',
			value: (v) => v.status === 'starting' || v.status === 'training',
		},
	];

	const actions = [
		{
			title: 'Delete',
			icon: 'delete',
			action: onDelete,
		},
	];
</script>

<Page title="Runs">
	<StoreTable
		store={runs}
		columns={columns}
		actions={actions}
		orderBy="id"
		orderByDirection="desc"
		onPause={onPause}
		onPlay={onPlay}
		bind:onUpdate />
</Page>
