<script>
	import BaseWidget from './BaseWidget.svelte';
	import AddLinkWidget from '../widgetdialogs/AddLinkWidget.svelte';
	import {deleteLink} from '../../api/links';
	import {unpinContent, pinContent} from '../../api/modules';

	export let content;
	export let afterThis;
	export let onUpdate;
	export let onMouseDown;

	let openAddLinkWidget;
	let clickedLink;

	//const strippedUrl = content.link.url
	//	.replace('http://', '')
	//	.replace('https://', '')
	//	.split(/[/?#]/)[0];

	const onDelete = async () => {
		if (confirm('Are you sure you want to delete this link?')) {
			await deleteLink(content.link.id);
			if (onUpdate) {
				onUpdate();
			}
		}
	};

	const onEdit = async () => {
		clickedLink = content.link;
		openAddLinkWidget();
	};

	const onPin = async () => {
		pinContent(content.id);
		if (onUpdate) {
			onUpdate();
		}
	};

	const onUnpin = async () => {
		unpinContent(content.id);
		if (onUpdate) {
			onUpdate();
		}
	};
</script>

<BaseWidget
	title={content.link.name}
	icon="link"
	content={content}
	href={content.link.url}
	afterThis={afterThis}
	onMouseDown={onMouseDown}
	onEdit={onEdit}
	onUnlink={onDelete}
	pinned={content.isPinned}
	onPin={onPin}
	onUnpin={onUnpin} />

<AddLinkWidget
	bind:open={openAddLinkWidget}
	bind:link={clickedLink}
	bind:onUpdate />
