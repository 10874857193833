<script>
	import {createEventDispatcher} from 'svelte';
	const dispatch = createEventDispatcher();

	import Dialog from '../Dialog.svelte';

	import {postDataset, updateDataset} from '../../api/datasets';

	const onSubmit = async () => {
		try {
			if (dataset.id) {
				await updateDataset(dataset);
			} else {
				await postDataset(dataset);
			}

			dataset = null;
			dispatch('submit');
			close();
		} catch (e) {
			error = e.error ? e.error : e;
		}
	};

	$: dataset = dataset || {
		id: undefined,
		name: '',
	};

	let error = '';
	let close;
	export let open;
	export let dataset;
</script>

<form on:submit|preventDefault={onSubmit}>
	<Dialog
		title="{dataset.id ? 'Edit' : 'Add'} dataset"
		bind:open
		bind:close
		bind:error>
		<label>
			Name
			<input type="text" bind:value={dataset.name} required />
		</label>
	</Dialog>
</form>
