import {writable} from 'svelte/store';

import {getTag /*, updateTag, deleteTag*/} from '../api/tags';

function createTag() {
	const {subscribe, set} = writable();
	const fetchTag = async (id) => set(await getTag(id));

	return {
		subscribe,
		fetch: (id) => fetchTag(id),
		/*change: async (tag) => {
			await updateTag(tag);
			fetchTag(tag.id);
		},
		delete: async (id) => {
			await deleteTag(id);
			set(null);
		},*/
	};
}

export const tag = createTag();
