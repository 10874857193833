import {writable} from 'svelte/store';

import {
	deleteLocationcode,
	getLocationcode,
	postLocationcode,
} from '../api/locationcodes';

function createLocationcodes() {
	const {subscribe, set, update} = writable({count: 0, records: []});
	const fetchLocationcodes = async (offset, limit) => {
		return set(await getLocationcode(offset, limit));
	};
	return {
		subscribe,
		fetch: (offset, limit) => fetchLocationcodes(offset, limit),
		add: async () => {
			await postLocationcode();
			fetchLocationcodes();
		},
		delete: async (id) => {
			await deleteLocationcode(id);
			update((prev) => ({
				count: prev.count,
				records: prev.records.filter(
					(locationcode) => locationcode.id !== id
				),
			}));
		},
	};
}

export const locationcodes = createLocationcodes();
