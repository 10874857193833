<script>
	import {onMount} from 'svelte';
	import Select from 'svelte-select';

	import {createEventDispatcher} from 'svelte';
	const dispatch = createEventDispatcher();

	import Dialog from '../Dialog.svelte';

	import {
		getPublicModulesForMyLocations,
		copyModule,
	} from '../../api/modules';

	let close;
	export let open;
	export let items = [];

	let error = '';
	$: selected = null;

	onMount(async () => {
		items = await getPublicModulesForMyLocations('item');
		if (!items) {
			items = [];
		}
		items = items.map((item) => {
			item.label = item.name;
			item.value = item.id;
			return item;
		});
	});

	const onSubmit = async () => {
		try {
			if (selected === null) {
				return;
			}
			await copyModule(selected.id);
			dispatch('submit');
			close();
		} catch (e) {
			error = e.error;
		}
	};
</script>

<form on:submit|preventDefault={onSubmit}>
	<Dialog title="Add item from library" bind:open bind:close bind:error>
		<Select items={items} bind:value={selected} />
	</Dialog>
</form>

<style>
	form {
		max-width: 100%;
	}
</style>
