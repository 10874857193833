import {PERMISSIONS} from '../permissions';

const getSafeUrl = (locationId, permissions, isAdmin = false) => {
	return `/location/${locationId}`;
	if (isAdmin || permissions?.includes(PERMISSIONS.READ_OVERVIEW)) {
		return `/location/${locationId}`;
	} else if (
		permissions?.includes(PERMISSIONS.MANAGE_ITEMS) ||
		permissions?.includes(PERMISSIONS.MANAGE_CONTENT)
	) {
		return `/location/${locationId}/items`;
	} else if (permissions?.includes(PERMISSIONS.MANAGE_IMAGES)) {
		return `/location/${locationId}/images`;
	} else if (permissions?.includes(PERMISSIONS.ANNOTATE_IMAGES)) {
		return `/location/${locationId}/annotator`;
	} else if (
		permissions?.includes(PERMISSIONS.MANAGE_USERS) ||
		permissions?.includes(PERMISSIONS.INVITE_USERS)
	) {
		return `/location/${locationId}/users`;
	}
	return `/404`;
};

export default getSafeUrl;
