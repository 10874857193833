<script>
	import {moveContent} from '../../api/modules';
	import AddWidget from './AddWidget.svelte';
	import TextWidget from './TextWidget.svelte';
	import LinkWidget from './LinkWidget.svelte';
	import VideoWidget from './VideoWidget.svelte';
	import DocumentWidget from './DocumentWidget.svelte';
	import ModuleWidget from './ModuleWidget.svelte';

	export let onUpdate = () => {};
	export let addNew = false;
	export let module;

	let top = 0;
	let mouseDown = null;
	let listParent;
	let after = null;

	const onMouseDown = (e, content) => {
		if (e.button !== 0) {
			return;
		}
		top = e.clientY - 20;
		mouseDown = content;
	};

	const onMouseMove = (e) => {
		if (mouseDown === null) {
			return;
		}

		top = e.clientY - 20;

		listParent.childNodes.forEach((item) => {
			if (typeof item.getBoundingClientRect !== 'function') {
				return;
			}

			const box = item.getBoundingClientRect();
			const y = box.y + box.height / 2;

			if (e.clientY > y && e.clientY < y + box.height) {
				after = module.content.find((c) => c.id === Number(item.id));
			}
		});
	};

	const onMouseUp = async () => {
		if (after && mouseDown) {
			await moveContent({
				parentId: module.id,
				id: mouseDown.id,
				afterId: after.id,
			});
			onUpdate();
		}
		mouseDown = null;
		after = null;
	};

	$: videos = module.content
		.filter((content) => content.video !== null)
		.map((content) => content.video);

	$: documents = module.content
		.filter((content) => content.document !== null)
		.map((content) => content.document);

	$: modules = module.content
		.filter((content) => content.module !== null)
		.map((content) => content.module);
</script>

<svelte:window on:mousemove={onMouseMove} on:mouseup={onMouseUp} />

<div class="ContentContainer" bind:this={listParent}>
	{#each module.content as content}
		<div
			id={content.id}
			class="selectable"
			style={mouseDown === content && `position: fixed; top: ${top}px;`}>
			{#if content.video}
				<VideoWidget
					bind:onUpdate
					onMouseDown={onMouseDown}
					content={content}
					afterThis={content === after} />
			{:else if content.textbox}
				<TextWidget
					bind:onUpdate
					onMouseDown={onMouseDown}
					content={content}
					afterThis={content === after} />
			{:else if content.document}
				<DocumentWidget
					bind:onUpdate
					onMouseDown={onMouseDown}
					content={content}
					afterThis={content === after} />
			{:else if content.link}
				<LinkWidget
					bind:onUpdate
					onMouseDown={onMouseDown}
					content={content}
					afterThis={content === after} />
			{:else if content.module}
				<ModuleWidget
					bind:onUpdate
					onMouseDown={onMouseDown}
					content={content}
					afterThis={content === after} />
			{:else}
				<p>Unknown content type</p>
			{/if}
		</div>
	{/each}
	{#if addNew}
		<AddWidget
			bind:videosInModule={videos}
			bind:documentsInModule={documents}
			bind:modulesInModule={modules}
			bind:onUpdate
			moduleId={module.id} />
	{/if}
</div>

<style>
	.ContentContainer {
		box-sizing: border-box;
		max-width: 500px;
		width: 100%;
		padding: 20px 0;
	}
	.selectable {
		user-select: none;
	}
</style>
