<script>
	import {onMount} from 'svelte';
	import {deleteAnnotation, moveAnnotation, tagDelete} from '../api/datasets';
	import {
		getTrainingAnnotations,
		getEvaluationAnnotations,
	} from '../api/datasets';
	import {postStartTraining} from '../api/mlModels';

	import {dataset} from '../stores/dataset';

	import Page from '../components/Page.svelte';
	import Table from '../components/Table.svelte';
	import Topbar from '../components/Topbar.svelte';
	import Button from '../components/Button.svelte';
	import Paginator from '../components/Paginator.svelte';
	import AnnotationGrid from '../components/AnnotationGrid.svelte';
	import ImportFromLocationDialog from '../components/dialogs/ImportFromLocationDialog.svelte';

	let activatedTraining = [];
	let activatedEvaluation = [];

	let offsetTraining = 0;
	let limitTraining = 30;

	let offsetEvaluation = 0;
	let limitEvaluation = 30;

	let startDisabled = false;
	let openImportFromLocationDialog;

	export let datasetId;

	$: editDataset = $dataset;

	onMount(async () => {
		await dataset.fetch(datasetId);
		editDataset = $dataset;
	});

	$: promiseTraining = getTrainingAnnotations(
		datasetId,
		offsetTraining,
		limitTraining
	);

	$: promiseEvaluation = getEvaluationAnnotations(
		datasetId,
		offsetEvaluation,
		limitEvaluation
	);

	const refetch = () => {
		promiseTraining = getTrainingAnnotations(
			datasetId,
			offsetTraining,
			limitTraining
		);
		promiseEvaluation = getEvaluationAnnotations(
			datasetId,
			offsetEvaluation,
			limitEvaluation
		);
		dataset.fetch(datasetId);
		activatedTraining = [];
		activatedEvaluation = [];
	};

	const onMoveToEvaluation = async () => {
		await Promise.all(
			activatedTraining.map((annotationId) =>
				moveAnnotation({
					id: annotationId,
					dataset: 'evaluation',
				})
			)
		);

		refetch();
	};

	const onMoveToTraining = async () => {
		await Promise.all(
			activatedEvaluation.map((annotationId) =>
				moveAnnotation({id: annotationId, dataset: 'training'})
			)
		);
		refetch();
	};

	const onDeleteTrainingAnnotations = async () => {
		await Promise.all(
			activatedTraining.map(async (annotationId) => {
				return deleteAnnotation(annotationId);
			})
		);
		refetch();
	};

	const onDeleteEvaluationAnnotations = async () => {
		await Promise.all(
			activatedEvaluation.map(async (annotationId) => {
				return deleteAnnotation(annotationId);
			})
		);
		refetch();
	};

	const onDeleteTag = async (tagName) => {
		await tagDelete(datasetId, tagName);
		refetch();
	};

	const onStartTraining = async () => {
		startDisabled = true;
		try {
			await postStartTraining(datasetId);
			startDisabled = false;
			window.location.href = `/machine-learning/runs`;
		} catch (e) {
			alert(e.error);
			startDisabled = false;
		}
	};

	const onImportFromLocation = () => {
		openImportFromLocationDialog();
	};

	const columns = [
		{
			title: 'Tag name',
			href: (tag) => `/machine-learning/tags/${tag.id}`,
			value: (tag) => tag.name,
			orderKey: 'name',
		},
		{
			title: 'Training',
			value: (tag) => tag.trainingCount,
		},
		{
			title: 'Evaluation',
			value: (tag) => tag.evaluationCount,
		},
		{
			title: 'Total annotations',
			value: (tag) => tag.annotationCount,
		},
	];

	const actions = [
		{
			title: 'Delete',
			icon: 'delete',
			action: (tag) => onDeleteTag(tag.id),
		},
	];
</script>

{#if editDataset}
	<Page title={$dataset.name} crumbs={['datasets', $dataset.name]}>
		<svelte:fragment slot="toolbar">
			<Button
				on:click={() => onStartTraining()}
				disabled={startDisabled || dataset.status === 'training'}>
				Start run
			</Button>
		</svelte:fragment>

		<Topbar small title="Included tags">
			<Button on:click={onImportFromLocation}>
				Import from location
			</Button>
		</Topbar>
		<Table
			columns={columns}
			actions={actions}
			rows={$dataset.tags}
			count={$dataset.tags.length} />

		<div class="data-container">
			<div class="data">
				<Topbar small title="Training data">
					<Button on:click={onMoveToEvaluation}>
						Move to evaulation set
					</Button>
					<Button on:click={onDeleteTrainingAnnotations}>
						Exclude from dataset
					</Button>
				</Topbar>
				{#await promiseTraining then annotations}
					{#if annotations.annotations.length === 0}
						<p>There are no training images in this dataset</p>
						<Paginator count={0} limit={0} />
					{:else}
						<AnnotationGrid
							annotations={annotations.annotations}
							bind:activated={activatedTraining} /><Paginator
							routing={false}
							count={annotations.count}
							bind:limit={limitTraining}
							bind:offset={offsetTraining} />
					{/if}
				{/await}
			</div>
			<div class="data">
				<Topbar small title="Evaluation data">
					<Button on:click={() => onMoveToTraining(dataset.id)}>
						Move to training set
					</Button>
					<Button
						on:click={() =>
							onDeleteEvaluationAnnotations(dataset.id)}>
						Exclude from dataset
					</Button>
				</Topbar>
				{#await promiseEvaluation then annotations}
					{#if annotations.annotations.length === 0}
						<p>There are no evaluation images in this dataset</p>
						<Paginator count={0} limit={0} />
					{:else}
						<AnnotationGrid
							annotations={annotations.annotations}
							bind:activated={activatedEvaluation} /><Paginator
							routing={false}
							count={annotations.count}
							bind:limit={limitEvaluation}
							bind:offset={offsetEvaluation} />
					{/if}
				{/await}
			</div>
		</div>
	</Page>
	<ImportFromLocationDialog
		bind:open={openImportFromLocationDialog}
		datasetId={$dataset.id}
		on:submit={refetch} />
{/if}

<style>
	.data-container {
		display: flex;
		justify-content: space-between;
		gap: 20px;
	}
	.data {
		justify-content: space-between;
		width: 100%;
	}
	@media screen and (max-width: 700px) {
		.data-container {
			flex-direction: column;
		}
		.data {
			max-width: 100%;
		}
	}
</style>
