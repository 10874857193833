<script>
	import {fly} from 'svelte/transition';
	import Button from '../components/Button.svelte';

	import Page from '../components/Page.svelte';
	import StoreTable from '../components/StoreTable.svelte';
	import ItemModuleDialog from '../components/dialogs/ItemModuleDialog.svelte';
	import ItemLibraryDialog from '../components/dialogs/ItemLibraryDialog.svelte';
	import LinkContentDialog from '../components/dialogs/LinkContentDialog.svelte';

	import {user} from '../stores/user';
	import {items} from '../stores/items';
	import {moveModule, moveModuleDown, moveModuleUp} from '../api/modules';

	let selected = [];
	let clickedItem;

	let openItemDialog;
	let openLibraryDialog;
	let openLinkContentDialog;
	let onUpdate;

	const onAdd = () => {
		clickedItem = null;
		openItemDialog();
	};

	const onAddFromLibrary = () => {
		openLibraryDialog();
	};

	const onDelete = async (item) => {
		if (
			!confirm(`Are you sure you want to delete ${item.name || item.id}?`)
		) {
			return;
		}
		const response = await items.delete(item.id);
		if (response.error) {
			alert(response.error);
		}

		onUpdate();
	};

	const onDeleteMany = async () => {
		if (
			!confirm(
				`Are you sure you want to delete ${selected.length} items ?`
			)
		) {
			return;
		}
		await Promise.all(
			selected.map(async (item) => {
				return items.delete(item.id);
			})
		);
		selected = [];

		onUpdate();
	};

	const onLink = async (item) => {
		clickedItem = item;
		openLinkContentDialog();
	};

	const onMoveUp = async (module) => {
		await moveModuleUp(module.id, 'item');
		onUpdate();
	};

	const onMoveDown = async (module) => {
		await moveModuleDown(module.id, 'item');
		onUpdate();
	};

	const columns = [
		{
			title: 'Name',
			value: (item) => item.name,
			href: (item) => `/location/${$user?.locationId}/items/${item.id}`,
			orderKey: 'name',
			truncate: true,
		},
		{
			title: 'Description',
			value: (item) => item.description,
			hide: 'mobile',
			orderKey: 'description',
			truncate: true,
		},
		/*{
			title: 'Category',
			value: (item) => item.categoryId,
			type: 'category',
			hide: 'small',
			orderKey: 'categoryId',
			truncate: true,
		},*/
		{
			title: 'Videos',
			value: (row) => row.videoCount,
			hide: 'medium',
			orderKey: 'videoCount',
		},
		{
			title: 'Documents',
			value: (row) => row.documentCount,
			hide: 'medium',
			orderKey: 'documentCount',
		},
		{
			title: 'Textboxes',
			value: (row) => row.textboxCount,
			hide: 'medium',
			orderKey: 'textboxCount',
		},
		{
			title: 'Links',
			value: (row) => row.linkCount,
			hide: 'medium',
			orderKey: 'linkCount',
		},
	];

	const actions = [
		{
			title: 'Move up',
			icon: 'arrow_upward',
			action: onMoveUp,
		},
		{
			title: 'Move down',
			icon: 'arrow_downward',
			action: onMoveDown,
		},
		{
			title: 'Delete',
			icon: 'delete',
			action: onDelete,
		},
		{
			title: 'Link to module',
			icon: 'add_link',
			action: onLink,
		},
	];
</script>

<Page
	title="Items"
	description="Manage your existing items or add new ones. You may also browse the library to check if any items you want is already in the system"
	crumbs={['items']}>
	<svelte:fragment slot="toolbar">
		<Button on:click={onAdd}>Add item</Button>
		<Button on:click={onAddFromLibrary}>Browse library</Button>
		{#if selected.length > 0}
			<span in:fly>
				<Button on:click={onDeleteMany}>delete</Button>
			</span>
		{/if}
	</svelte:fragment>

	<StoreTable
		store={items}
		columns={columns}
		actions={actions}
		bind:onUpdate />

	<ItemModuleDialog
		bind:open={openItemDialog}
		on:submit={onUpdate}
		item={clickedItem} />
	<ItemLibraryDialog
		bind:open={openLibraryDialog}
		modulesInLocation={$items}
		on:submit={onUpdate} />
	<LinkContentDialog
		bind:open={openLinkContentDialog}
		moduleId={clickedItem?.id} />
</Page>
