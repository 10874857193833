import {writable} from 'svelte/store';

import {getModule, updateModule, deleteModule} from '../api/modules';

function createModule() {
	const {subscribe, set} = writable();
	const fetchModule = async (id) => set(await getModule(id));

	return {
		subscribe,
		fetch: (id) => fetchModule(id),
		change: async (module) => {
			await updateModule(module);
			fetchModule(module.id);
		},
		delete: async (id) => {
			await deleteModule(id);
			set(null);
		},
	};
}

export const module = createModule();
