<script>
	import {onMount} from 'svelte';

	import Select from 'svelte-select';
	import Dialog from '../Dialog.svelte';

	import {getPublicForMyLocations} from '../../api/videos';
	import {linkContent} from '../../api/modules';

	import {filterExisting} from '../../utils/filterExisting';

	let selected;
	let error = '';
	let close;
	export let open;
	export let moduleId;
	export let videos = [];
	export let videosInModule = [];
	export let onUpdate = () => {};
	export let excludedIds = [];

	onMount(async () => {
		videos = await getPublicForMyLocations();
		videos = videos.map((video) => {
			video.label = video.title;
			video.value = video.id;
			return video;
		});
		videos = videos.filter((video) => !excludedIds.includes(video.id));
	});

	const onSubmit = async () => {
		error = '';
		try {
			await linkContent({
				videoId: selected.id,
				parentId: moduleId,
			});
			selected = null;
			onUpdate();
			close();
		} catch (e) {
			error = e.error ? e.error : e;
		}
	};
</script>

<form on:submit|preventDefault={onSubmit}>
	<Dialog title="Add video from library" bind:open bind:close bind:error>
		<Select
			bind:value={selected}
			items={filterExisting(videos, videosInModule)} />
	</Dialog>
</form>

<style>
	:global(.videoLibraryDialog .selectContainer) {
		box-sizing: border-box;
	}
	:global(.videoLibraryDialog .selectContainer input) {
		padding-right: 0 !important;
	}
	:global(.videoLibraryDialog .listContainer) {
		box-sizing: border-box;
	}

	:global(.videoLibraryDialog) {
		overflow: visible !important;
	}
	form {
		max-width: 100%;
	}
</style>
