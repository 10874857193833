<script>
	import BaseWidget from './BaseWidget.svelte';
	import {deleteTextbox} from '../../api/textboxes';
	import {unpinContent, pinContent} from '../../api/modules';

	export let content;
	export let afterThis;
	export let onUpdate;
	export let onMouseDown;

	const onDelete = async () => {
		if (confirm('Are you sure you want to delete this text content?')) {
			await deleteTextbox(content.textbox.id);
			if (onUpdate) {
				onUpdate();
			}
		}
	};

	const onPin = async () => {
		pinContent(content.id);
		if (onUpdate) {
			onUpdate();
		}
	};

	const onUnpin = async () => {
		unpinContent(content.id);
		if (onUpdate) {
			onUpdate();
		}
	};
</script>

<BaseWidget
	title={content.textbox.title}
	icon="subject"
	content={content}
	afterThis={afterThis}
	onMouseDown={onMouseDown}
	onUnlink={onDelete}
	pinned={content.isPinned}
	onPin={onPin}
	onUnpin={onUnpin}>
	<div slot="expanded">
		<p>{content.textbox.text}</p>
	</div>
</BaseWidget>
