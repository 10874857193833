import {writable} from 'svelte/store';

import {
	deleteDocument,
	getDocuments,
	postDocument,
	updateDocument,
} from '../api/documents';

function createDocuments() {
	const {subscribe, set, update} = writable();
	return {
		subscribe,
		fetch: async (offset, limit, orderBy) => {
			return set(await getDocuments(offset, limit, orderBy));
		},
		add: async (document) => {
			await postDocument(document);
		},
		change: async (document) => {
			await updateDocument(document);
		},
		delete: async (id) => {
			await deleteDocument(id);
			update((arr) => ({
				count: arr.count,
				records: arr.records.filter((document) => document.id !== id),
			}));
		},
	};
}

export const documents = createDocuments();
