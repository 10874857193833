<script>
	import Button from '../components/Button.svelte';
	import {
		getTrainingAnnotations,
		getEvaluationAnnotations,
		getTagImages,
		moveAnnotation,
		deleteAnnotation,
	} from '../api/tags';
	import AnnotationGrid from '../components/AnnotationGrid.svelte';

	import Page from '../components/Page.svelte';
	import Paginator from '../components/Paginator.svelte';
	import Toolbar from '../components/Toolbar.svelte';

	import {tag} from '../stores/tag';

	export let tagId;

	tag.fetch(tagId);

	let activatedTraining = [];
	let activatedEvaluation = [];

	let offsetTraining = 0;
	let limitTraining = 30;

	let offsetEvaluation = 0;
	let limitEvaluation = 30;

	let offsetImages = 0;
	let limitImages = 30;

	$: promiseTraining = getTrainingAnnotations(
		tagId,
		offsetTraining,
		limitTraining
	);
	$: promiseEvaluation = getEvaluationAnnotations(
		tagId,
		offsetEvaluation,
		limitEvaluation
	);
	$: promiseImages = getTagImages(tagId, offsetImages, limitImages);

	const refetch = () => {
		promiseTraining = getTrainingAnnotations(
			tagId,
			offsetTraining,
			limitTraining
		);
		promiseEvaluation = getEvaluationAnnotations(
			tagId,
			offsetEvaluation,
			limitEvaluation
		);
		promiseImages = getTagImages(tagId, offsetImages, limitImages);
		activatedTraining = [];
		activatedEvaluation = [];
	};

	const onMoveToEvaluation = async () => {
		await Promise.all(
			activatedTraining.map((annotationId) =>
				moveAnnotation({id: annotationId, dataset: 'evaluation'})
			)
		);

		refetch();
	};

	const onMoveToTraining = async () => {
		await Promise.all(
			activatedEvaluation.map((annotationId) =>
				moveAnnotation({id: annotationId, dataset: 'training'})
			)
		);

		refetch();
	};

	const onDeleteTrainingAnnotations = async () => {
		await Promise.all(
			activatedTraining.map(async (annotationId) => {
				return deleteAnnotation(annotationId);
			})
		);

		refetch();
	};

	const onDeleteEvaluationAnnotations = async () => {
		await Promise.all(
			activatedEvaluation.map(async (annotationId) => {
				return deleteAnnotation(annotationId);
			})
		);

		refetch();
	};
</script>

{#if $tag}
	<Page title={$tag.name}>
		<div style="width:49%;display:inline-block">
			<div class="mdc-typography--headline4 padder">Training images</div>
			<Toolbar>
				<Button on:click={onMoveToEvaluation}>
					Move to evaulation set
				</Button>
				<Button on:click={onDeleteTrainingAnnotations}>
					Remove annotations
				</Button>
			</Toolbar>
			{#await promiseTraining then annotations}
				<AnnotationGrid
					annotations={annotations.annotations}
					bind:activated={activatedTraining} />
				<Paginator
					routing={false}
					count={annotations.count}
					bind:limit={limitTraining}
					bind:offset={offsetTraining} />
			{/await}
		</div>
		<div style="width:49%;display:inline-block">
			<div class="mdc-typography--headline4 padder">
				Evaluation images
			</div>
			<Toolbar>
				<Button on:click={onMoveToTraining}>
					Move to training set
				</Button>
				<Button on:click={onDeleteEvaluationAnnotations}>
					Remove annotations
				</Button>
			</Toolbar>
			{#await promiseEvaluation then annotations}
				<AnnotationGrid
					annotations={annotations.annotations}
					bind:activated={activatedEvaluation} />
				<Paginator
					routing={false}
					count={annotations.count}
					bind:limit={limitEvaluation}
					bind:offset={offsetEvaluation} />
			{/await}
		</div>
		<div style="width:49%;display:inline-block">
			<div class="mdc-typography--headline4 padder">
				Classification Images
			</div>
			{#await promiseImages then images}
				{#each images.records as image}
					<div class="grid" id={image.id}>
						<div class="img-overlay-wrap">
							<img alt="ML" class="item" src={image.imageUrl} />
						</div>
					</div>
				{/each}
				<Paginator
					routing={false}
					count={images.count}
					bind:limit={limitImages}
					bind:offset={offsetImages} />
			{/await}
		</div>
	</Page>
{/if}

<style>
	.padder {
		padding: 20px 0;
		color: #30465e;
		font-weight: bold;
		text-transform: uppercase;
	}

	img {
		display: inline-block;
		width: 80px;
		height: 80px;
	}
	.img-overlay-wrap {
		position: relative;
		display: inline-block;
		transition: 0.2s;
		height: 80px;
	}
	.img-overlay-wrap svg {
		position: absolute;
		width: 100%;
		height: 100%;
	}
	.grid {
		display: inline-block;
		margin: 5px;
		user-select: none;
	}
	:global(.material-icons.nomargin) {
		margin: 0 !important;
		font-size: 14px;
	}
</style>
