<script>
	import {fly} from 'svelte/transition';

	import Page from '../components/Page.svelte';
	import Table from '../components/Table.svelte';
	import Topbar from '../components/Topbar.svelte';

	import {globalOverview} from '../stores/globalOverview';
	import {allLogs} from '../stores/logs';
	import {shortLocations} from '../stores/short/locations';

	export let limit1 = 10;
	export let offset1 = 0;
	export let limit2 = 10;
	export let offset2 = 0;

	$: {
		globalOverview.fetch();
		allLogs.fetch(offset1, limit1, offset2, limit2);
	}

	shortLocations.fetch();

	const columns = [
		{
			title: 'Action',
			value: (e) => `${e.name} ${e.text}`,
			truncate: true,
		},
		{
			title: 'Location',
			value: (entry) =>
				entry.locationId
					? `${$shortLocations[entry.locationId]}`
					: null,
		},
		{
			title: 'Time',
			value: (e) => e.createdAt,
			type: 'time',
		},
	];

	const getTotalUsers = () => {
		const {registeredUserCount, unregisteredUserCount, inviteCount} =
			$globalOverview;
		return registeredUserCount + unregisteredUserCount + inviteCount;
	};
</script>

<Page title="Global Overview">
	{#if $globalOverview && $allLogs}
		<div in:fly={{y: 10}}>
			<div class="cardcontainer">
				<div class="stats-section">
					<table class="stats-section__table">
						<tr class="large">
							<td><b>Total users</b></td>
							<td class="value"><b>{getTotalUsers()}</b></td>
						</tr>
						<tr class="large">
							<td>New users (7 days)</td>
							<td class="value"
								>{$globalOverview.newUserCount}</td>
						</tr>
						<tr>
							<br />
						</tr>
						<tr>
							<td>Registered</td>
							<td class="value"
								>{$globalOverview.registeredUserCount}</td>
						</tr>
						<tr>
							<td>Guests</td>
							<td class="value"
								>{$globalOverview.unregisteredUserCount}</td>
						</tr>
						<tr>
							<td>Invited</td>
							<td class="value">{$globalOverview.inviteCount}</td>
						</tr>
					</table>
				</div>

				<div class="stats-section">
					<table class="stats-section__table">
						<tr class="large">
							<td><b>Content</b></td>
						</tr>
						<tr>
							<td><b>Items</b></td>
							<td class="value"
								><b>{$globalOverview.itemCount}</b></td>
						</tr>
						<tr>
							<td>Modules</td>
							<td class="value">{$globalOverview.moduleCount}</td>
						</tr>
						<tr>
							<td>Item modules</td>
							<td class="value">
								{$globalOverview.itemModuleCount}
							</td>
						</tr>
						<tr>
							<td>Videos</td>
							<td class="value">
								{$globalOverview.videoCount}
							</td>
						</tr>
						<tr>
							<br />
						</tr>
					</table>
				</div>
				<div class="stats-section">
					<table class="stats-section__table">
						<tr class="large">
							<td><b>Engagement</b></td>
						</tr>
						<tr>
							<td>Video views</td>
							<td class="value"
								>{$globalOverview.videoViewsCount}</td>
						</tr>
					</table>
				</div>
				<div class="stats-section">
					<table class="stats-section__table">
						<tr class="large">
							<td><b>Images</b></td>
							<td class="value"
								><b>{$globalOverview.imageCount}</b></td>
						</tr>
						<tr>
							<td>Annotated images</td>
							<td class="value"
								>{$globalOverview.annotatedImages}</td>
						</tr>
						<tr>
							<td>Annotation rate</td>
							<td class="value">
								{Math.round(
									($globalOverview.annotatedImages /
										$globalOverview.imageCount) *
										100
								) || 0}%
							</td>
						</tr>
						<tr>
							<br />
						</tr>
					</table>
				</div>
				<div class="stats-section">
					<table class="stats-section__table">
						<tr class="large">
							<td><b>Service messages</b></td>
							<td class="value"
								><b
									>{$globalOverview.unresolvedServiceMessagesCount +
										$globalOverview.resolvedServiceMessagesCount}</b
								></td>
						</tr>
						<tr>
							<td>Resolved</td>
							<td class="value"
								>{$globalOverview.resolvedServiceMessagesCount}</td>
						</tr>
						<tr>
							<td>Pending resolution</td>
							<td class="value">
								{$globalOverview.unresolvedServiceMessagesCount}
							</td>
						</tr>
						<tr>
							<br />
						</tr>
					</table>
				</div>
			</div>
			<div class="tableWrapper">
				<div class="tables">
					<Topbar small title="Logins" />
					<Table
						rows={$allLogs.loginLogs.records}
						count={$allLogs.loginLogs.count}
						columns={columns}
						routing={false}
						bind:limit={limit1}
						bind:offset={offset1} />
				</div>
				<div class="tables">
					<Topbar small title="Content updates" />
					<Table
						rows={$allLogs.otherLogs.records}
						count={$allLogs.otherLogs.count}
						columns={columns}
						routing={false}
						bind:limit={limit2}
						bind:offset={offset2} />
				</div>
			</div>
		</div>
	{/if}
</Page>

<style>
	.stats-section {
		padding: 20px;
		border: solid 1px #000096;
		display: inline-block;
		border-radius: 5px;
		margin-bottom: 10px;
	}

	.stats-section__table {
		min-width: 230px;
		width: 100%;
	}
	.stats-section__table .large {
		font-size: 18px;
	}

	.stats-section__table td:first-child {
		width: 100%;
	}
	.stats-section__table td.value {
		white-space: nowrap;
		text-align: right;
	}

	.cardcontainer {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
		grid-gap: 1rem;
		margin-bottom: 1rem;
	}

	.tableWrapper {
		display: flex;
		flex-wrap: wrap;
	}
	.tables {
		flex: 1;
		padding: 10px;
	}
</style>
