<script>
	import {createEventDispatcher} from 'svelte';
	const dispatch = createEventDispatcher();

	import Dialog from '../Dialog.svelte';

	import {postService, updateService} from '../../api/services';

	const onSubmit = async () => {
		try {
			if (service.id) {
				await updateService(service);
			} else {
				await postService(service);
			}

			service = null;
			dispatch('submit');
			close();
		} catch (e) {
			error = e.error ? e.error : e;
		}
	};

	$: service = service || {
		id: undefined,
		name: '',
		ip: '0.0.0.0',
		port: '3000',
	};

	let error = '';
	let close;
	export let open;
	export let service;
</script>

<form on:submit|preventDefault={onSubmit}>
	<Dialog
		title="{service.id ? 'Edit' : 'Add'} service"
		bind:open
		bind:close
		bind:error>
		<label>
			Name
			<input type="text" bind:value={service.name} required />
		</label>
		<label>
			IP Address
			<input type="text" bind:value={service.ip} required />
		</label>
		<label>
			Port
			<input type="text" bind:value={service.port} required />
		</label>
	</Dialog>
</form>
