<script>
	import {shortLocations} from '../stores/short/locations';
	shortLocations.fetch();

	export let value;
</script>

<select bind:value>
	<option value={null}>No Location</option>
	{#each Object.entries($shortLocations) as [locationId, locationName]}
		<option value={Number(locationId)}>{locationName}</option>
	{/each}
</select>
