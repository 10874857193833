<script>
	import Page from '../components/Page.svelte';
	import StoreTable from '../components/StoreTable.svelte';
	import ItemDialog from '../components/dialogs/ItemDialog.svelte';

	import {globalItems} from '../stores/globalItems';
	import Button from '../components/Button.svelte';

	let onUpdate;
	let openItemDialog;
	let clickedItem;

	const onAdd = () => {
		openItemDialog();
	};

	const onDelete = async (item) => {
		if (
			!confirm(`Are you sure you want to delete ${item.name || item.id}?`)
		) {
			return;
		}
		const response = await globalItems.delete(item.id);
		if (response.error) {
			alert(response.error);
		}

		onUpdate();
	};

	const columns = [
		{
			title: 'Name',
			value: (item) => item.name,
			href: (item) => `/admin/global-items/${item.id}`,
			orderKey: 'name',
			truncate: true,
		},
		{
			title: 'Description',
			value: (item) => item.description,
			hide: 'mobile',
			orderKey: 'description',
			truncate: true,
		},
		{
			title: 'Modules',
			value: (item) => item.modules.length,
			hide: 'medium',
			truncate: true,
		},
	];

	const actions = [
		{
			title: 'Delete',
			icon: 'delete',
			action: onDelete,
		},
	];
</script>

<Page title="Global Items" description="Manage all items in the system">
	<svelte:fragment slot="toolbar">
		<Button on:click={onAdd}>Add item</Button>
	</svelte:fragment>

	<StoreTable
		store={globalItems}
		columns={columns}
		actions={actions}
		bind:onUpdate />
	<ItemDialog
		bind:open={openItemDialog}
		on:submit={onUpdate}
		item={clickedItem} />
</Page>
