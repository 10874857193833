<script>
	import Page from '../components/Page.svelte';
	import Button from '../components/Button.svelte';
	import StoreTable from '../components/StoreTable.svelte';
	import ServiceDialog from '../components/dialogs/ServiceDialog.svelte';

	import {services} from '../stores/services';

	const onAdd = () => {
		clickedService = null;
		openServiceDialog();
	};

	const onEdit = (service) => {
		clickedService = service;
		openServiceDialog();
	};

	const onDelete = async (service) => {
		if (
			!confirm(`Are you sure you want to delete service ${service.name}?`)
		) {
			return;
		}

		await services.delete(service.id);
		onUpdate();
	};

	const columns = [
		{
			title: 'Name',
			value: (service) => service.name,
		},
		{
			title: 'IP Address',
			value: (service) => service.ip,
		},
		{
			title: 'Port',
			value: (service) => service.port,
		},
		{
			title: 'Manager',
			value: (service) => service.user.name,
		},
		{
			title: 'Type',
			value: (service) => service.type,
		},
		{
			title: 'Status',
			value: (service) => service.status,
		},
	];

	const actions = [
		{
			title: 'Edit',
			icon: 'edit',
			action: onEdit,
		},
		{
			title: 'Delete',
			icon: 'delete',
			action: onDelete,
		},
	];

	let openServiceDialog;
	let clickedService;
	let onUpdate;
</script>

<Page title="Services">
	<svelte:fragment slot="toolbar">
		<Button on:click={onAdd}>New service</Button>
	</svelte:fragment>
	<StoreTable
		store={services}
		columns={columns}
		actions={actions}
		bind:onUpdate />
	<ServiceDialog
		bind:open={openServiceDialog}
		on:submit={onUpdate}
		service={clickedService} />
</Page>
