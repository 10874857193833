<script>
	import {onMount} from 'svelte';
	import {router} from 'tinro';

	import {getServiceMessages} from '../../api/servicemessages';
	import timeSince from '../../utils/timeSince';

	let serviceMessages = [];
	export let offset;
	export let limit;
	export let unresolvedOnly;

	const fetchServiceMessages = async () => {
		serviceMessages = (
			await getServiceMessages(offset, limit, 'createdAt', unresolvedOnly)
		).records;
		serviceMessages.reverse();
	};

	onMount(async () => {
		fetchServiceMessages();
	});

	$: if ($router.path) {
		fetchServiceMessages();
	}
</script>

<h2>Latest service messages</h2>
<div class="messageWrapper">
	{#each serviceMessages as serviceMessage}
		<div class="message">
			{serviceMessage.message}
		</div>
		<div class="messageInfo">
			Sent by <b>
				{serviceMessage.user?.name}
			</b>
			{timeSince(serviceMessage.createdAt)}
		</div>
	{/each}
</div>

<style>
	.messageWrapper {
		max-width: 500px;
		padding: 10px;
		border: 0.5px solid #ddd;
		border-radius: 3px;
	}
	.message {
		margin-bottom: 5px;
	}
	.messageInfo {
		font-size: 0.8rem;
		color: #666;
		border-bottom: 0.5px solid #ddd;
		margin-bottom: 6px;
	}
</style>
