import {BaseApi, LocationApi} from './api';

export const getLogs = async (offset, limit, type) => {
	return LocationApi.get(
		`/logs?offset=${offset}&limit=${limit}&type=${type}`
	);
};

export const getLoginCounts = async (offset, limit) => {
	return LocationApi.get(`/logs/logincounts?offset=${offset}&limit=${limit}`);
};

export const getVideoViews = async (offset, limit) => {
	return LocationApi.get(`/logs/videoviews?offset=${offset}&limit=${limit}`);
};

export const getAllLogs = async (offset, limit, type) => {
	return BaseApi.get(`/logs?offset=${offset}&limit=${limit}&type=${type}`);
};
