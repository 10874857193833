<p class="warn">
	<slot />
</p>

<style>
	.warn {
		margin-top: 0;
		font-size: 14px;
		color: #e28011;
	}
</style>
