import {writable} from 'svelte/store';

import {
	deleteModule,
	getModules,
	postModule,
	updateModule,
} from '../api/modules';

function createModules() {
	const {subscribe, set, update} = writable();
	const fetchModules = async (offset, limit, order) => {
		return set(await getModules(offset, limit, order));
	};

	return {
		subscribe,
		fetch: (offset, limit, order) => fetchModules(offset, limit, order),
		add: async (module) => {
			await postModule(module);
		},
		change: async (module) => {
			await updateModule(module);
		},
		delete: async (id, data) => {
			await deleteModule(id, data);
			update((arr) => ({
				count: arr.count,
				records: arr.records.filter((module) => module.id !== id),
			}));
		},
	};
}

export const modules = createModules();
