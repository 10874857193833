import {writable} from 'svelte/store';

import {deleteItem, getItems, postItemModule, updateItem} from '../api/items';

function createItems() {
	const {subscribe, set, update} = writable();
	const fetchItems = async (offset, limit, orderBy) => {
		return set(await getItems(offset, limit, orderBy));
	};

	return {
		subscribe,
		fetch: (offset, limit, orderBy) => fetchItems(offset, limit, orderBy),
		add: async (item) => {
			await postItemModule(item);
		},
		change: async (item) => {
			await updateItem(item);
		},
		delete: async (id) => {
			try {
				const response = await deleteItem(id);
				update((arr) => ({
					count: arr.count,
					records: arr.records.filter((item) => item.id !== id),
				}));
				return response;
			} catch (e) {
				return e;
			}
		},
	};
}

export const items = createItems();
