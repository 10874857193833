<script>
	import {onMount} from 'svelte';

	import Select from 'svelte-select';
	import Dialog from '../Dialog.svelte';

	import {getPublicForMyLocations} from '../../api/documents';
	import {linkContent} from '../../api/modules';

	import {filterExisting} from '../../utils/filterExisting';

	let selected;
	let error = '';
	let close;
	export let open;
	export let moduleId;
	export let documents = [];
	export let documentsInModule = [];
	export let onUpdate = () => {};

	onMount(async () => {
		documents = await getPublicForMyLocations();
		documents = documents.map((document) => {
			document.label = document.name;
			document.value = document.id;
			return document;
		});
	});

	const onSubmit = async () => {
		error = '';
		try {
			await linkContent({
				documentId: selected.id,
				parentId: moduleId,
			});

			selected = null;
			onUpdate();
			close();
		} catch (e) {
			error = e.error ? e.error : e;
		}
	};
</script>

<form on:submit|preventDefault={onSubmit}>
	<Dialog title="Add document from library" bind:open bind:close bind:error>
		<Select
			bind:value={selected}
			items={filterExisting(documents, documentsInModule)} />
	</Dialog>
</form>
