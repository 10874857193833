<script>
	import {onMount} from 'svelte';
	import Button from '../components/Button.svelte';
	import {user} from '../stores/user';
	import {getLocation} from '../api/locations';
	import {locations} from '../stores/locations';
	import {locationcodes} from '../stores/locationcodes';
	import {getDefaultLocationImages} from '../api/locationimages';
	import Page from '../components/Page.svelte';
	import Topbar from '../components/Topbar.svelte';
	import StoreTable from '../components/StoreTable.svelte';
	import {getModelsForLocation} from '../api/models';
	import QRDialog from '../components/dialogs/QRDialog.svelte';

	let locationimages;
	export let locationId = null;
	$: location = {};
	let onUpdate;
	let error;
	let previewedImage;
	let fileinput;
	let selectedImage;
	let openQRDialog;
	let selectedLocationCode;

	let models = [];

	onMount(async () => {
		const locId = locationId ? locationId : $user?.locationId;
		location = await getLocation(locId);
		location.modelId = location.model?.id || null;
		locationimages = await getDefaultLocationImages();
		locationimages = locationimages.records;
		if (location.image) {
			selectedImage = location.image.id;
		}
		models = await getModelsForLocation();
	});

	const onSubmit = async () => {
		try {
			await locations.change(location);
			location = await getLocation(location.id);
			location.modelId = location.model?.id || null;
		} catch (e) {
			console.error(e);
			error = e.error;
		}
	};
	const onFileSelected = (e) => {
		const image = e.target.files[0];
		const reader = new FileReader();
		reader.readAsDataURL(image);
		reader.onload = (e) => {
			previewedImage = e.target.result;
		};
		selectedImage = null;
		location.uploadImage = image;
	};
	const onAddLocationCode = async () => {
		await locationcodes.add();
		onUpdate();
	};
	const onLocationImageClick = (image) => {
		previewedImage = null;
		selectedImage = image.id;
		fileinput.value = null;
		previewedImage = image.url;
	};

	const onLocationImageSubmit = async () => {
		const data = {
			id: location.id,
		};
		if (selectedImage) {
			data.imageId = selectedImage;
		} else if (location.uploadImage) {
			data.uploadImage = location.uploadImage;
		} else {
			return;
		}
		await locations.change(data);

		previewedImage = null;
		location = await getLocation(location.id);
	};

	const onLocationCodeDelete = async (locationcode) => {
		if (
			!confirm(
				`Are you sure you want to delete code ${locationcode.key}?`
			)
		) {
			return;
		}

		await locationcodes.delete(locationcode.id);
		onUpdate();
	};

	const onPreview = (locationcode) => {
		selectedLocationCode = locationcode.key;
		openQRDialog();
	};

	const locationCodeColumns = [
		{
			title: 'Code',
			value: (v) => v.key,
		},
	];
	const locationCodeActions = [
		{
			title: 'View code',
			icon: 'visibility',
			action: onPreview,
		},
		{
			title: 'Delete code',
			icon: 'delete',
			action: onLocationCodeDelete,
		},
	];
</script>

<Page
	title="Location Settings"
	description="Manage the settings for the current location"
	crumbs={['Location Settings']}>
	<div class="container">
		<div class="left-side">
			<form on:submit|preventDefault={onSubmit}>
				<label>
					Name
					<input type="text" bind:value={location.name} required />
				</label>

				<label>
					Description
					<input type="text" bind:value={location.description} />
				</label>
				<label>
					Model
					<select bind:value={location.modelId} label="Model">
						<option value={null}>No model</option>
						{#each models as model}
							<option value={model.id}>{model.name}</option>
						{/each}
					</select>
				</label>
				<label>
					Display mode (App home screen)
					<select bind:value={location.displayMode}>
						<option value={0}>Items on top</option>
						<option value={1}>Modules on top</option>
						<option value={2}>Only show Modules</option>
						<option value={3}>Only show Items</option>
						<!-- <option value={4}>Group by categories</option> -->
					</select>
				</label>
				<label>
					Display direction (App home screen)
					<select bind:value={location.displayDirection}>
						<option value={0}>Horizontal lists</option>
						<option value={1}>Vertical lists</option>
					</select>
				</label>
				<label>
					Registration requirements?
					<select bind:value={location.requireRegistration}>
						<option value={0}>Allow guest accounts</option>
						<option value={1}>Require registration</option>
					</select>
				</label>
				{#if $user.admin}
					<label>
						Location type
						<select bind:value={location.isPremium}>
							<option value={0}>Standard</option>
							<option value={1}>Premium</option>
						</select>
					</label>
				{/if}
				<Button submit>Update settings</Button>
			</form>
		</div>
		<div class="right-side">
			{#if locationimages}
				<div class="locationimage-container">
					<Topbar
						small
						title="Location image"
						description="Select from the default images or upload your own." />
					<div class="imagecontainer">
						{#each locationimages as image}
							<div>
								<div
									class="locationimage"
									class:selected={selectedImage === image.id}
									style={`background-image:url("${image.url}")`}
									on:click={() =>
										onLocationImageClick(image)} />
							</div>
						{/each}
					</div>
					<div class="custom-image-container">
						<br />
						Custom image
						{#if previewedImage}
							<div class="preview">
								<div
									class="locationimage"
									class:selected={!selectedImage}
									on:click={() => {
										fileinput.click();
									}}
									style={`background-image:url("${previewedImage}")`} />
								<div
									class="replace-label"
									on:click={() => {
										fileinput.click();
									}}>
									<p>Click to replace</p>
								</div>
							</div>
						{:else if location.image && location.image.type !== 'default'}
							<div class="preview">
								<div
									class="locationimage"
									class:selected={selectedImage ===
										location.image.id}
									on:click={() => {
										fileinput.click();
									}}
									style={`background-image:url("${location.image.url}")`} />
								<div
									class="replace-label"
									on:click={() => {
										fileinput.click();
									}}>
									<p>Click to replace</p>
								</div>
							</div>
						{:else}
							<div
								class="add-new-image"
								on:click={() => {
									fileinput.click();
								}}>
								Upload new
							</div>
						{/if}
						<p class="tip">Recommended 1280x600</p>
						<input
							type="file"
							style="display:none"
							bind:this={fileinput}
							accept=".jpg, .jpeg, .png"
							on:input={(e) => {
								onFileSelected(e);
							}} />
					</div>
					<br />
					<Button on:click={onLocationImageSubmit}
						>Update location image</Button>
				</div>
			{/if}
			<div class="locationcode-container">
				<Topbar
					small
					title="Location codes"
					description="
				Anyone with your location codes can join your location without
				an invite. By default, there is no code, and all users have to
				be invited">
					<Button on:click={onAddLocationCode}>
						Generate location code
					</Button>
				</Topbar>
				<StoreTable
					store={locationcodes}
					columns={locationCodeColumns}
					actions={locationCodeActions}
					bind:onUpdate />
			</div>
			<br /><br />
		</div>
	</div>
	<QRDialog bind:open={openQRDialog} locationCode={selectedLocationCode} />
</Page>

<style>
	.tip {
		margin: 2px 0px;
		font-size: 14px;
		color: #777;
	}
	.container {
		display: flex;
		justify-content: space-between;
	}
	.left-side {
		margin-bottom: 10px;
		flex-basis: 40%;
	}
	.right-side {
		flex-basis: 55%;
	}
	.halfContainer {
		display: flex;
		justify-content: space-between;
	}
	.halfContainer .col {
		width: 48%;
	}
	.imagecontainer {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
		grid-gap: 1rem;
	}
	.locationimage-container {
		margin-bottom: 30px;
	}
	.locationimage {
		box-sizing: border-box;
		width: 100%;
		padding-top: 60%;
		border-radius: 5px;
		background-size: cover;
		background-position: center;
	}

	.replace-label {
		width: 100%;
		text-align: center;
		position: absolute;
		top: 35%;
		display: flex;
		justify-content: center;
	}

	.locationimage.selected {
		border: solid 6px rgb(250, 89, 89);
	}

	.custom-image-container {
		width: 200px;
	}

	.preview {
		position: relative;
	}
	.preview p {
		text-align: center;
		display: none;
		margin: 0 auto;
		padding: 10px;
		border-radius: 2px;
		background-color: white;
	}
	.preview .locationimage {
		margin: 0;
	}
	.preview:hover .locationimage {
		opacity: 0.5;
		cursor: pointer;
	}
	.preview:hover p {
		display: block;
		cursor: pointer;
	}
	.add-new-image {
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100px;
		border: dashed 3px #999;
		color: #999;
		border-radius: 5px;
	}
	.add-new-image:hover {
		border-color: #aaa;
		color: #aaa;
		cursor: pointer;
	}
	@media screen and (max-width: 1300px) {
		.container {
			flex-direction: column;
			grid-template-columns: 100%;
		}
	}
</style>
