<script>
	import Page from '../components/Page.svelte';
	import Button from '../components/Button.svelte';
	import StoreTable from '../components/StoreTable.svelte';
	import UserGroupDialog from '../components/dialogs/UserGroupDialog.svelte';

	import {usergroups} from '../stores/usergroups';

	let selected = [];
	let clicked;
	let onUpdateUserGroups;
	const onUpdate = (...args) => {
		onUpdateUserGroups(...args);
	};

	let openUserGroupDialog;

	const onAdd = () => {
		openUserGroupDialog();
	};

	const onEdit = (group) => {
		clicked = group;
		openUserGroupDialog();
	};

	const onDelete = async (group) => {
		if (!confirm(`Are you sure you want to remove group ${group.name}?`)) {
			return;
		}

		await usergroups.delete(group.id);
		usergroups.fetch();
	};

	const columns = [
		{
			title: 'Name',
			value: (group) => group.name,
			orderKey: 'name',
		},
	];
	const actions = [
		{
			title: 'Edit',
			icon: 'edit',
			action: onEdit,
		},
		{
			title: 'Delete',
			icon: 'delete',
			action: onDelete,
		},
	];
</script>

<Page
	title="User groups"
	description="Manage your location's users here. Invite new users or copy them from this location to another you control."
	crumbs={['User groups']}>
	<svelte:fragment slot="toolbar">
		<Button on:click={onAdd}>Add user group</Button>
	</svelte:fragment>
	<StoreTable
		store={usergroups}
		columns={columns}
		actions={actions}
		emptyMessage="There are no users in this location"
		bind:onUpdate={onUpdateUserGroups}
		bind:selected />
	<br /><br />

	<UserGroupDialog
		bind:open={openUserGroupDialog}
		usergroup={clicked}
		on:submit={onUpdate} />
</Page>
