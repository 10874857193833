<script>
	import Page from '../components/Page.svelte';
	import Button from '../components/Button.svelte';
	import StoreTable from '../components/StoreTable.svelte';
	import TextboxDialog from '../components/dialogs/TextboxDialog.svelte';
	import {shortUsers} from '../stores/short/users';
	import LinkContentDialog from '../components/dialogs/LinkContentDialog.svelte';

	import {textboxes} from '../stores/textboxes';

	shortUsers.fetch();

	let onUpdate;
	let selectedTextbox;
	let openTextboxDialog;
	let openLinkContentDialog;

	const onLink = (textbox) => {
		selectedTextbox = textbox;
		openLinkContentDialog();
	};

	const onAdd = () => {
		selectedTextbox = null;
		openTextboxDialog();
	};

	const onEdit = (textbox) => {
		selectedTextbox = textbox;
		openTextboxDialog();
	};

	const onDelete = async (textbox) => {
		if (
			!confirm(
				`Are you sure you want to delete ${
					textbox.title || textbox.id
				}?`
			)
		) {
			return;
		}
		await textboxes.delete(textbox.id);
		onUpdate();
	};

	const columns = [
		{
			title: 'Title',
			value: (textbox) => textbox.title,
			orderKey: 'title',
			truncate: true,
		},
		{
			title: 'Text',
			value: (textbox) => textbox.text,
			truncate: true,
			orderKey: 'text',
		},
		{
			title: 'User',
			value: (textbox) => textbox.ownerId,
			type: 'user',
			orderKey: 'ownerId',
		},
		{
			title: 'Created at',
			value: (textbox) => textbox.createdAt,
			type: 'time',
			orderKey: 'createdAt',
		},
	];

	const actions = [
		{
			title: 'Edit',
			icon: 'edit',
			action: onEdit,
		},
		{
			title: 'Delete',
			icon: 'delete',
			action: onDelete,
		},
		{
			title: 'Link to module',
			icon: 'add_link',
			action: onLink,
		},
	];
</script>

<Page
	title="Textboxes"
	description="Manage textboxes here"
	crumbs={['Textboxes']}>
	<svelte:fragment slot="toolbar">
		<Button on:click={onAdd}>Add textbox</Button>
	</svelte:fragment>
	<StoreTable
		store={textboxes}
		columns={columns}
		actions={actions}
		bind:onUpdate />
	<TextboxDialog
		bind:open={openTextboxDialog}
		bind:textbox={selectedTextbox}
		on:submit={onUpdate} />
	<LinkContentDialog
		bind:open={openLinkContentDialog}
		textboxId={selectedTextbox?.id} />
</Page>
