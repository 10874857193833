<script>
	import {createEventDispatcher, onMount} from 'svelte';
	import Dialog from '../Dialog.svelte';
	import {
		resolveServiceMessage,
		unresolveServiceMessage,
		postServiceMessageReply,
		getServiceMessage,
	} from '../../api/servicemessages';
	import {fullTime} from '../../utils/timeSince';
	import Button from '../Button.svelte';

	const dispatch = createEventDispatcher();

	export let error = '';
	export let open;
	let close;
	export let onUpdate = () => {};
	export let limit;
	export let offset;

	export let serviceMessage;
	export let isResolved;

	$: serviceMessage = serviceMessage;

	let newReply = {
		id: undefined,
		message: '',
		serviceMessageId: serviceMessage?.id,
	};

	const fetchServiceMessage = async () => {
		serviceMessage = await getServiceMessage(
			offset,
			limit,
			'createdAt',
			serviceMessage.id
		);
		isResolved = Boolean(serviceMessage?.isResolved);
	};

	const insertServiceMessageReply = async () => {
		newReply.serviceMessageId = serviceMessage?.id;
		postServiceMessageReply(newReply);
		fetchServiceMessage();
	};

	let onOpen = (message) => {
		serviceMessage = message;
		fetchServiceMessage();
	};

	const onSubmit = async () => {
		try {
			if (
				newReply.message === '' &&
				isResolved &&
				!confirm(`Are you sure you want to resolve without a message?`)
			) {
				return;
			}
			if (isResolved) {
				await resolveServiceMessage(serviceMessage.id);
			} else {
				await unresolveServiceMessage(serviceMessage.id);
			}

			if (newReply.message !== '') {
				await insertServiceMessageReply();
			}

			dispatch('submit');
			onUpdate();
			fetchServiceMessage();
			newReply.message = '';
		} catch (e) {
			error = e.error ? e.error : e;
		}
	};
</script>

<Dialog
	title="Service message"
	noSubmit
	cancelName="Close"
	bind:open
	bind:close
	bind:error
	bind:onOpen>
	{#if serviceMessage}
		<div class="messageWrapper">
			<div class="replyMessageInfo">
				<span class="replyUsername">
					{serviceMessage.user.name}
				</span>
			</div>
			{serviceMessage.message}
			<div class="ReplyCreatedAt">
				{fullTime(serviceMessage.createdAt)}
			</div>
		</div>
		{#if serviceMessage?.replies}
			{#each serviceMessage.replies as reply}
				<div class="replyMessageWrapper">
					<div class="replyMessageInfo">
						<span class="replyUsername">
							{reply.user.name}
						</span>
					</div>
					{reply.message}
					<div class="ReplyCreatedAt">
						{fullTime(reply.createdAt)}
					</div>
				</div>
			{/each}
		{/if}
		<form on:submit|preventDefault={onSubmit}>
			<label>
				Write a reply
				<textarea
					placeholder="Write a reply"
					bind:value={newReply.message} />
			</label>
			<label>
				Mark as resolved
				<input type="checkbox" bind:checked={isResolved} />
			</label><br /><br />
			<Button submit>
				{newReply.message ? 'Send reply' : 'Update resolved status'}
			</Button>
		</form>
	{/if}
</Dialog>

<style>
	.replyUsername {
		font-weight: bold;
		font-size: 16px;
	}
	.ReplyCreatedAt {
		font-size: 13px;
		color: #666;
	}

	.replyMessageWrapper {
		margin-bottom: 10px;
		margin-left: 15px;
	}

	.replyMessageInfo {
		margin-bottom: 3px;
	}
	.messageWrapper {
		margin-bottom: 20px;
	}
</style>
