import {writable} from 'svelte/store';

import {
	deleteTextbox,
	getTextboxes,
	updateTextbox,
	postTextbox,
} from '../api/textboxes';

function createTextboxes() {
	const {subscribe, set, update} = writable();

	return {
		subscribe,
		fetch: async (offset, limit, orderBy) => {
			return set(await getTextboxes(offset, limit, orderBy));
		},
		add: async (textbox) => {
			await postTextbox(textbox);
		},
		change: async (textbox) => {
			await updateTextbox(textbox);
		},
		delete: async (id) => {
			await deleteTextbox(id);
			update((arr) => ({
				count: arr.count,
				records: arr.records.filter((textbox) => textbox.id !== id),
			}));
		},
	};
}

export const textboxes = createTextboxes();
