<script>
	import Page from '../components/Page.svelte';
	import Button from '../components/Button.svelte';
	import StoreTable from '../components/StoreTable.svelte';
	import ModelDialog from '../components/dialogs/ModelDialog.svelte';

	import {models} from '../stores/models';

	let onUpdate;
	let clickedModel;
	let openModelDialog;

	const onAdd = () => {
		clickedModel = null;
		openModelDialog();
	};

	const onEdit = (model) => {
		clickedModel = model;
		openModelDialog();
	};

	const onDelete = (model) => {
		if (confirm(`Are you sure you want to delete model ${model.name}?`)) {
			models.delete(model.id);
		}
	};

	const onUpdatePre = () => {
		models.fetch();
		onUpdate();
	};

	const columns = [
		{
			title: 'Name',
			value: (row) => row.name,
			orderKey: 'name',
		},
		{
			title: 'Created at',
			value: (e) => e.createdAt,
			type: 'time',
			orderKey: 'createdAt',
		},
	];

	const actions = [
		{
			title: 'Download Model',
			icon: 'get_app',
			href: (row) => row.modelUrl,
		},
		{
			title: 'Download Labels',
			icon: 'get_app',
			href: (row) => row.labelUrl,
		},
		{
			title: 'Edit',
			icon: 'edit',
			action: onEdit,
		},
		{
			title: 'Delete',
			icon: 'delete',
			action: onDelete,
		},
	];
</script>

<Page title="Models">
	<svelte:fragment slot="toolbar">
		<Button on:click={onAdd}>Add model</Button>
	</svelte:fragment>
	<StoreTable
		store={models}
		columns={columns}
		actions={actions}
		bind:onUpdate />
	<ModelDialog
		model={clickedModel}
		bind:open={openModelDialog}
		on:submit={onUpdatePre} />
</Page>
