<script>
	export let progress = 0;
	export let thin = false;
</script>

<div class="progressbar" title="{progress}%" style={thin ? 'height:7px;' : ''}>
	<div
		class="progressbar__inner"
		class:complete={progress === 100}
		style="width:{progress}%;{thin ? 'height:7px;' : ''}" />
</div>

<style>
	.progressbar {
		width: 400px;
		max-width: 100%;
		height: 20px;
		border-radius: 10px;
		overflow: hidden;
		background-color: #000;
	}

	.progressbar__inner {
		width: 0%;
		border-radius: 10px;
		height: 20px;
		background-color: #4099ff;
		transition: 0.5s all;
	}
</style>
