<script>
	import {onMount} from 'svelte';

	import {user as userStore} from '../stores/user';
	import {token} from '../stores/token';
	import {loginLogs} from '../stores/loginLogs';

	import {refreshLogin} from '../api/authentication';
	import {
		deleteOwnRole,
		deleteSelf,
		updateSelf,
		getSelf,
		linkMyGoogleAuth,
		unlinkMyGoogleAuth,
		getSelfNotificationPreferences,
		updateSelfNotificationPreferences,
	} from '../api/users';
	import {shortLocations} from '../stores/short/locations';

	import Page from '../components/Page.svelte';
	import Button from '../components/Button.svelte';
	import Table from '../components/Table.svelte';
	import StoreTable from '../components/StoreTable.svelte';
	import GoogleSignInButton from '../components/GoogleSignInButton.svelte';

	shortLocations.fetch();

	let name = $userStore?.name;
	let email = $userStore?.email;
	let googleError = '';

	let showNotificationPreferences = false;

	let user = {
		roles: [],
	};
	let notificationPreferences = {};

	let infoError = null;

	onMount(async () => {
		user = await getSelf();
		name = user.name;
		email = user.email;

		notificationPreferences = await getSelfNotificationPreferences();
	});

	let onUpdate;

	const updatePage = async () => {
		const newLocation = await refreshLogin();
		$token = newLocation.token;
		location.reload();
	};

	const onInfoSave = async () => {
		infoError = null;
		try {
			await updateSelf({name, email});
			await updatePage();
		} catch (e) {
			console.error(e);
			if (e.error) {
				infoError = e.error;
			}
		}
	};

	const onRoleDelete = async (role) => {
		if (role.text === 'Admin') {
			alert('You can not delete the admin role.');
			return;
		}

		if (!confirm('Are you sure you want to remove this role?')) {
			return;
		}
		try {
			await deleteOwnRole(role.id, role.locationId);
			await updatePage();
		} catch (e) {
			console.error(e);
		}
	};

	const onDelete = async () => {
		if (
			!confirm(
				'Are you sure you want to completely remove your user? This action cannot be undone'
			)
		) {
			return;
		}
		try {
			await deleteSelf();
			$token = null;
		} catch (e) {
			console.error(e);
		}
	};

	const roleColumns = [
		{
			title: 'Location',
			value: (role) =>
				$shortLocations[role.locationId] ||
				role.locationText ||
				'No location',
			href: (role) => `/location/${role.locationId}`,
		},
		{
			title: 'Role',
			value: (role) => role.name || 'No role',
		},
	];
	const roleActions = [
		{
			title: 'Delete',
			icon: 'delete',
			action: onRoleDelete,
		},
	];

	const loginMethods = [
		'Email and Password',
		'Username and Password',
		'Service Token',
		'Empty User Token',
		'Google',
	];
	const loginLogsColumns = [
		{
			title: 'Method',
			value: (record) => loginMethods[record.method],
		},
		{
			title: 'IP-address',
			value: (record) => record.ip,
		},
		{
			title: 'Time',
			value: (record) => record.createdAt,
			type: 'time',
		},
	];
	const superAdminRows = [];
	if ($userStore?.admin) {
		superAdminRows.push({name: 'Admin', locationText: 'Global'});
	}

	const onGoogleSignIn = async (idToken) => {
		try {
			const authResponse = await linkMyGoogleAuth({idToken});
			console.log(authResponse);
		} catch (e) {
			console.log(e);
			return;
		}
		updatePage();
	};

	const unlinkGoogleLogin = async () => {
		googleError = '';
		try {
			await unlinkMyGoogleAuth();
			updatePage();
		} catch (e) {
			googleError = e.error;
		}
	};

	const notificationTopics = [
		{
			name: 'I am invited to a new space',
			example: 'You have been invited to a new space!',
			key: 'user_invited',
		},
		{
			name: 'Someone responds to my service messages',
			example: 'An admin has responded to your service message',
			key: 'user_serviceMessageUpdates',
		},
		{
			name: 'New content has been added to a space I am in',
			example: 'New content has been added to ExampleSpace',
			key: 'user_newContentUpdates',
		},
		{
			name: 'Someone comments on my content',
			example: 'Simen commented on your video',
			key: 'contentCreator_newComment',
		},
		{
			name: 'When my content reaches new milestones',
			example: 'Your video has been viewed 1000 times!',
			key: 'contentCreator_engagementMilestones',
		},
		{
			name: 'New users join my spaces',
			example: 'A user has joined your space!',
			key: 'locationAdmin_userJoined',
		},
		{
			name: 'Users accept my invitations',
			example: 'A user has accepted your invitation',
			key: 'locationAdmin_userAcceptedInvite',
		},
		{
			name: 'Someone posts a service message in a space where I am admin',
			example: 'Someone posted a new service message.',
			key: 'locationAdmin_newServiceMessage',
		},
	];

	const updateNotificationPreferences = async () => {
		await updateSelfNotificationPreferences(notificationPreferences);

		showNotificationPreferences = false;
	};
</script>

<Page title="My Profile">
	<h2 class="padder">My information</h2>
	<form on:submit|preventDefault={onInfoSave}>
		<label>
			{'Name'}<input type="text" bind:value={name} required />
		</label>
		<label>
			{'Email Address'}<input type="text" bind:value={email} required />
		</label>
		{#if infoError} <p class="error">{infoError}</p>{/if}
		<Button submit>Save</Button>
	</form>
	<br />
	<h2 class="padder">Notification preferences</h2>
	<p>Choose what notifications you want to receive in the Unifractal app</p>
	{#if !showNotificationPreferences}
		<Button filled on:click={() => (showNotificationPreferences = true)}>
			Manage notification preferences
		</Button>
	{:else}
		<label>
			<input
				class="bigCheck"
				type="checkbox"
				bind:checked={notificationPreferences.notificationsEnabled} />
			Send me notifications
			{#if !notificationPreferences.notificationsEnabled}
				<p class="notification-example error">
					You will not receive any notifications on your phone
				</p>
			{/if}
		</label>

		<p>Send me notifications when..</p>
		{#each notificationTopics as topic}
			<label
				class:disabled={!notificationPreferences.notificationsEnabled}>
				<input
					disabled={!notificationPreferences.notificationsEnabled}
					class="bigCheck"
					type="checkbox"
					bind:checked={notificationPreferences[topic.key]} />
				{topic.name}
				<p class="notification-example">{topic.example}</p>
			</label>
		{/each}
		<Button on:click={updateNotificationPreferences}>
			Update notification preferences
		</Button>
	{/if}
	<br />
	<br />
	<h2 class="padder">My roles</h2>
	<Table
		columns={roleColumns}
		rows={[...superAdminRows, ...user?.roles]}
		actions={roleActions}
		hidePaginator />
	<br />
	<h2 class="padder">My latest logins</h2>
	<StoreTable
		store={loginLogs}
		columns={loginLogsColumns}
		bind:onUpdate
		limit={5}
		hidePaginator />
	<br /><br />
	<h2 class="padder">Login with Google</h2>
	{#if user?.hasGoogleLogin}
		<p>Your account is set up with "Login with Google".</p>
		<Button on:click={unlinkGoogleLogin}>Unlink google account</Button>
		<p class="error">{googleError}</p>
	{:else}
		<p>
			Your account is not set up with Login with Google. Click the button
			below to connect an account
		</p>
		<GoogleSignInButton onSignIn={onGoogleSignIn} />
	{/if}
	<br />
	<h2 class="padder">Account Management</h2>
	<p>
		If you wish to delete your user account you can click the button below.
		All the information about you in our system will be deleted, but any
		content you've uploaded to locations will remain. If you also wish to
		delete this, you should do it manually before deleting your account.
	</p>
	<Button danger on:click={onDelete}>Delete my account</Button>
	<br /><br /><br />
	<br /><br /><br />
</Page>

<style>
	input[type='text'] {
		max-width: 300px;
		display: block;
	}
	label {
		display: block;
		font-size: 16px;
	}
	.disabled {
		color: #999;
	}
	.padder {
		padding: 20px 0 5px 0;
		color: #30465e;
		font-weight: bold;
		text-transform: uppercase;
		font-size: 18px;
	}

	.error {
		color: red;
		font-size: 14px;
	}

	.notification-example {
		font-size: 14px;
		font-style: italic;
		margin: 5px 0 20px 0;
	}
</style>
