import {writable} from 'svelte/store';

import {getRolesShort} from '../../api/roles';

function createRoles() {
	const {subscribe, set} = writable({});
	return {
		subscribe,
		fetch: async () => {
			const roles = await getRolesShort();
			const result = roles.reduce((map, obj) => {
				map[obj.id] = obj.name;
				return map;
			}, {});
			return set(result);
		},
	};
}

export const shortRoles = createRoles();
