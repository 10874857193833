import {BaseApi, LocationApi} from './api';

export const getModules = async (offset, limit, order) => {
	return LocationApi.get(
		`/modules?type=course&offset=${offset}&limit=${limit}&orderBy=${order}`
	);
};

export const getItemModules = async (offset, limit, order) => {
	return LocationApi.get(
		`/modules?type=item&offset=${offset}&limit=${limit}&orderBy=${order}`
	);
};

export const getModulesFromLocation = async ({id, offset, limit, order}) => {
	return BaseApi.get(
		`/location/${id}/modules?offset=${offset}&limit=${limit}&orderBy=${order}`
	);
};

export const copyModule = async (id) => {
	return LocationApi.post(`/modules/copy`, {sourceModuleId: id});
};

export const getShortModules = async () => {
	return LocationApi.get(`/modules?short=true`);
};

export const getModule = async (id) => {
	return LocationApi.get(`/module/${id}`);
};

export const getPublicModulesForMyLocations = async (type, includeSelf) => {
	const query = new URLSearchParams();

	if (type) {
		query.set('type', type);
	}
	if (includeSelf) {
		query.set('includeSelf', true);
	}

	return LocationApi.get(`/modules/fromlocations?${query.toString()}`);
};

export const deleteModule = async (id, data) => {
	return LocationApi.delete(`/module/${id}`, data);
};

export const postModule = async (module) => {
	const data = new FormData();

	if (module.name) {
		data.append('name', module.name);
	}
	if (module.description) {
		data.append('description', module.description);
	}
	if (module.files) {
		data.append('thumbnail', module.files[0]);
	}
	if (module.hideFromHome !== undefined) {
		data.append('hideFromHome', module.hideFromHome);
	}
	if (module.type !== undefined) {
		data.append('type', module.type);
	}

	return LocationApi.post('/module', data);
};

export const updateModule = async (module) => {
	const data = new FormData();

	if (module.name) {
		data.append('name', module.name);
	}
	if (module.description) {
		data.append('description', module.description);
	}
	if (module.files) {
		data.append('thumbnail', module.files[0]);
	}
	if (module.hideFromHome !== undefined) {
		data.append('hideFromHome', module.hideFromHome);
	}
	if (module.restrictAccess !== undefined) {
		data.append('restrictAccess', module.restrictAccess);
	}
	if (module.userGroups !== undefined) {
		data.append('userGroups', module.userGroups);
	}

	return LocationApi.update('/module/' + module.id, data);
};

export const moveModule = async (moduleId, afterId) => {
	return LocationApi.put(`/modules/move`, {moduleId, afterId});
};

export const moveModuleUp = async (moduleId, type) => {
	return LocationApi.put(`/modules/${moduleId}/up`, {type});
};

export const moveModuleDown = async (moduleId, type) => {
	return LocationApi.put(`/modules/${moduleId}/down`, {type});
};

export const convertToModule = async (moduleId) => {
	return LocationApi.put(`/modules/${moduleId}/convertToModule`);
};

export const convertToItem = async (moduleId, itemId) => {
	return LocationApi.put(`/modules/${moduleId}/convertToItem`, {itemId});
};

export const linkContent = async (data) => {
	return LocationApi.post('/module/content/link', data); //Expects parentId and one of videoId, documentId, textboxId, etc.
};

export const unlinkContent = async (contentId) => {
	return LocationApi.post('/module/content/unlink', {
		contentId,
	});
};

export const pinContent = async (contentId) => {
	return LocationApi.put(`/content/${contentId}/pin`);
};

export const unpinContent = async (contentId) => {
	return LocationApi.put(`/content/${contentId}/unpin`);
};

export const moveContent = async (data) => {
	return LocationApi.put('/module/content/move', data);
};

export const updateContent = async ({id, name, description}) => {
	console.log(name, description);
	return LocationApi.put(`/content/${id}`, {name, description});
};
