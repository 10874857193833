import axios from 'axios';
import {router} from 'tinro';

import {token} from '../stores/token';
import {refreshToken} from './authentication';

let location = null;

router.subscribe((route) => {
	const pathComponents = route.path.split('/');
	const index = pathComponents.findIndex((item) => item === 'location');
	location = pathComponents[index + 1];
});

const BASE_API_ENDPOINT = myapp.env.BASE_API_ENDPOINT; //eslint-disable-line no-undef

const api = axios.create({
	baseURL: BASE_API_ENDPOINT, //eslint-disable-line no-undef
});

let tokenLock;
token.subscribe((val) => {
	api.defaults.headers.common.authorization = `Bearer ${val}`;
});

export default axios;

const trycatch = async (func) => {
	try {
		const response = await func();

		return response.data;
	} catch (error) {
		if (error.message === 'Network Error') {
			return Promise.reject({
				error: 'Could not connect to server. Please try again later',
			});
		}
		if (error.response.status === 401) {
			if (!tokenLock) {
				tokenLock = new Promise(async (r) => {
					try {
						const newToken = await refreshToken();
						if (newToken.authToken) {
							token.set(newToken.authToken);
						} else {
							token.set(null);
						}
						tokenLock = null;
						return r(true);
					} catch (e) {
						token.set(null);
						tokenLock = null;
						return r(false);
					}
				});
			}
			if (await tokenLock) {
				return trycatch(func);
			}
		}
		if (error.response.status === 304) {
			error.response.data = {error: 'Nothing changed'};
		}
		return Promise.reject(error.response.data);
	}
};

/*
 * Base API methods
 */
export class BaseApi {
	static post = async (url, data, config = {}) => {
		return trycatch(() => api.post(url, data, config));
	};
	static get = async (url, config = {}) => {
		return trycatch(() => api.get(url, config));
	};
	static update = async (url, data) => {
		return trycatch(() => api.put(url, data));
	};
	static delete = async (url, data) => {
		return trycatch(() => api.delete(url, {data}));
	};
}
export const MLApi = {
	path: '/machine-learning',
	post: async (url, data, config = {}) => {
		return trycatch(() => api.post(MLApi.path + url, data, config));
	},
	get: async (url, config = {}) => {
		return trycatch(() => api.get(MLApi.path + url, config));
	},
	update: async (url, data) => {
		return trycatch(() => api.put(MLApi.path + url, data));
	},
	delete: async (url, data) => {
		return trycatch(() => api.delete(MLApi.path + url, {data}));
	},
};

export const LocationApi = {
	path: '/location/',
	getPath: () => {
		return LocationApi.path + location;
	},
	post: async (url, data, config = {}) => {
		return trycatch(() =>
			api.post(LocationApi.getPath() + url, data, config)
		);
	},
	get: async (url, config = {}) => {
		return trycatch(() => api.get(LocationApi.getPath() + url, config));
	},
	update: async (url, data) => {
		return trycatch(() => api.put(LocationApi.getPath() + url, data));
	},
	put: async (url, data) => {
		return trycatch(() => api.put(LocationApi.getPath() + url, data));
	},
	delete: async (url, data) => {
		return trycatch(() => api.delete(LocationApi.getPath() + url, {data}));
	},
};

//Media paths
export const mediaPost = async (url, data, muData, config = {}) => {
	const media = await LocationApi.post(url, data);

	return trycatch(() =>
		axios.put(media.url, muData, {
			// Remove default authorization header
			// otherwise cors will fail on google cloud
			transformRequest: (data, headers) => {
				delete headers.common.authorization;
				return data;
			},
			headers: {
				'Content-Type': muData.type,
			},
			...config,
		})
	);
};

export const cleanMediaPost = async (url, data, config = {}) => {
	return trycatch(() =>
		axios.put(url, data, {
			// Remove default authorization header
			// otherwise cors will fail on google cloud
			transformRequest: (data, headers) => {
				delete headers.common.authorization;
				return data;
			},
			headers: {
				'Content-Type': data.type,
			},
			...config,
		})
	);
};
export const getServiceEndpoint = (type) => {
	return BaseApi.get('/services/endpoint?type=' + type);
};

export const mediaUpdate = async (url, data, config = {}) => {
	const service = await getServiceEndpoint('media-uploader');
	const configOverride = {
		...config,
		baseURL: service.endpoint,
	};
	return trycatch(() => axios.put(url, data, configOverride));
};

//Machine learning paths
export const mlPost = async (url, data, config = {}) => {
	const service = await getServiceEndpoint('machine-learning');

	const configOverride = {
		...config,
		baseURL: service.endpoint,
	};

	return trycatch(() => axios.post(url, data, configOverride));
};
