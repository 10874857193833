<script>
	import Page from '../components/Page.svelte';
	import Button from '../components/Button.svelte';
	import StoreTable from '../components/StoreTable.svelte';
	import UserDialog from '../components/dialogs/UserDialog.svelte';
	import UserCopyDialog from '../components/dialogs/UserCopyDialog.svelte';
	import InviteUserDialog from '../components/dialogs/InviteUserDialog.svelte';
	import BulkInviteDialog from '../components/dialogs/BulkInviteDialog.svelte';

	import {users} from '../stores/users';
	import {invites} from '../stores/invites';
	import {reInviteUser} from '../api/users';
	import NewServiceAccountDialog from '../components/dialogs/NewServiceAccountDialog.svelte';

	let selected = [];
	let clickedUser;
	let onUpdateUsers;
	let onUpdateInvites;
	const onUpdate = (...args) => {
		onUpdateUsers(...args);
		onUpdateInvites(...args);
	};

	let openUserDialog;
	let openUserCopyDialog;
	let openInviteDialog;
	let openBulkInviteDialog;
	let openNewServiceAccountDialog;

	const onAdd = () => {
		openInviteDialog();
	};

	const onBulkInvite = () => {
		openBulkInviteDialog();
	};

	const onCopy = () => {
		openUserCopyDialog();
	};

	const onEdit = (user) => {
		clickedUser = user;
		openUserDialog();
	};

	const onCreateServiceAccount = async () => {
		openNewServiceAccountDialog();
	};

	const onDelete = async (user) => {
		if (!confirm(`Are you sure you want to remove user ${user.name}?`)) {
			return;
		}

		await users.delete(user.id);
		users.fetch();
	};
	const onDeleteInvite = async (invite) => {
		if (
			!confirm(
				`Are you sure you want to delete the invite to ${invite.inviteeEmail}?`
			)
		) {
			return;
		}

		await invites.delete(invite.id);
		invites.fetch();
	};

	const onReInvite = async (user) => {
		await reInviteUser(user);
		alert('User reinvited');
	};

	const getGroupsText = (groups) => {
		let text = groups.map((g) => g.name).join(', ');
		if (text.length > 25) text = `${groups.length} groups`;
		return text;
	};

	const columns = [
		{
			title: 'Name',
			value: (user) => user.name,
			orderKey: 'name',
			href: (user) => `users/${user.id}`,
		},
		{
			title: 'Email',
			value: (user) => user.email,
			orderKey: 'email',
		},
		{
			title: 'Role',
			value: (user) => user.roleId,
			orderKey: 'roleId',
			type: 'role',
			hide: 'medium',
		},
		{
			title: 'Groups',
			value: (user) => getGroupsText(user.userGroups),
			orderKey: 'roleId',
			hide: 'medium',
		},
		{
			title: 'Last Login',
			value: (user) => user.lastLogin,
			type: 'time',
		},
	];

	const actions = [
		{
			title: 'Edit',
			icon: 'edit',
			action: onEdit,
		},
		{
			title: 'Delete',
			icon: 'delete',
			action: onDelete,
		},
	];

	const inviteColumns = [
		{
			title: 'Invited Email',
			value: (invite) => invite.inviteeEmail,
		},
		{
			title: 'Role',
			value: (invite) => invite.roleId,
			type: 'role',
		},
		{
			title: 'Status',
			value: (invite) => (invite.isConsumed ? 'Declined' : 'Unanswered'),
		},
	];

	const inviteActions = [
		{
			title: 'Delete',
			icon: 'delete',
			action: onDeleteInvite,
		},
		{
			title: 'Re-invite',
			icon: 'cached',
			action: onReInvite,
			isVisible: (row) => row.refreshVisible,
		},
	];
</script>

<Page
	title="Users"
	description="Manage your location's users here. Invite new users or copy them from this location to another you control."
	crumbs={['Users']}>
	<svelte:fragment slot="toolbar">
		<Button on:click={onCreateServiceAccount}>
			Create service account
		</Button>
		<Button on:click={onBulkInvite}>Invite users</Button>
		<!-- <Button on:click={onAdd}>Invite users</Button> -->
		<Button on:click={onCopy}>Copy user</Button>
	</svelte:fragment>
	<StoreTable
		store={users}
		columns={columns}
		actions={actions}
		emptyMessage="There are no users in this location"
		bind:onUpdate={onUpdateUsers}
		bind:selected />
	<br /><br />
	<StoreTable
		store={invites}
		columns={inviteColumns}
		actions={inviteActions}
		emptyMessage="There are no active invites"
		bind:onUpdate={onUpdateInvites} />
	<UserCopyDialog
		bind:open={openUserCopyDialog}
		userIds={selected.map((sel) => sel.id)} />
	<!-- <InviteUserDialog bind:open={openInviteDialog} on:submit={onUpdate} /> -->
	<UserDialog
		bind:open={openUserDialog}
		user={clickedUser}
		on:submit={onUpdate} />
	<BulkInviteDialog bind:open={openBulkInviteDialog} on:submit={onUpdate} />
	<NewServiceAccountDialog
		bind:open={openNewServiceAccountDialog}
		on:submit={onUpdate} />
</Page>
