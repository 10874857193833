<script>
	import BaseWidget from './BaseWidget.svelte';
	import {unlinkContent, unpinContent, pinContent} from '../../api/modules';

	export let content;
	export let afterThis;
	export let onUpdate;
	export let onMouseDown;

	const onDelete = async () => {
		if (confirm('Are you sure you want to remove this document?')) {
			await unlinkContent(content.id);
			if (onUpdate) {
				onUpdate();
			}
		}
	};

	const onPin = async () => {
		pinContent(content.id);
		if (onUpdate) {
			onUpdate();
		}
	};

	const onUnpin = async () => {
		unpinContent(content.id);
		if (onUpdate) {
			onUpdate();
		}
	};
</script>

<BaseWidget
	title={content.document.name}
	icon="description"
	content={content}
	afterThis={afterThis}
	onMouseDown={onMouseDown}
	onUnlink={onDelete}
	pinned={content.isPinned}
	onPin={onPin}
	onUnpin={onUnpin}>
	<div slot="expanded">
		<a href={content.document.url} target="_blank">Download</a>
	</div>
</BaseWidget>
