<script>
	import {router} from 'tinro';
	import {onMount} from 'svelte';

	import Button from '../components/Button.svelte';

	import {token} from '../stores/token';
	import {localstore} from '../stores/localstore';

	import {getInvite, acceptInvite, declineInvite} from '../api/invites';

	import {refreshLogin} from '../api/authentication';

	const code = $router.query.code;
	let invite = null;
	let inviteLoaded = false;
	let error = '';

	onMount(async () => {
		try {
			invite = await getInvite(code);
			inviteLoaded = true;
		} catch (e) {
			error = e.error ? e.error : e;
			inviteLoaded = true;
		}
	});

	const onAcceptInvite = async () => {
		await acceptInvite(code);
		const newLocation = await refreshLogin();
		$token = newLocation.token;
		location.href = '/location/' + invite.location.id;
		$localstore.locationId = invite.location.id;
	};
	const onDeclineInvite = async () => {
		await declineInvite(code);
		const newLocation = await refreshLogin();
		$token = newLocation.token;
		location.href = '/profile';
	};
</script>

<div class="invite-wrapper">
	{#if !inviteLoaded}
		<div class="invite">Loading invite...</div>
	{:else if invite}
		<div class="invite">
			<h1>Location invite</h1>
			<p>
				<b>{invite.inviter.name}</b> has invited you to join the
				location <b>{invite.location.name}</b>
				{#if invite.role.id !== 1}as a
					<b>{invite.role.name}</b>{/if}
			</p>
			<br />
			<Button filled on:click={onAcceptInvite}>Accept invite</Button>
			<Button textonly danger on:click={onDeclineInvite}>Decline</Button>
		</div>
	{:else}
		<div class="invite">
			<p class="error">Error</p>
			<p>
				This invite code does not exist, has expired, or was already
				responded to
			</p>
		</div>
	{/if}
</div>

<style>
	h1 {
		margin-top: 0;
		font-size: 20px;
	}
	p {
		margin: 0;
		font-size: 16px;
	}
	.error {
		text-transform: uppercase;
		color: red;
		font-weight: bold;
		font-size: 12px;
	}
	.invite-wrapper {
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.invite {
		box-sizing: border-box;
		margin-top: 50px;
		border-radius: 10px;
		padding: 40px;
		width: 400px;
		max-width: calc(100% - 20px);
		box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.178);
	}

	@media screen and (max-width: 500px) {
		.invite {
			padding: 40px 20px;
		}
	}
</style>
