import {LocationApi} from './api';

/*export const getTextboxes = async (offset, limit) => {
	return get(`/textboxes?offset=${offset}&limit=${limit}`);
};*/

export const getTextbox = async (textboxId) => {
	return LocationApi.get(`/textbox/${textboxId}`);
};

export const postTextbox = async (textbox, moduleId) => {
	return LocationApi.post('/textbox', {
		title: textbox.title,
		text: textbox.text,
		moduleId,
	});
};

export const updateTextbox = async (textbox) => {
	return LocationApi.update(`/textbox/${textbox.id}`, {
		title: textbox.title,
		text: textbox.text,
	});
};

export const deleteTextbox = async (id) => {
	return LocationApi.delete(`/textbox/${id}`);
};

export const getTextboxes = async (offset, limit, orderBy) => {
	return LocationApi.get(
		`/textbox?offset=${offset}&limit=${limit}&orderBy=${orderBy}`
	);
};
