<script>
	import {fly} from 'svelte/transition';

	import {router} from 'tinro';

	import Page from '../components/Page.svelte';
	import Table from '../components/Table.svelte';
	import Topbar from '../components/Topbar.svelte';
	import LoginChart from '../components/charts/LoginChart.svelte';
	import VideoViewChart from '../components/charts/VideoViewChart.svelte';
	import ServiceMessageWidget from '../components/overviewwidgets/ServiceMessageWidget.svelte';

	import {logs} from '../stores/logs';
	import {overview} from '../stores/overview';
	import {user} from '../stores/user';
	import {localstore} from '../stores/localstore';
	import {setLocation} from '../api/authentication';
	let updateChart;

	export let limit1 = 10;
	export let offset1 = 0;
	export let limit2 = 10;
	export let offset2 = 0;

	// Update if we switch location
	$: if ($router.path) {
		const currentLocId = $router.path.split('/').at(-1);
		if ($localstore.locationId !== currentLocId) {
			setLocation(currentLocId).then(() => {});
			$localstore.locationId = currentLocId;
		}
		overview.fetch();
		logs.fetch(offset1, limit1, offset2, limit2);
		if (updateChart) {
			updateChart();
		}
	}

	const columns = [
		{
			title: 'Action',
			value: (entry) => `${entry.name} ${entry.text}`,
			truncate: true,
		},
		{
			title: 'Time',
			value: (e) => e.createdAt,
			type: 'time',
		},
	];

	const getPercent = (from, to) => {
		return Math.round((from / (to + from)) * 100);
	};

	const getPercentTitle = (from, to) => {
		return `${from} (${getPercent(from, to)}%)`;
	};

	/* eslint-disable */
	//Disables cause these are produced in build scripts
	const buildType = myapp.env.BUILD_TYPE;
	/* eslint-enable */
</script>

<Page title={$overview?.name || 'Overview'}>
	{#if $overview && $logs}
		<div in:fly={{y: 10}}>
			<div class="cardcontainer">
				<div class="card filled">
					<span class="cardtitle">ITEMS</span>
					<span class="cardcount"
						>{$overview.counters.itemModuleCount}</span>
				</div>
				<div class="card filled">
					<span class="cardtitle">MODULES</span>
					<span class="cardcount"
						>{$overview.counters.moduleCount}</span>
				</div>
				<div class="card filled">
					<span class="cardtitle">USERS</span>
					<span class="cardcount"
						>{$overview.counters.registeredUserCount +
							$overview.counters.unregisteredUserCount}
					</span>
					<div
						title="Not registered {getPercentTitle(
							$overview.counters.unregisteredUserCount,
							$overview.counters.registeredUserCount
						)}"
						style="background-color:#777; width:100%; height:20px; border-radius: 8px">
						<div
							title="Registered {getPercentTitle(
								$overview.counters.registeredUserCount,
								$overview.counters.unregisteredUserCount
							)}"
							style="background-color:#555; height:20px; border-radius: 8px; width:{getPercent(
								$overview.counters.registeredUserCount,
								$overview.counters.unregisteredUserCount
							)}%;" />
					</div>
				</div>
				<div class="card filled">
					<span class="cardtitle">SERVICE MESSAGES</span>
					<span class="cardcount"
						>{$overview.counters.unresolvedServiceMessagesCount +
							$overview.counters
								.resolvedServiceMessagesCount}</span>
					<div
						title="Unresolved {getPercentTitle(
							$overview.counters.unresolvedServiceMessagesCount,
							$overview.counters.resolvedServiceMessagesCount
						)}"
						style="background-color:#777; width:100%; height:20px; border-radius: 8px">
						<div
							title="Resolved {getPercentTitle(
								$overview.counters.resolvedServiceMessagesCount,
								$overview.counters
									.unresolvedServiceMessagesCount
							)}"
							style="background-color:#555; height:20px; border-radius: 8px; width:{getPercent(
								$overview.counters.resolvedServiceMessagesCount,
								$overview.counters
									.unresolvedServiceMessagesCount
							)}%;" />
					</div>
				</div>
			</div>

			<div style="display:flex;flex-wrap:wrap;">
				<div style="width:50%">
					<h2>Logins last week</h2>
					<LoginChart />
				</div>
				<div style="width:50%">
					<h2>Video views last week</h2>
					<VideoViewChart bind:updateChart />
				</div>
			</div>

			{#if buildType !== 'PRODUCTION'}
				<!-- TODO SERVICE MESSAGES: Remove check when implemented in app -->
				<a href="/location/{$user?.locationId}/servicemessages">
					<ServiceMessageWidget
						offset={0}
						limit={3}
						unresolvedOnly={true} />
				</a>
			{/if}

			<Topbar small title="Content updates" />
			<Table
				rows={$logs.otherLogs.records}
				count={$logs.otherLogs.count}
				columns={columns}
				routing={false}
				bind:limit={limit2}
				bind:offset={offset2} />
		</div>
	{/if}
</Page>

<style>
	.cardcontainer {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
		grid-gap: 1rem;
		margin-bottom: 1rem;
	}
	.card {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 15px;
		border-radius: 5px;
		font-weight: bold;
		font-size: 16px;
	}
	.filled {
		background: #000096;
		color: white;
	}
	.bordered {
		background: white;
		color: #000096;
		border: 1px solid;
	}
	.cardcount {
		font-size: 50px;
		margin-left: 10px;
		margin-top: -12px;
	}
	a:link {
		text-decoration: inherit;
		color: inherit;
	}

	a:visited {
		text-decoration: inherit;
		color: inherit;
	}
</style>
