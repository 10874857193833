<script>
	import {createEventDispatcher} from 'svelte';

	import Dialog from '../Dialog.svelte';

	import {globalItems} from '../../stores/globalItems';

	let error = '';
	let close;
	export let item;
	export let open;
	const dispatch = createEventDispatcher();

	const onSubmit = async () => {
		try {
			if (item.id) {
				await globalItems.change(item);
			} else {
				await globalItems.add(item);
			}

			item = null;
			error = '';
			dispatch('submit');
			close();
		} catch (e) {
			error = e.error ? e.error : e;
		}
	};

	$: item = item || {
		name: '',
		description: '',
		thumbnailUrl: null,
		files: null,
	};
</script>

<form on:submit|preventDefault={onSubmit}>
	<Dialog title="Add item" canScroll bind:open bind:close bind:error>
		<label>
			Name
			<input type="text" bind:value={item.name} />
		</label>
		<label>
			Description
			<input type="text" bind:value={item.description} />
		</label>
		{#if item.thumbnailUrl}
			<img class="thumb" src={item.thumbnailUrl} alt="thumbnail" />
		{/if}
		<label>
			Thumbnail
			<input
				accept="image/*"
				type="file"
				class={!item.imageUrl && 'fileupload'}
				bind:files={item.files} />
		</label>
	</Dialog>
</form>
