<script>
	import {createEventDispatcher} from 'svelte';
	import Dialog from '../Dialog.svelte';
	import {postUserGroup, updateUserGroup} from '../../api/usergroups';

	const dispatch = createEventDispatcher();

	export let error = '';
	export let open;
	let close;
	export let onUpdate = () => {};

	export let usergroup;

	$: usergroup = usergroup || {
		id: undefined,
		name: '',
	};

	const onSubmit = async () => {
		try {
			if (usergroup.id) {
				await updateUserGroup(usergroup);
			} else {
				await postUserGroup(usergroup);
			}
			usergroup = null;

			dispatch('submit');
			onUpdate();
			close();
		} catch (e) {
			error = e.error ? e.error : e;
		}
	};
</script>

<form on:submit|preventDefault={onSubmit}>
	<Dialog
		title={usergroup.id ? 'Edit user group' : 'Add user group'}
		bind:open
		bind:close
		bind:error>
		<label>
			Name
			<input type="text" bind:value={usergroup.name} required />
		</label>
	</Dialog>
</form>
