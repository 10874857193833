<script>
	import Dialog from '../Dialog.svelte';

	import {copyUsers} from '../../api/users';
	import {shortLocations} from '../../stores/short/locations';
	shortLocations.fetch();

	const onSubmit = async () => {
		try {
			await copyUsers({userIds, locationId});
			close();
		} catch (e) {
			error = e.error ? e.error : e;
		}
	};

	let error = '';
	let locationId;
	let close;
	export let open;
	export let userIds;
</script>

<form on:submit|preventDefault={onSubmit}>
	<Dialog title="Copy users to location" bind:open bind:close bind:error>
		<label>
			Location
			<select bind:value={locationId} label="Location">
				{#each Object.entries($shortLocations) as [locationId, locationName]}
					<option value={Number(locationId)}>{locationName}</option>
				{/each}
			</select>
		</label>
	</Dialog>
</form>
