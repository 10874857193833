<script>
	import {createEventDispatcher, tick} from 'svelte';

	const dispatch = createEventDispatcher();

	export let imageSrc;
	export let clickToFullScreen = false;

	const handleWrapperClick = () => {
		if (clickToFullScreen) {
			toggleFullScreen();
		}
	};

	const toggleFullScreen = () => {
		dispatch('fullscreen');
	};
</script>

<div class="wrap" on:click={handleWrapperClick}>
	{#if imageSrc}
		<img
			class="vidbox"
			src={imageSrc}
			alt="video-thumbnail"
			loading="lazy"
			draggable="false" />
	{:else}
		<div class="vidbox black" />
	{/if}

	<div class="button-container">
		<div
			class="material-icons button button_fullscreen"
			on:click={toggleFullScreen}>
			fullscreen
		</div>
	</div>
</div>

<style>
	.wrap {
		position: relative;
		background-color: black;
		border-radius: 8px;
	}
	.button-container {
		position: absolute;
		background: linear-gradient(0, rgba(60, 60, 60, 0.2), rgba(0, 0, 0, 0));
		border-radius: 8px;
		height: 40px;
		bottom: 0px;
		width: 100%;
		z-index: 100;
	}
	.button {
		position: absolute;
		color: white;
		cursor: pointer;
		bottom: 8px;
	}

	.button_fullscreen {
		right: 4px;
	}
	.vidbox {
		display: block;
		width: 100%;
		height: 100px;
		object-fit: cover;
		border-radius: 8px;
	}
	.black {
		background-color: black;
	}
</style>
