import {BaseApi} from './api';

export const deleteRole = async (roleId) => {
	return BaseApi.delete('/role/' + roleId);
};

export const getRoles = async () => {
	return BaseApi.get('/roles');
};

export const getRolesShort = async () => {
	return BaseApi.get('/roles?short=true');
};

export const postRole = async (role) => {
	return BaseApi.post('/role', {
		name: role.name,
		permissions: role.permissions,
	});
};

export const updateRole = async (role) => {
	return BaseApi.update('/role/' + role.id, {
		name: role.name,
		permissions: role.permissions,
	});
};
