<script>
	import {router} from 'tinro';
	import {fly} from 'svelte/transition';

	import EnvironmentBanner from '../components/EnvironmentBanner.svelte';
	import RegisterForm from '../components/RegisterForm.svelte';
	import LoginForm from '../components/LoginForm.svelte';

	export let registerFirst = false;

	let isRegister = Boolean(registerFirst);
	const toggleIsRegister = () => {
		isRegister = !isRegister;

		let query = '';
		if ($router.query.invite) {
			query = `?invite=${$router.query.invite}`;
		}

		window.history.replaceState(
			{},
			'',
			(isRegister ? '/register' : '/login') + query
		);
	};

	const onRegister = () => {};
	const onLogin = () => {};

	$: loginHref = $router.query.invite
		? `/login?invite=${$router.query.invite}`
		: '/login';
</script>

<EnvironmentBanner />
<header class="login-header">
	<a href={loginHref}>
		<img src="logo-blue.svg" alt="Unifractal" class="header-logo" />
	</a>
</header>
<div class="login-page">
	<div class="login-form" class:reversed={!isRegister}>
		{#if !isRegister}
			<div class="alt-action" in:fly={{x: -400, opacity: 1}}>
				<h2><b>Get started</b></h2>
				<p>
					Get started by registering an account and downloading the
					app
				</p>
				<button
					class="login-button alt-button"
					on:click={toggleIsRegister}>
					Sign up
				</button>
			</div>
		{/if}
		<div class="login-content">
			{#if isRegister}
				<div in:fly={{x: 200}}>
					<h1>Register an account</h1>
					<RegisterForm onRegister={onRegister} />
				</div>
			{:else}
				<div in:fly={{x: -200}}>
					<h1>Sign in to Unifractal</h1>
					<LoginForm onLogin={onLogin} />
					<br />
				</div>
			{/if}
		</div>
		{#if isRegister}
			<div class="alt-action" in:fly={{x: 400, opacity: 1}}>
				<h2><b>Welcome back!</b></h2>
				<p>
					Sign in to continue refining the learning experience in your
					location
				</p>
				<button
					class="login-button alt-button"
					on:click={toggleIsRegister}>
					Sign in
				</button>
			</div>
		{/if}
	</div>
</div>

<style>
	.login-page {
		background: #f7f7f7;
		min-height: calc(100vh - 80px);
	}
	.login-form {
		overflow: hidden;
		display: flex;
		background-color: white;
		border-radius: 20px;
		width: 800px;
		max-width: calc(100% - 40px);
		min-height: 600px;
	}

	.login-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		box-sizing: border-box;
		padding: 40px;
		width: 450px;
		flex-shrink: 0;
	}
	.login-content h1 {
		color: #0000ff;
	}

	.login-button {
		min-width: 200px;
		padding: 15px 30px;
		border-radius: 25px;
		border: none;
		transition: all 0.12s;
	}
	.alt-action {
		position: relative;
		z-index: 2;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 40px;
		background-color: #0000ff;
		color: white;
		border-radius: 20px;
	}
	.alt-button {
		background: #0000ff;
		background: transparent;
		color: white;
		border: solid 1px white;
	}
	.alt-button:hover {
		color: #0000ff;
		background-color: white;
		cursor: pointer;
	}

	.login-form h1 {
		margin-top: 0;
	}

	.header-logo {
		padding-left: 20px;
	}

	@media screen and (max-width: 800px) {
		.login-page {
			padding-bottom: 80px;
		}
		.login-form {
			flex-direction: column;
		}
		.login-content {
			width: 100%;
		}
		.login-form.reversed {
			flex-direction: column-reverse;
		}
	}
</style>
