<script>
	import Crumbs from '../components/Crumbs.svelte';
	import Topbar from '../components/Topbar.svelte';

	export let title;
	export let description = null;
	export let crumbs = null;
</script>

<div class="page">
	{#if crumbs}
		<Crumbs crumbs={crumbs} />
	{/if}
	<Topbar title={title} description={description}>
		<slot name="toolbar" />
	</Topbar>
	<slot />
</div>

<style>
	.page {
		box-sizing: border-box;
		padding: 24px 48px;
		width: 100%;
	}
	@media (max-width: 600px) {
		.page {
			padding: 10px 20px;
			width: 100%;
		}
	}
</style>
