import {writable} from 'svelte/store';

import {
	getLocations,
	updateLocation,
	postLocation,
	deleteLocation,
} from '../api/locations';

function createLocations() {
	const {subscribe, set, update} = writable({count: 0, records: []});
	const fetchLocations = async (offset, limit, orderBy) => {
		try {
			set(await getLocations(offset, limit, orderBy));
		} catch {
			set({count: 0, records: []});
		}
	};
	return {
		subscribe,
		fetch: (offset, limit, orderBy) =>
			fetchLocations(offset, limit, orderBy),
		add: async (location) => {
			return await postLocation(location);
		},
		change: async (location) => {
			await updateLocation(location);
		},
		delete: async (locationId) => {
			await deleteLocation(locationId);
			update((arr) => ({
				count: arr.count,
				records: arr.records.filter(
					(location) => location.id !== locationId
				),
			}));
		},
	};
}

export const locations = createLocations();
