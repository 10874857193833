import {writable} from 'svelte/store';

import {deleteModel, getModels, postModel, updateModel} from '../api/models';

function createModels() {
	const {subscribe, set, update} = writable();

	return {
		subscribe,
		fetch: async (offset, limit, order) => {
			return set(await getModels(offset, limit, order));
		},
		add: async (model) => {
			await postModel(model);
		},
		change: async (model) => {
			await updateModel(model);
		},
		delete: async (id) => {
			await deleteModel(id);
			update((arr) => ({
				count: arr.count,
				records: arr.records.filter((model) => model.id !== id),
			}));
		},
	};
}

export const models = createModels();
