<div class="wrapper">
	<div class="box"><img class="image-404" src="/no-videos.png" alt="" /></div>
	<div class="content">
		<div class="flex">
			<h1 class="page-title">404</h1>
			<p class="page-subtitle">Page not found</p>
		</div>
		<p>
			We could not find the page you're looking for. If you believe this
			to be an error, let us know at
			<a class="link" href="mailto:contact@unifractal.com"
				>contact@unifractal.com</a
			>.
		</p>
		<p>
			You can also try to
			<a class="link" href="/login">sign in</a>
			or browse using the menu on the left
		</p>
	</div>
</div>

<style>
	/*test*/
	.wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 1;
		min-height: 70vh;
		font-size: 20px;
	}

	.box {
		background-color: #ff8a40;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		color: white;
		padding: 40px;
		padding-left: 0;
		padding-right: 140px;
		border-radius: 10px;
	}

	.content {
		border-radius: 10px;
		max-width: 350px;
		margin-left: -160px;
		padding: 40px;
		color: #fff;
		background-color: #18212c;
	}

	.flex {
		display: flex;
	}

	.page-title {
		margin: 0;
		font-size: 60px;
	}
	.page-subtitle {
		margin: 0;
		margin-top: 5px;
		font-size: 25px;
	}

	.image-404 {
		width: 90%;
		max-width: 500px;
		height: auto;
		display: block;
	}

	@media screen and (max-width: 1400px) {
		.image-404 {
			max-width: 300px;
		}
		.wrapper {
			font-size: 16px;
		}

		.content {
			margin-left: -80px;
		}

		.box {
			padding-right: 60px;
		}

		.page-title {
			font-size: 35px;
		}
		.page-subtitle {
			font-size: 20px;
			margin-left: 5px;
		}
	}
	@media screen and (max-width: 1100px) {
		.wrapper {
			flex-direction: column;
		}

		.image-404 {
			max-width: 300px;
		}
		.box {
			padding: 60px;
			padding-top: 40px;
		}
		.content {
			padding: 30px;
			margin-top: -85px;
			margin-left: 0;
			max-width: 300px;
		}
	}
	@media screen and (max-width: 500px) {
		.wrapper {
			justify-content: flex-start;
			align-items: center;
		}
		.box {
			width: 100%;
			padding: 20px;
			border-radius: 0;
		}

		.content {
			box-sizing: border-box;
			max-width: initial;
			display: flex;
			justify-content: center;
			flex-direction: column;
			padding: 20px;
			flex: 1;
			width: 100%;
			border-radius: 0;
			margin-top: 0;
		}
	}
</style>
