<script>
	import {createEventDispatcher} from 'svelte';
	import Select from 'svelte-select';

	import Dialog from '../Dialog.svelte';
	import WarningLabel from '../WarningLabel.svelte';
	import CategorySelector from '../CategorySelector.svelte';

	import {items} from '../../stores/items';
	import {getItemsForAutoComplete} from '../../api/items';

	const dispatch = createEventDispatcher();

	let error = '';
	let close;
	export let item;
	export let open;
	let selectedItem = null;

	const onSubmit = async () => {
		try {
			if (item.id) {
				await items.change(item);
			} else {
				item.itemId = selectedItem?.id;

				await items.add(item);
			}

			item = null;
			error = '';
			dispatch('submit');
			close();
		} catch (e) {
			error = e.error ? e.error : e;
		}
		onItemClear();
	};

	const onItemSelect = async (event) => {
		if (event.detail) {
			if (event.detail.label === event.detail.value) {
				//Assume this was created now, rather than selected
				const name = event.detail.label;
				item.description = null;
				selectedItem = {id: null, name};
			} else {
				const id = event.detail.value;
				const name = event.detail.label;

				selectedItem = {id, name};

				if (event.detail.description) {
					item.description = event.detail.description;
				}
				if (event.detail.thumbnailUrl) {
					item.thumbnailUrl = event.detail.thumbnailUrl;
					item.awsKey = event.detail.thumbAwsKey;
				}
			}

			item.name = event.detail.label;
		}
	};
	const onItemClear = async () => {
		selectedItem = null;
		item.thumbnailUrl = null;
		item.awsKey = null;
	};

	const loadOptions = async (filterText) => {
		// Put your async code here...
		// For example call an API using filterText as your search params
		// When your API responds resolve your Promise
		const res = await getItemsForAutoComplete(filterText);
		res.forEach((val) => {
			val.label = val.name;
			val.value = val.id;
		});
		return res;
	};

	$: item = item || {
		//categoryId: null,
		name: '',
		description: '',
		itemId: null,
		thumbnailUrl: null,
		access: 'private',
		awsKey: null,
		files: null,
	};
</script>

<form
	autocomplete="off"
	data-lpignore="true"
	on:submit|preventDefault={onSubmit}>
	<Dialog title="Add item" canScroll bind:open bind:close bind:error>
		{#if !selectedItem}
			<label class="selectwrapper">
				Item name
				<Select
					class="selectbox"
					name="item"
					isCreatable
					noOptionsMessage="Start typing"
					loadOptions={loadOptions}
					on:select={onItemSelect} />
			</label>
			<br /><br />
		{:else}
			{#if selectedItem.id}Selected item:{:else}Create item:{/if}<br />
			<b>{selectedItem.name}</b>
			<span class="link" on:click={onItemClear}>Clear</span>
			<br /><br />
			<label>
				Name
				<input type="text" bind:value={item.name} />
			</label>
			<label>
				Description
				<input type="text" bind:value={item.description} />
			</label>
			<!--<label>
			Category
			<CategorySelector bind:value={item.categoryId} />
		</label>-->
			<label>
				Access
				<select bind:value={item.access}>
					<option value="public">Global</option>
					<option value="private">This location only</option>
				</select>
				{#if item.access === 'private'}
					<WarningLabel>
						Note: We recommend setting this to global</WarningLabel>
					<!--<WarningLabel>
						Note: We advise that all items are acessible globally
						unless the item itself is highly specific to this
						location or somehow is classified. <b
							>Videos and documents in this item will still remain
							private regardless of this settings</b>
					</WarningLabel>-->
				{/if}
			</label>

			{#if item.thumbnailUrl && !item.files?.length}
				<img class="thumb" src={item.thumbnailUrl} alt="thumbnail" />
			{/if}
			<label>
				Thumbnail
				<input
					accept="image/*"
					type="file"
					class={!item.imageUrl && 'fileupload'}
					bind:files={item.files} />
			</label>
		{/if}
	</Dialog>
</form>

<style>
	.selectwrapper {
		display: block;
		width: 400px;
		max-width: 100%;
	}
</style>
