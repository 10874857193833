<script>
	import Page from '../components/Page.svelte';
	import Button from '../components/Button.svelte';
	import StoreTable from '../components/StoreTable.svelte';
	import VideoDialog from '../components/dialogs/VideoDialog.svelte';
	import VideoPreviewModal from '../components/modals/VideoPreviewModal.svelte';
	import LinkContentDialog from '../components/dialogs/LinkContentDialog.svelte';

	import {user} from '../stores/user';
	import {videos} from '../stores/videos';

	let openVideoDialog;

	let onUpdate;
	let openVideoPreviewModal;
	let selectedVideo;
	let openLinkContentDialog;

	const onAdd = () => {
		selectedVideo = null;
		openVideoDialog();
	};

	const onEdit = (editVideo) => {
		selectedVideo = editVideo;
		openVideoDialog();
	};

	const onDelete = async (video) => {
		if (
			!confirm(
				`Are are you sure you want to delete ${
					video.title || video.id
				}?`
			)
		) {
			return;
		}
		await videos.delete(video.id);
		onUpdate();
	};

	const onPreview = (video) => {
		selectedVideo = video;
		openVideoPreviewModal();
	};

	const onLink = (video) => {
		selectedVideo = video;
		openLinkContentDialog();
	};

	const columns = [
		{
			title: 'Title',
			value: (video) => video.title,
			href: (video) =>
				`/location/${$user?.locationId}/videos/${video.id}`,
			truncate: true,
			orderKey: 'title',
		},
		{
			title: 'Description',
			value: (video) => video.description,
			truncate: true,
			hide: 'medium',
			orderKey: 'description',
		},
		{
			title: 'Creator',
			value: (video) => video.owner.name,
			truncate: true,
			hide: 'medium',
			orderKey: 'ownerId',
		},
		{
			title: 'In modules (globally)',
			value: (video) => video.modules.length,
			truncate: true,
			hide: 'medium',
			orderKey: 'ownerId',
		},
		{
			title: 'Status',
			orderKey: 'status',
			value: (video) => video.status,
		},
	];

	const actions = [
		{
			title: 'Edit',
			icon: 'edit',
			action: onEdit,
		},
		{
			title: 'Delete',
			icon: 'delete',
			action: onDelete,
		},
		{
			title: 'Preview Video',
			icon: 'preview',
			action: onPreview,
		},
		{
			title: 'Link to module',
			icon: 'add_link',
			action: onLink,
		},
	];
</script>

<Page
	title="Videos"
	description="Upload and manage videos here."
	crumbs={['Videos']}>
	<svelte:fragment slot="toolbar">
		<Button on:click={onAdd}>Add video</Button>
	</svelte:fragment>
	<StoreTable
		store={videos}
		columns={columns}
		actions={actions}
		bind:onUpdate />
	<VideoDialog
		bind:open={openVideoDialog}
		bind:video={selectedVideo}
		on:submit={onUpdate} />
	<VideoPreviewModal
		bind:open={openVideoPreviewModal}
		video={selectedVideo} />
	<LinkContentDialog
		bind:open={openLinkContentDialog}
		videoId={selectedVideo?.id} />
</Page>
