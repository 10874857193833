<script>
	import {createEventDispatcher} from 'svelte';
	const dispatch = createEventDispatcher();

	import Dialog from '../Dialog.svelte';
	import LocationSelector from '../LocationSelector.svelte';

	import {startClassificationRun} from '../../api/classificationRuns.js';

	const onSubmit = async () => {
		try {
			if (locationId) {
				await startClassificationRun(locationId);
			} else {
				throw new Error('Invalid Location');
			}
			dispatch('submit');
			close();
		} catch (e) {
			error = e.error ? e.error : e;
		}
	};

	let error = '';
	let close;
	let locationId;
	export let open;
</script>

<form on:submit|preventDefault={onSubmit}>
	<Dialog
		title="New Classifcation Run"
		submitName="Start"
		bind:open
		bind:close
		bind:error>
		<label>
			Location
			<LocationSelector bind:value={locationId} />
		</label>
	</Dialog>
</form>
