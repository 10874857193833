<script>
	import Dialog from '../Dialog.svelte';

	let videoElement;
	let close;
	export let open;
	export let video = null;

	let visible;

	$: if (!visible) {
		if (videoElement) {
			videoElement.pause();
			videoElement.currentTime = 0;
		}
	}
</script>

<Dialog
	title={video?.title}
	bind:open
	bind:close
	bind:visible
	noCancel
	noSubmit>
	{#if video && video.videoUrl}
		<video
			bind:this={videoElement}
			class="video"
			src={video.videoUrl}
			preload="none"
			autoplay
			controls><track kind="captions" /></video>
	{:else}
		<div class="video">Video processing...</div>
	{/if}
</Dialog>

<style>
	.video {
		display: block;
		outline: none;
		width: 100%;
		height: 500px;
		text-align: center;
		line-height: 250px;
		border-radius: 5px;
		background-color: black;
		color: white;
		font-size: 14px;
		font-weight: bold;
	}
</style>
