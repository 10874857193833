import {BaseApi} from './api';

export const getLocations = async (offset, limit, orderBy) => {
	return BaseApi.get(
		`/locations?offset=${offset}&limit=${limit}&orderBy=${orderBy}`
	);
};

export const getLocationsShort = async () => {
	let res;
	try {
		res = BaseApi.get(`/locations?short=true`);
	} catch (e) {
		console.log(e);
	}
	return res;
};

export const getLocation = async (id) => {
	return BaseApi.get(`/location/${id}`);
};

export const getLocationRoot = async (id) => {
	return BaseApi.get(`/location/${id}/root`);
};

export const postLocation = async (location) => {
	const data = new FormData(); //Use formdata cause we're potentially sending files
	if (location.name) {
		data.append('name', location.name);
	}
	if (location.description) {
		data.append('description', location.description);
	}
	if (location.latitude) {
		data.append('latitude', location.latitude);
	}
	if (location.longitude) {
		data.append('longitude', location.longitude);
	}
	if (location.imageId) {
		data.append('imageId', location.imageId);
	}
	if (location.uploadImage) {
		data.append('image', location.uploadImage);
	}

	return BaseApi.post('/location', data);
};

export const updateLocation = async (location) => {
	const data = new FormData(); //Use formdata cause we're potentially sending files
	if (location.name) {
		data.append('name', location.name);
	}
	if (location.description) {
		data.append('description', location.description);
	}
	if (location.latitude) {
		data.append('latitude', location.latitude);
	}
	if (location.longitude) {
		data.append('longitude', location.longitude);
	}
	if (location.modelId || location.modelId === null) {
		data.append('modelId', location.modelId);
	}
	if (location.imageId) {
		data.append('imageId', location.imageId);
	}
	if (location.uploadImage) {
		data.append('image', location.uploadImage);
	}
	if (location.hasOwnProperty('displayMode')) {
		data.append('displayMode', location.displayMode);
	}
	if (location.hasOwnProperty('displayDirection')) {
		data.append('displayDirection', location.displayDirection);
	}
	if (location.hasOwnProperty('requireRegistration')) {
		data.append('requireRegistration', location.requireRegistration);
	}
	if (location.hasOwnProperty('isPremium')) {
		data.append('isPremium', location.isPremium);
	}

	return BaseApi.update('/location/' + location.id, data);
};

export const deleteLocation = async (locationId) => {
	return BaseApi.delete(`/location/${locationId}`);
};
