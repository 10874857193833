import {BaseApi} from './api';
const PLATFORM_DASHBOARD = 1;

export const register = async (user) => {
	return BaseApi.post('/register', user);
};

export const login = async (user) => {
	return BaseApi.post(
		'/login',
		{
			email: user.email,
			password: user.password,
			method: 0,
			locationId: user.locationId,
		},
		{withCredentials: true}
	);
};

export const refreshToken = async () => {
	return BaseApi.get('/token', {withCredentials: true});
};

export const logout = async () => {
	return BaseApi.post('/token/logout', {}, {withCredentials: true});
};

export const loginWithGoogle = async ({idToken, locationId}) => {
	return BaseApi.post('/login', {
		idToken,
		method: 4,
		locationId,
	});
};

export const setLocation = async (locationId) => {
	return await BaseApi.post(`/setLocation/${locationId}`, {
		platform: PLATFORM_DASHBOARD,
	});
};

export const forgotPassword = async (user) => {
	return BaseApi.post('/forgotpassword', {
		email: user.email,
	});
};

export const resetPassword = async (user) => {
	return BaseApi.post('/resetpassword', {
		token: user.token,
		password: user.password,
	});
};

export const postForgotPassword = async (user) => {
	return BaseApi.post(`/forgotpassword`, {email: user.email});
};

export const refreshLogin = async () => {
	return BaseApi.get('/refresh');
};
