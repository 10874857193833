import {BaseApi, LocationApi} from './api';

export const deleteItem = async (itemId) => {
	return LocationApi.delete(`/modules/${itemId}`);
};

export const getAllItems = async (offset, limit, orderBy) => {
	return BaseApi.get(
		`/items?offset=${offset}&limit=${limit}&orderBy=${orderBy}`
	);
};

export const getItemsForAutoComplete = async (searchQuery) => {
	return BaseApi.get(`/items/search?q=${searchQuery}`);
};

export const getGlobalItem = async (id) => {
	return BaseApi.get(`/items/${id}`);
};

export const deleteGlobalItem = async (id) => {
	return BaseApi.delete(`/items/${id}`);
};

export const updateGlobalItem = async (item) => {
	const data = new FormData();

	if (item.name) {
		data.append('name', item.name);
	}
	if (item.description) {
		data.append('description', item.description);
	}
	if (item.tags) {
		item.tags.map((tag) => data.append('tags', tag));
	}
	if (item.hideFromHome) {
		data.append('hideFromHome', item.hideFromHome);
	}
	if (item.files) {
		data.append('thumbnail', item.files[0]);
	}

	return BaseApi.update(`/items/${item.id}`, data);
};
export const postGlobalItem = async (item) => {
	const data = new FormData();

	if (item.name) {
		data.append('name', item.name);
	}
	if (item.description) {
		data.append('description', item.description);
	}
	if (item.access) {
		data.append('access', item.access);
	}
	if (item.hideFromHome) {
		data.append('hideFromHome', item.hideFromHome);
	}
	if (item.files) {
		data.append('thumbnail', item.files[0]);
	}

	return BaseApi.post('/items', data);
};

export const getShortItems = async () => {
	return LocationApi.get('/items?short=true');
};

export const getItem = async (id) => {
	return LocationApi.get(`/modules/${id}?type=item`);
};

export const getItems = async (offset, limit, orderBy) => {
	return LocationApi.get(
		`/modules?type=item&offset=${offset}&limit=${limit}&orderBy=${orderBy}`
	);
};

export const getPublic = async () => {
	return LocationApi.get(`/items?pub=true`);
};

export const addFromLibrary = async (itemId) => {
	return LocationApi.post(`/item/relate`, {itemId});
};

export const postItemModule = async (item) => {
	const data = new FormData();

	if (item.name) {
		data.append('name', item.name);
	}
	if (item.description) {
		data.append('description', item.description);
	}
	if (item.orderWeight) {
		data.append('orderWeight', item.orderWeight);
	}
	if (item.access) {
		data.append('access', item.access);
	}
	if (item.itemId) {
		data.append('itemId', item.itemId);
	}
	if (item.files) {
		data.append('thumbnail', item.files[0]);
	}
	if (item.awsKey) {
		data.append('awsKey', item.awsKey);
	}
	if (item.hasOwnProperty('hideFromHome')) {
		data.append('hideFromHome', item.hideFromHome);
	}
	data.append('type', 'item');

	return LocationApi.post('/module', data);
};

/*
export const postItemImage = async (itemId, files) => {
	const data = new FormData();

	if (itemId) {
		data.append('itemId', itemId);
	}

	if (files) {
		data.append('img', files[0]);
	}

	return mediaPost('/itemimage', data);
};*/

export const updateItem = async (item) => {
	const data = new FormData();

	if (item.name) {
		data.append('name', item.name);
	}
	if (item.description) {
		data.append('description', item.description);
	}
	if (item.orderWeight) {
		data.append('orderWeight', item.orderWeight);
	}
	if (item.tags) {
		item.tags.map((tag) => data.append('tags', tag));
	}
	if (item.access) {
		data.append('access', item.access);
	}
	if (item.hasOwnProperty('hideFromHome')) {
		data.append('hideFromHome', item.hideFromHome);
	}
	if (item.files) {
		data.append('thumbnail', item.files[0]);
	}

	return LocationApi.update(`/modules/${item.id}`, data);
};

export const gettags = async () => {
	return LocationApi.get('/tags');
};
