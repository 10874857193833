import {BaseApi, LocationApi} from './api';

export const getInvites = async (offset, limit) => {
	return await LocationApi.get(
		`/users/invited?offset=${offset}&limit=${limit}`
	);
};
export const deleteInvite = async (inviteId) => {
	return await LocationApi.delete(`/users/invite/${inviteId}`);
};

export const sendInvite = async (
	email,
	roleId,
	userGroups,
	customEmailTemplate
) => {
	const payload = {
		email,
		roleId,
		userGroups,
	};

	if (customEmailTemplate && customEmailTemplate !== '') {
		payload.customEmailTemplate = customEmailTemplate;
	}

	return await LocationApi.post('/users/invite', payload);
};

export const getInvite = async (key) => {
	return await BaseApi.get(`/invite/${key}`);
};

export const acceptInvite = async (key) => {
	return await BaseApi.post(`/invite/accept`, {key});
};
export const declineInvite = async (key) => {
	return await BaseApi.post(`/invite/decline`, {key});
};
