import {writable} from 'svelte/store';

import {getShortUserGroups} from '../../api/usergroups';

function createUserGroups() {
	const {subscribe, set} = writable({});
	return {
		subscribe,
		fetch: async () => {
			const usergroups = await getShortUserGroups();
			const result = usergroups.reduce((map, obj) => {
				map[obj.id] = obj.name;
				return map;
			}, {});
			return set(result);
		},
	};
}

export const shortUserGroups = createUserGroups();
