<script>
	import Button from '../components/Button.svelte';
	import {forgotPassword} from '../api/authentication';
	import EnvironmentBanner from '../components/EnvironmentBanner.svelte';

	let email = '';
	let error = '';
	let sent = false;

	const onSubmit = async () => {
		try {
			await forgotPassword({email});
			sent = true;
		} catch (e) {
			error = e.error;
		}
	};
</script>

<EnvironmentBanner />
<header class="login-header">
	<a href="/login">
		<img src="logo-white.svg" alt="Unifractal" class="header-logo" />
	</a>
</header>
{#if sent}
	<div class="login-page">
		<div class="login-form">
			<h1>Password reset email sent!</h1>
			<br />
			<a class="link" href="/login">Click here to return to login</a>
			<br />
		</div>
	</div>
{:else}
	<div class="login-page">
		<div class="login-form">
			<h1>Forgot Password</h1>
			<form on:submit|preventDefault={onSubmit}>
				<div>
					<label>
						{'Email'}
						<input type="email" bind:value={email} />
					</label>
					<a class="link" href="/login"
						>Already know your password?</a>
					<br />
					<div class="errortext">{error}</div>
					<br />
					<Button submit>Send</Button>
				</div>
			</form>
		</div>
	</div>
{/if}

<style>
	.header-logo {
		padding-left: 20px;
	}
</style>
