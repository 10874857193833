<script>
	import {router} from 'tinro';

	import {login, register} from '../api/authentication';
	import {token} from '../stores/token';
	import {user} from '../stores/user';
	import {localstore} from '../stores/localstore';

	import {getBestLocationId} from '../utils/tokenUtils';
	import getSafeUrl from '../utils/getSafeUrl';

	export let onRegister;

	let agrees = false;
	let email = '';
	let password = '';
	let error = '';
	let name;
	let code;

	const inviteCode = $router.query.invite;

	if ($token) {
		if (
			new Date($user?.exp).valueOf() <
			Math.floor(new Date().valueOf() / 1000)
		) {
			$token = null;
		} else if ($router.query.invite) {
			const code = $router.query.invite;
			router.goto(`/invite/?code=${code}`);
		} else {
			const locationId = $localstore?.locationId
				? $localstore.locationId
				: getBestLocationId($user);

			const url = getSafeUrl(
				locationId,
				$user?.locationPermissions[locationId],
				$user?.admin
			);

			router.goto(url);
		}
	}

	const onSubmit = async () => {
		if (!agrees) {
			error = 'You must agree to the Terms of Service and Privacy Policy';
			return;
		}

		let registerData = {
			email,
			name,
			password,
		};
		if ($router.query.token) {
			registerData.token = $router.query.token;
		} else if (code) {
			registerData.key = code;
		}

		try {
			await register(registerData);
			const authResponse = await login({email, password});
			$token = authResponse.token;
		} catch (e) {
			console.error(e);
			if (e?.error) {
				error = e.error;

				if (error === 'Missing required parameter: name') {
					error = 'Missing field: Name';
				}
			} else {
				error = 'Could not register';
			}
			return; //Return here so that the redirect code doesn't run on error
		}

		let url;
		if ($router.query.invite) {
			const code = $router.query.invite;
			url = `/invite/?code=${code}`;
		} else {
			const locationId = getBestLocationId($user);

			url = getSafeUrl(
				locationId,
				$user?.locationPermissions[locationId],
				$user?.admin
			);
			$localstore = {locationId, email};
		}

		router.goto(url);
		onRegister();
	};
</script>

<form on:submit|preventDefault={onSubmit}>
	<label>
		Email
		<input type="email" name="email" bind:value={email} />
	</label>

	<label>
		Name
		<input type="text" name="name" bind:value={name} />
	</label>

	<label>
		Password
		<input type="password" name="password" bind:value={password} />
	</label>
	<br /><br />
	{#if !($router.query.token || inviteCode)}
		<label>
			Location code (Optional)
			<input type="text" name="location code" bind:value={code} />
		</label>
	{/if}
	<label>
		<input type="checkbox" bind:checked={agrees} />
		I agree to the
		<a href="https://unifractal.com/tos">Terms of Service</a>
		and
		<a href="https://unifractal.com/privacy">Privacy Policy</a>
	</label>
	<div class="errortext">{error}</div>
	<br />
	<br />
	<button class="login-button main-button" type="submit"> Register </button>
</form>

<style>
	label a {
		color: #0000ff;
		text-decoration: none;
	}

	.main-button {
		background: #0000ff;
		color: white;
		border: none;
	}
	.main-button:hover {
		background-color: #000096;
		cursor: pointer;
	}

	.login-button {
		min-width: 200px;
		padding: 15px 30px;
		border-radius: 25px;
		transition: all 0.12s;
	}

	.errortext {
		color: red;
	}
</style>
