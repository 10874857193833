import {LocationApi} from './api';

export const getUserGroups = async (offset, limit, orderBy) => {
	return LocationApi.get(
		`/usergroups?offset=${offset}&limit=${limit}&orderBy=${orderBy}`
	);
};
export const getShortUserGroups = async () => {
	return LocationApi.get(`/usergroups?short=true`);
};

export const getUserGroup = async (groupId) => {
	return LocationApi.get(`/usergroup/${groupId}`);
};

export const postUserGroup = async ({name}) => {
	return LocationApi.post('/usergroup', {
		name,
	});
};

export const updateUserGroup = async ({name, id}) => {
	return LocationApi.update(`/usergroup/${id}`, {
		name,
	});
};

export const deleteUserGroup = async (id) => {
	return LocationApi.delete(`/usergroup/${id}`);
};

export const postAddUserToGroup = async (groupId, userId) => {
	return LocationApi.post(`/usergroup/${groupId}/user/${userId}`);
};

export const removeUserFromGroup = async (groupId, userId) => {
	return LocationApi.delete(`/usergroup/${groupId}/user/${userId}`);
};
