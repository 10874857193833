<script>
	import {createEventDispatcher} from 'svelte';

	import Dialog from '../Dialog.svelte';
	import {postLink, updateLink} from '../../api/links';

	const dispatch = createEventDispatcher();

	let close;
	export let open;
	export let link;

	export let onUpdate = () => {};
	export let moduleId;

	$: link = link || {
		id: undefined,
		url: '',
		name: '',
	};

	const clearFields = () => {
		link = {url: '', name: ''};
	};

	const onSubmit = async () => {
		if (link.id) {
			await updateLink(link);
		} else {
			await postLink(link, moduleId);
		}
		onUpdate();
		clearFields();
		close();

		dispatch('submit');
	};
</script>

<form on:submit|preventDefault={onSubmit}>
	<Dialog title={link.id ? 'Edit Link' : 'Add link'} bind:open bind:close>
		<label>
			Name
			<input
				type="text"
				bind:value={link.name}
				placeholder="Unifractal home page"
				required />
		</label>
		<label>
			Link destination
			<input
				type="text"
				bind:value={link.url}
				placeholder="https://unifractal.com"
				required />
		</label>
	</Dialog>
</form>

<style>
</style>
