<script>
	export let icon;
	export let title;
	export let content;
	export let afterThis;
	export let href;
	export let pinned;

	export let onMouseDown;
	export let onDelete;
	export let onUnlink;
	export let onEdit;
	export let onPin;
	export let onUnpin;

	export let onTitleClick = () => {};

	export let expanded = false;
	const toggleExpanded = () => {
		expanded = !expanded;
	};
</script>

<div class="widget" class:afterThis>
	<div class="header">
		<span>
			<div
				class="material-icons icon-wrapper"
				on:mousedown={(e) => onMouseDown(e, content)}>
				drag_handle
			</div>
			<h3
				on:click={() => {
					onTitleClick();
					toggleExpanded();
				}}>
				<div class="material-icons icon-wrapper">{icon}</div>
				{#if href}
					<a href={href}>{title}</a>
				{:else}
					{title}
				{/if}
			</h3>
		</span>
		<div>
			{#if onEdit}
				<div
					class="icon-button material-icons"
					title="Delete from learning module"
					on:click={onEdit}>
					edit
				</div>
			{/if}
			{#if onDelete}
				<div
					class="icon-button material-icons"
					title="Delete from learning module"
					on:click={onDelete}>
					delete
				</div>
			{/if}
			{#if onPin && onUnpin}
				{#if pinned}
					<div
						class="icon-button material-icons"
						title="Unpin"
						on:click={onUnpin}>
						push_pin
					</div>
				{:else}
					<div
						class="icon-button material-icons rotate90"
						title="Pin"
						on:click={onPin}>
						push_pin
					</div>
				{/if}
			{/if}
			{#if onUnlink}
				<div
					class="icon-button material-icons"
					title="Remove from learning module"
					on:click={onUnlink}>
					link_off
				</div>
			{/if}
		</div>
	</div>
	{#if expanded}
		<slot name="expanded" />
	{/if}
</div>

<style>
	.rotate90 {
		transform: rotate(90deg);
	}
	.widget {
		box-sizing: border-box;
		margin-bottom: 20px;
		padding-bottom: 20px;
		border-bottom: solid 1px #ccc;
		background: white;
	}
	.afterThis {
		margin-bottom: 100px;
	}
	.widget h3 {
		margin-top: 5px;
		vertical-align: middle;
		margin-bottom: 5px;
		display: inline-block;
	}
	.widget h3:hover {
		cursor: pointer;
	}
	.icon-wrapper {
		vertical-align: middle;
	}
	.icon-wrapper:hover {
		cursor: pointer;
	}
	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
</style>
