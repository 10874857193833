import {LocationApi, BaseApi} from './api';

export const reInviteUser = async (user) => {
	return LocationApi.post(`/user/reinvite`, {
		email: user.email,
	});
};

export const deleteUser = async (userId) => {
	return LocationApi.delete('/user/' + userId);
};

export const getUsers = async (offset, limit, order) => {
	return LocationApi.get(
		`/users?offset=${offset}&limit=${limit}&orderBy=${order}`
	);
};
export const getUserInLocation = async (userId) => {
	return LocationApi.get(`/users/${userId}`);
};

export const getUsersShort = async () => {
	return LocationApi.get('/users?short=true');
};

export const copyUser = async (user) => {
	return LocationApi.post('/user/copy', {
		userId: user.id,
		locationId: user.locationId,
	});
};

export const copyUsers = async (data) => {
	return LocationApi.post('/user/copy', {
		userIds: data.userIds,
		locationId: data.locationId,
	});
};

export const moveUser = async (user) => {
	return LocationApi.post('/user/move', {
		userId: user.id,
		locationId: user.locationId,
	});
};

export const updateUser = async (user) => {
	return LocationApi.update('/user/' + user.id, {
		name: user.name,
		email: user.email,
		roleId: user.roleId,
		userGroups: user.userGroups,
	});
};

export const updateUserRole = async (item) => {
	return LocationApi.post('/userroles/update', item);
};

export const createServiceAccount = async (name) => {
	return LocationApi.post('/user/serviceaccount', {name});
};

//ME:

export const deleteOwnRole = async (roleId, locationId) => {
	return BaseApi.delete(`/user/me/role/${roleId}`, {locationId});
};

export const linkMyGoogleAuth = async ({idToken}) => {
	return BaseApi.post('/user/me/googleauth', {idToken});
};

export const unlinkMyGoogleAuth = async () => {
	return BaseApi.delete('/user/me/googleauth');
};

export const updateSelf = async (data) => {
	return BaseApi.update('/user/me', data);
};

export const deleteSelf = async () => {
	return BaseApi.delete('/user/me');
};

export const getSelf = async () => {
	return BaseApi.get(`/user/me`);
};
export const getSelfNotificationPreferences = async () => {
	return BaseApi.get(`/user/me/preferences/notifications`);
};
export const updateSelfNotificationPreferences = async (preferences) => {
	return BaseApi.update(`/user/me/preferences/notifications`, {preferences});
};
