import {writable} from 'svelte/store';

import {getDataset, updateDataset, deleteDataset} from '../api/datasets';

function createDataset() {
	const {subscribe, set} = writable();
	const fetchDataset = async (id) => set(await getDataset(id));

	return {
		subscribe,
		fetch: (id) => fetchDataset(id),
		change: async (dataset) => {
			await updateDataset(dataset);
			fetchDataset(dataset.id);
		},
		delete: async (id) => {
			await deleteDataset(id);
			set(null);
		},
	};
}

export const dataset = createDataset();
