import {writable} from 'svelte/store';

import {getLogs, getAllLogs} from '../api/logs';

function createLogs() {
	const {subscribe, set} = writable(null);
	const fetchLogs = async (offset1, limit1, offset2, limit2) => {
		const loginLogs = await getLogs(offset1, limit1, 'login');
		const otherLogs = await getLogs(offset2, limit2, 'other');

		set({loginLogs, otherLogs});
	};

	return {
		subscribe,
		fetch: (offset1, limit1, offset2, limit2) =>
			fetchLogs(offset1, limit1, offset2, limit2),
	};
}

export const logs = createLogs();

function createAllLogs() {
	const {subscribe, set} = writable(null);
	const fetchLogs = async (offset1, limit1, offset2, limit2) => {
		const loginLogs = await getAllLogs(offset1, limit1, 'login');
		const otherLogs = await getAllLogs(offset2, limit2, 'other');

		set({loginLogs, otherLogs});
	};

	return {
		subscribe,
		fetch: (offset1, limit1, offset2, limit2) =>
			fetchLogs(offset1, limit1, offset2, limit2),
	};
}

export const allLogs = createAllLogs();
