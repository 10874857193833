<script>
	import {Route, router} from 'tinro';

	// Authentication
	import LoginRegisterPage from './pages/LoginRegister.svelte';
	import ResetPasswordPage from './pages/ResetPassword.svelte';
	import ForgotPasswordPage from './pages/ForgotPassword.svelte';

	// Managers
	import RunManager from './pages/RunManager.svelte';
	import TagManager from './pages/TagManager.svelte';
	import ItemManager from './pages/ItemManager.svelte';
	import UserManager from './pages/UserManager.svelte';
	import UserGroupManager from './pages/UserGroupManager.svelte';
	import RoleManager from './pages/RoleManager.svelte';
	import ImageManager from './pages/ImageManager.svelte';
	import ModelManager from './pages/ModelManager.svelte';
	import DatasetManager from './pages/DatasetManager.svelte';
	import ServiceManager from './pages/ServiceManager.svelte';
	import LocationManager from './pages/LocationManager.svelte';
	import CategoryManager from './pages/CategoryManager.svelte';
	import ModuleManager from './pages/ModuleManager.svelte';
	import VideoManager from './pages/VideoManager.svelte';
	import ClassificationRunManager from './pages/ClassificationRunManager.svelte';
	import DocumentManager from './pages/DocumentManager.svelte';
	import TextboxManager from './pages/TextboxManager.svelte';
	import GlobalItemManager from './pages/GlobalItemManager.svelte';
	import ServiceMessageManager from './pages/ServiceMessageManager.svelte';
	import ContentManager from './pages/ContentManager.svelte';

	// Single element pages
	import TagPage from './pages/Tag.svelte';
	import ItemPage from './pages/Item.svelte';
	import DatasetPage from './pages/Dataset.svelte';
	import Module from './pages/Module.svelte';
	import VideoPage from './pages/Video.svelte';
	import UserPage from './pages/User.svelte';
	import GlobalItem from './pages/GlobalItem.svelte';

	// Other pages
	import CreateLocation from './pages/CreateLocation.svelte';
	import Profile from './pages/Profile.svelte';
	import Default from './pages/Default.svelte';
	import Overview from './pages/Overview.svelte';
	import Annotator from './pages/Annotator.svelte';
	import LocationSettings from './pages/LocationSettings.svelte';
	import GlobalOverview from './pages/GlobalOverview.svelte';

	//Error pages
	import NotFound from './pages/error/NotFound.svelte';

	import Appbar from './components/Appbar.svelte';

	import {user} from './stores/user';

	import {
		checkPerms,
		checkGlobalPerms,
		PERMISSIONS as p,
	} from './permissions';
	import InviteResponse from './pages/InviteResponse.svelte';
	import {setLocation} from './api/authentication';

	//If you're inside a location route, check if your path matches the saved location, then call setLocation if not
	const regex = /(?:\/location\/)(\d+)/;
	const matches = $router.path.match(regex);
	if (matches) {
		let nowLocationId = Number(matches[1]);
		let savedLocationId = $user.locationId;

		if (nowLocationId !== savedLocationId) setLocation(nowLocationId);
	}

	/* eslint-disable */
	//Disables cause these are produced in build scripts
	const buildType = myapp.env.BUILD_TYPE;
	/* eslint-enable */
</script>

<svelte:head>
	{#if buildType === 'STAGING'}
		<title>(Staging) Unifractal Dashboard</title>
	{:else if buildType !== 'PRODUCTION'}
		<title>(Local) Unifractal Dashboard</title>
	{/if}
</svelte:head>

<Route path="/*" firstmatch>
	<Route path="/forgot-password">
		<ForgotPasswordPage />
	</Route>
	<Route path="/reset-password">
		<ResetPasswordPage />
	</Route>
	<Route path="/register">
		<LoginRegisterPage registerFirst />
	</Route>
	<Route path="/login">
		<LoginRegisterPage />
	</Route>
	<Route path="/">
		<LoginRegisterPage />
	</Route>
	<Route path="/*" firstmatch>
		{#if $user}
			<Appbar>
				<Route path="/profile">
					<Profile />
				</Route>
				<Route path="/invite">
					<InviteResponse />
				</Route>
				<Route path="/location/create">
					<CreateLocation />
				</Route>
				<Route path="/location/:locationId/*" let:meta>
					{#if !checkPerms($user, meta.params.locationId, p.READ_OVERVIEW)}
						<Route path="/*">
							<Default />
						</Route>
					{/if}
					{#if checkPerms($user, meta.params.locationId, p.READ_OVERVIEW)}
						<Route path="/">
							<Overview />
						</Route>
					{/if}
					{#if checkPerms($user, meta.params.locationId, p.MANAGE_ITEMS)}
						<Route path="/items">
							<ItemManager />
						</Route>

						<Route path="/items/:itemId" let:meta>
							<ItemPage itemId={meta.params.itemId} />
						</Route>
						<Route path="/videos">
							<VideoManager />
						</Route>
						<Route path="/videos/:videoId" let:meta>
							<VideoPage videoId={meta.params.videoId} />
						</Route>
						<Route path="/settings">
							<LocationSettings />
						</Route>
						<Route path="/documents">
							<DocumentManager />
						</Route>
						<Route path="/textboxes">
							<TextboxManager />
						</Route>
					{/if}
					{#if checkPerms($user, meta.params.locationId, p.MANAGE_CONTENT)}
						<Route path="/modules">
							<ModuleManager />
						</Route>
						<Route path="/content" let:meta>
							<ContentManager />
						</Route>
						<Route path="/modules/:moduleId" let:meta>
							<Module moduleId={meta.params.moduleId} />
						</Route>
						<Route path="/categories">
							<CategoryManager />
						</Route>
					{/if}

					{#if checkPerms($user, meta.params.locationId, p.MANAGE_USERS)}
						<Route path="/users">
							<UserManager />
						</Route>
					{/if}
					{#if checkPerms($user, meta.params.locationId, p.MANAGE_USERS)}
						<Route path="/users/:userId" let:meta>
							<UserPage userId={meta.params.userId} />
						</Route>
					{/if}
					{#if checkPerms($user, meta.params.locationId, p.MANAGE_USERS)}
						<Route path="/usergroups">
							<UserGroupManager />
						</Route>
					{/if}

					{#if checkPerms($user, meta.params.locationId, p.MANAGE_IMAGES)}
						<Route path="/images">
							<ImageManager />
						</Route>
					{/if}
					{#if checkPerms($user, meta.params.locationId, p.ANNOTATE_IMAGES)}
						<Route path="/annotator">
							<Annotator />
						</Route>
					{/if}
					{#if checkPerms($user, meta.params.locationId, p.MANAGE_CONTENT)}
						<Route path="/servicemessages">
							<ServiceMessageManager />
						</Route>
					{/if}
					{#if checkPerms($user, meta.params.locationId, p.ADMIN)}
						<Route fallback>
							<NotFound />
						</Route>
					{/if}
				</Route>
				<Route path="/machine-learning/*">
					{#if checkGlobalPerms($user, p.MANAGE_MACHINELEARNING)}
						<Route path="/datasets">
							<DatasetManager />
						</Route>

						<Route path="/tags">
							<TagManager />
						</Route>
						<Route path="/tags/:tagId" let:meta>
							<TagPage tagId={decodeURI(meta.params.tagId)} />
						</Route>

						<Route path="/models">
							<ModelManager />
						</Route>

						<Route path="/runs">
							<RunManager />
						</Route>

						<Route path="/classificationruns">
							<ClassificationRunManager />
						</Route>

						<Route path="/datasets/:datasetId" let:meta>
							<DatasetPage datasetId={meta.params.datasetId} />
						</Route>
						<Route fallback>
							<NotFound />
						</Route>
					{/if}
				</Route>
				<Route path="/admin/*">
					{#if checkGlobalPerms($user, p.ADMIN)}
						<Route path="/services">
							<ServiceManager />
						</Route>

						<Route path="/locations">
							<LocationManager />
						</Route>

						<Route path="/roles">
							<RoleManager />
						</Route>
						<Route path="/global-overview">
							<GlobalOverview />
						</Route>
						<Route path="/global-items">
							<GlobalItemManager />
						</Route>
						<Route path="/global-items/:itemId" let:meta>
							<GlobalItem itemId={meta.params.itemId} />
						</Route>
						<Route fallback>
							<NotFound />
						</Route>
					{/if}
				</Route>
				<Route fallback>
					<NotFound />
				</Route>
			</Appbar>
		{/if}
	</Route>
</Route>

<style>
	:global(.danger-outline) {
		border-color: red !important;
		color: red !important;
	}
	:global(.danger-button) {
		background-color: red !important;
	}
</style>
