<script>
	import Dialog from '../Dialog.svelte';
	import {createEventDispatcher} from 'svelte';

	import {modules} from '../../stores/modules';

	const dispatch = createEventDispatcher();

	let error = '';
	let close;
	export let open;
	export let module;
	let keepTextboxes = false;
	let keepLinks = false;

	const onSubmit = async () => {
		const data = {
			keepTextboxes,
			keepLinks,
		};
		try {
			await modules.delete(module.id, data);
			error = '';
			dispatch('submit');
			close();
		} catch (e) {
			error = e.error ? e.error : e;
		}
	};
</script>

<form on:submit|preventDefault={onSubmit}>
	<Dialog
		title="Delete module?"
		bind:open
		bind:close
		bind:error
		submitName="Delete">
		<div class="field">
			<label>
				<input
					class="bigCheck"
					type="checkbox"
					bind:checked={keepLinks} />
				Keep links?
			</label>
			<label>
				<input
					class="bigCheck"
					type="checkbox"
					bind:checked={keepTextboxes} />
				Keep textboxes?
			</label>
		</div>
	</Dialog>
</form>

<style>
	label {
		display: block;
		margin-bottom: 10px;
	}
</style>
