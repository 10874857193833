import {token} from './stores/token';
import {localstore} from './stores/localstore';

export const PERMISSIONS = {
	READ: 1,
	READ_OVERVIEW: 2,
	MANAGE_IMAGES: 3,
	ANNOTATE_IMAGES: 4,
	MANAGE_MACHINELEARNING: 5,
	MANAGE_CONTENT: 6,
	APPROVE_CONTENT: 7,
	MANAGE_ITEMS: 8,
	INVITE_USERS: 9,
	MANAGE_USERS: 10,
	MANAGE_LOCATIONS: 11,
	SERVICE_CONTENT: 12,
	SERVICE_MACHINELEARNING: 13,
	SOCIAL_COMMENT_ON_CONTENT: 14,
	SOCIAL_RATE_CONTENT: 15,
};

export const isAuthenticationValid = (user) => {
	if (!user) {
		return false;
	}
	// if (user.exp < Math.floor(new Date().valueOf() / 1000)) {
	// 	return false;
	// }

	return true;
};

export const checkPerms = (user, locationId, permission) => {
	if (!isAuthenticationValid(user)) {
		//token.set(null);

		return false;
	}

	const valid =
		user.admin ||
		user.locationPermissions[locationId]?.includes(permission);

	return valid;
};

export const checkGlobalPerms = (user, permission) => {
	if (!isAuthenticationValid(user)) {
		//token.set(null);
		return false;
	}

	const valid = user.admin || user.globalPermissions.includes(permission);

	return valid;
};
