import {writable} from 'svelte/store';

import {
	deleteUserGroup,
	getUserGroups,
	updateUserGroup,
	postUserGroup,
} from '../api/usergroups';

function createUsers() {
	const {subscribe, set, update} = writable();

	return {
		subscribe,
		fetch: async (offset, limit, order) => {
			const userGroups = await getUserGroups(offset, limit, order);
			set(userGroups);
		},

		add: async (group) => {
			await postUserGroup(group);
		},
		change: async (group) => {
			await updateUserGroup(group);
			fetchUsers();
		},
		delete: async (id) => {
			await deleteUserGroup(id);
			update((arr) => ({
				count: arr.count,
				records: arr.records.filter((el) => el.id !== id),
			}));
		},
	};
}

export const usergroups = createUsers();
