<script>
	import {createEventDispatcher} from 'svelte';
	const dispatch = createEventDispatcher();

	import Dialog from '../Dialog.svelte';

	import {updateTag} from '../../api/tags';

	const onSubmit = async () => {
		try {
			await updateTag(tag);

			tag = null;
			dispatch('submit');
			close();
		} catch (e) {
			error = e.error ? e.error : e;
		}
	};

	$: tag = tag || {
		id: undefined,
		name: '',
	};

	let error = '';
	let close;
	export let open;
	export let tag;
</script>

<form on:submit|preventDefault={onSubmit}>
	<Dialog title="Edit tag" bind:open bind:close bind:error>
		<label>
			Tag name
			<input type="text" bind:value={tag.name} required />
		</label>
	</Dialog>
</form>
