import {LocationApi} from './api';

export const deleteLocationcode = async (locationcodeId) => {
	return LocationApi.delete(`/locationcode/${locationcodeId}`);
};

export const getLocationcode = async (offset, limit) => {
	return LocationApi.get(`/locationcode?offset=${offset}&limit=${limit}`);
};
export const postLocationcode = async () => {
	return LocationApi.post('/locationcode', {});
};
