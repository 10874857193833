<script>
	import Page from '../components/Page.svelte';
	import StoreTable from '../components/StoreTable.svelte';
	import ResolveServiceMessageDialog from '../components/dialogs/ServiceMessageDialog.svelte';
	import {serviceMessages} from '../stores/servicemessages';

	let openServiceMessageResolveDialog;
	let selectedMessage;
	let onUpdate;

	const onViewMessage = (message) => {
		selectedMessage = message;
		openServiceMessageResolveDialog(message);
	};

	const onDelete = async (serviceMessage) => {
		if (!confirm(`Are you sure you want to delete ${serviceMessage.id}?`)) {
			return;
		}
		await serviceMessages.delete(serviceMessage.id);
		onUpdate();
	};

	const columns = [
		{
			title: 'User',
			value: (serviceMessage) => serviceMessage.user.name,
			truncate: true,
		},
		{
			title: 'Message',
			value: (serviceMessage) => serviceMessage.message,
			truncate: true,
		},
		{
			title: 'Resolved',
			value: (serviceMessage) => {
				if (serviceMessage.isResolved) {
					return 'Resolved';
				} else {
					return 'Not Resolved';
				}
			},
		},
		{
			title: 'Resolved By',
			value: (serviceMessage) => serviceMessage.resolvedBy,
			type: 'user',
		},
	];

	const actions = [
		{
			title: 'Resolve',
			icon: 'comments',
			action: onViewMessage,
		},
		{
			title: 'Delete',
			icon: 'delete',
			action: onDelete,
		},
	];
</script>

<Page
	title="Service Messages"
	description="Review and resolve messages from your users here"
	crumbs={['Service Messages']}>
	<svelte:fragment slot="toolbar" />
	<StoreTable
		store={serviceMessages}
		columns={columns}
		actions={actions}
		orderBy="createdAt"
		orderByDirection="desc"
		bind:onUpdate />
	<ResolveServiceMessageDialog
		bind:open={openServiceMessageResolveDialog}
		serviceMessage={selectedMessage}
		on:submit={onUpdate} />
</Page>
