import {LocationApi, MLApi} from './api';

export const getModelList = async () => {
	return MLApi.get('/models?short=1');
};

export const getModelsForLocation = async () => {
	return LocationApi.get('/models');
};

export const getModels = async (offset, limit, order) => {
	return MLApi.get(
		`/models?offset=${offset}&limit=${limit}&orderBy=${order}`
	);
};

export const postModel = async (model) => {
	const data = new FormData();

	if (model.name) {
		data.append('name', model.name);
	}
	if (model.modelFile) {
		data.append('modelFile', model.modelFile[0]);
	}
	if (model.labelFile) {
		data.append('labelFile', model.labelFile[0]);
	}
	if (model.type) {
		data.append('type', model.type);
	}

	return MLApi.post('/models', data);
};

export const updateModel = async (model) => {
	return MLApi.update('/models/' + model.id, {
		name: model.name,
	});
};

export const deleteModel = async (modelId) => {
	return MLApi.delete('/models/', modelId);
};
