<script>
	import {createEventDispatcher} from 'svelte';
	import Dialog from '../Dialog.svelte';
	import {postTextbox, updateTextbox} from '../../api/textboxes';

	const dispatch = createEventDispatcher();

	export let error = '';
	export let open;
	let close;
	export let onUpdate = () => {};

	export let textbox;

	$: textbox = textbox || {
		id: undefined,
		title: '',
		text: '',
	};

	const onSubmit = async () => {
		try {
			if (textbox.id) {
				await updateTextbox(textbox);
			} else {
				await postTextbox(textbox);
			}
			textbox = null;

			dispatch('submit');
			onUpdate();
			close();
		} catch (e) {
			error = e.error ? e.error : e;
		}
	};
</script>

<form on:submit|preventDefault={onSubmit}>
	<Dialog
		title={textbox.id ? 'Edit textbox' : 'Add textbox'}
		bind:open
		bind:close
		bind:error>
		<label>
			Title
			<input type="text" bind:value={textbox.title} required />
		</label>
		<label>
			Text
			<input type="text" bind:value={textbox.text} required />
		</label>
	</Dialog>
</form>
