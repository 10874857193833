<script>
	import {getTagColor} from '../utils/getTagColor';

	export let image;
	export let imageElement;
	$: imageRectangle = imageElement?.getBoundingClientRect();

	$: ratioToPixelsX = (inputX) => {
		return inputX * imageRectangle.width;
	};
	$: ratioToPixelsY = (inputY) => {
		return inputY * imageRectangle.height;
	};
</script>

<div class="img-overlay-wrap">
	{#if imageRectangle}
		<svg>
			{#each image.annotations as annotation}
				<rect
					x={ratioToPixelsX(annotation.x - annotation.width / 2)}
					y={ratioToPixelsY(annotation.y - annotation.height / 2)}
					width={ratioToPixelsX(annotation.width)}
					height={ratioToPixelsY(annotation.height)}
					stroke={getTagColor(annotation.tagId)}
					fill={getTagColor(annotation.tagId)}
					fill-opacity="0.2" />
				<rect
					x={ratioToPixelsX(annotation.x - annotation.width / 2)}
					y={ratioToPixelsY(annotation.y - annotation.height / 2) -
						16}
					width={ratioToPixelsX(annotation.width)}
					height={16}
					stroke={getTagColor(annotation.tagId)}
					fill={getTagColor(annotation.tagId)}
					fill-opacity="0.8" />
				<text
					x={ratioToPixelsX(annotation.x - annotation.width / 2) + 2}
					y={ratioToPixelsY(annotation.y - annotation.height / 2) - 4}
					class="annotation-text"
					fill="white">
					{annotation.tag.name}
				</text>
			{/each}
		</svg>
	{/if}
	<img
		class="image"
		draggable="false"
		alt="annotationimage"
		src={image.url}
		bind:this={imageElement}
		on:load={() => {
			imageElement = imageElement;
		}} />
</div>

<style>
	img {
		max-width: 100%;
		border-radius: 4px;
	}

	.img-overlay-wrap {
		position: relative;
		display: inline-block;
		transition: 0.2s;
	}
	.img-overlay-wrap svg {
		position: absolute;
		width: 100%;
		height: 100%;
	}

	.annotation-text {
		font-size: 12px;
		user-select: none;
	}
</style>
