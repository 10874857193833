import {mediaPost, LocationApi} from './api';

export const deleteImage = async (imageId) => {
	return LocationApi.delete(`/image/${imageId}`);
};

export const deleteImages = (imageIds) => {
	return Promise.all(imageIds.map((imageId) => deleteImage(imageId)));
};

export const getImageUrls = async (count, confirmNoPersonalData, tagId) => {
	return LocationApi.post(`/images/geturls`, {count, confirmNoPersonalData, tagId});
};

export const getImagesForAnnotator = async (query = {}) => {
	const queryObj = {
		force: query.forceImage || false,
		...query,
	};

	let queryString = '';
	Object.entries(queryObj).forEach(([key, value]) => {
		queryString += (queryString.length ? '&' : '?') + key + '=' + value;
	});

	return LocationApi.get(`/image/forAnnotator${queryString}`);
};

export const getImageById = async (id) => {
	return LocationApi.get(`/image/${id}`);
};

export const getImages = async (offset, limit) => {
	return LocationApi.get(`/images?limit=${limit}&offset=${offset}`);
};

export const postImages = (images, confirmNoPersonalData, onUploadProgress) => {
	const data = new FormData();
	const imageData = new FormData();
	if (Array.isArray(images)) {
		images.forEach((image) => {
			imageData.append('img', image);
		});
	} else {
		imageData.append('img', images);
	}
	data.append('confirmNoPersonalData', confirmNoPersonalData);

	const config = {
		onUploadProgress,
	};
	return mediaPost('/images', data, imageData, config);
};

export const rotateImage = async (image) => {
	return LocationApi.update('/image/rotate', {
		imageId: image.id,
		angle: image.angle,
	});
};

export const rotateImages = (images) => {
	return Promise.all(images.map((image) => rotateImage(image)));
};
