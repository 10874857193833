<script>
	export let danger = false;
	export let filled = false;
	export let textonly = false;
	export let submit = false;
	export let href = false;
	export let selected = false;
</script>

{#if href}
	<a href={href}>
		<button
			type={submit ? 'submit' : 'button'}
			class:danger
			class:filled
			class:textonly
			on:click><slot /></button>
	</a>
{:else}
	<button
		type={submit ? 'submit' : 'button'}
		class:danger
		class:filled
		class:textonly
		class:selected
		on:click><slot /></button>
{/if}

<style>
	button {
		font-size: 14px;
		padding: 0 10px;
		height: 34px;
		border-radius: 4px;
		border: 1px solid #30465e;
		background-color: transparent;
		color: #30465e;
		user-select: none;
		transition: transform 0.12s;
	}
	button:hover {
		cursor: pointer;
		background-color: #efefef;
	}
	button:active {
		transform: translateY(1.5px);
		background-color: #ededed;
	}

	.filled {
		background-color: #000096;
		border: none;
		color: white;
	}
	.filled:hover {
		background-color: #3a44ad;
	}
	.filled:active {
		background-color: #3a44ad;
	}

	.textonly {
		background-color: none;
		border: none;
		color: black;
	}
	.textonly:hover {
		background-color: none;
		border: none;
	}
	.danger {
		border-color: red;
		color: red;
	}
	.selected {
		background-color: #eee;
	}
</style>
