<script>
	import {onMount} from 'svelte';
	import {router} from 'tinro';
	import Select from 'svelte-select';

	import {user} from '../stores/user';
	import {setLocation} from '../api/authentication';
	import getSafeUrl from '../utils/getSafeUrl';
	import {localstore} from '../stores/localstore';
	import {shortLocations} from '../stores/short/locations';

	shortLocations.fetch();
	$: value = null;

	const onChangeLocation = async (event) => {
		const locationId = event.detail.value;
		await setLocation(locationId);
		$localstore.locationId = locationId;
		const url = getSafeUrl(
			locationId,
			$user?.locationPermissions[locationId],
			$user?.admin
		);
		router.goto(url);
	};

	onMount(() => {
		value = {value: String($user?.locationId)};
	});
</script>

{#if $shortLocations && value}
	<Select
		bind:value
		noOptionsMessage="No locations"
		items={Object.entries($shortLocations).map(([key, value]) => {
			return {
				value: key,
				label: value,
			};
		})}
		on:select={onChangeLocation}
		showIndicator
		isClearable={false} />
{/if}
