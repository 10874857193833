<script>
	import {onMount} from 'svelte';

	export let isOpen = false;
	export let miniWindow = false;

	export const open = () => {
		isOpen = true;
	};
	export const close = () => {
		isOpen = false;
	};

	function setMiniWindow() {
		miniWindow = window.innerWidth < 900;
	}

	onMount(setMiniWindow);
</script>

<svelte:window on:resize={setMiniWindow} />

<div class="drawer" class:miniWindow class:open={isOpen}>
	<slot />
</div>

<style>
	.drawer {
		box-sizing: border-box;
		width: 256px;
		min-width: 256px;
		background-color: white;
		border-right: 1px solid #0003;
		transition: width 0.2s;
	}
	.drawer:not(.miniWindow) {
		height: 100vh;
		position: sticky;
		top: 0;
	}
	.drawer.miniWindow {
		z-index: 10;
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		width: 0px;
		min-width: 0px;
		overflow: hidden;
	}
	.drawer.miniWindow.open {
		width: 250px;
		min-width: 250px;
	}
</style>
