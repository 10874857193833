<script>
	export let engagements = null;

	const calculateLikePercent = (eng) => {
		return (eng.likes / (eng.likes + eng.dislikes)) * 100;
	};
</script>

{#if engagements}
	<div class="Engagements">
		<p>
			{engagements.views} views
			<span class="material-icons up">thumb_up</span>{engagements.likes}
			<span class="material-icons down">thumb_down</span
			>{engagements.dislikes}
		</p>

		{#if calculateLikePercent(engagements) > 50 && engagements.likes > 4}
			<p>
				{calculateLikePercent(engagements)}% of users think this is
				helpful
			</p>
		{/if}
	</div>
{/if}

<style>
	.Engagements :global(.material-icons) {
		font-size: 18px;
		vertical-align: middle;
		margin-left: 10px;
		margin-right: 4px;
	}
	.Engagements :global(.material-icons.up) {
		margin-bottom: 4px;
	}
	.Engagements :global(.material-icons.down) {
		margin-top: 2px;
	}
</style>
