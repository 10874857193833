import {BaseApi, mlPost} from './api';

export const getMLModel = async (modelId) => {
	return BaseApi.get(`/mlmodel/${modelId}`);
};

export const getMLModels = async () => {
	return BaseApi.get('/mlmodels');
};

export const postStartTraining = async (datasetId) => {
	return mlPost('/start', {datasetId});
};
