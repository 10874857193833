<script>
	import {createEventDispatcher} from 'svelte';
	const dispatch = createEventDispatcher();

	import Dialog from '../Dialog.svelte';

	import {importTagFromLocation} from '../../api/datasets';
	import {shortLocations} from '../../stores/short/locations';
	shortLocations.fetch();

	const onSubmit = async () => {
		try {
			if (!locationId) {
				throw new Error('You must select a location');
			}

			await importTagFromLocation(datasetId, locationId);
			dispatch('submit');
			close();
		} catch (e) {
			error = e.error ? e.error : e;
		}
	};

	let error = '';
	let locationId;
	let close;
	export let open;
	export let datasetId;
</script>

<form on:submit|preventDefault={onSubmit}>
	<Dialog title="Import from location" bind:open bind:close bind:error>
		<label>
			Location
			<select bind:value={locationId} label="Location">
				<option value="">Select location</option>
				{#each Object.entries($shortLocations) as [locationId, locationName]}
					<option value={Number(locationId)}>{locationName}</option>
				{/each}
			</select>
		</label>
	</Dialog>
</form>
