<script>
	import {createEventDispatcher} from 'svelte';
	import Dialog from '../Dialog.svelte';
	import {postDocument, updateDocument} from '../../api/documents';
	import {stripExtension} from '../../utils/fileUtils';

	const dispatch = createEventDispatcher();

	let uploader;
	let error = '';
	let close;
	export let open;
	export let document;
	export let moduleId = null;
	export let hideChooser = false;

	export let onUpdate = () => {};

	const onProgress = (event) => {
		//progressHidden = false;
		//progress = (event.loaded / event.total) * 100;
	};

	const onFileChange = (e) => {
		if (!e.target?.files?.length) {
			return;
		}
		const fileName = stripExtension(e.target.files[0].name);
		if (!document.name || document.name === '') {
			document.name = fileName;
		}
	};

	$: document = document || {
		id: undefined,
		name: '',
		files: null,
	};

	const onSubmit = async () => {
		try {
			if (document.id) {
				await updateDocument(document);
			} else {
				if (moduleId) {
					document.moduleId = moduleId;
				}
				await postDocument(document, onProgress);
			}

			document = null;

			if (uploader) {
				uploader.value = null;
			}

			dispatch('submit');
			onUpdate();
			close();
		} catch (e) {
			error = e.error ? e.error : e;
		}
	};
</script>

<form on:submit|preventDefault={onSubmit}>
	<Dialog
		title="{document.id ? 'Edit' : 'Add'} document"
		bind:open
		bind:close
		bind:error>
		<label>
			Name
			<input type="text" bind:value={document.name} required />
		</label>

		{#if !document.id && !hideChooser}
			<label>
				Document
				<input
					id="fileuploader"
					accept=".pdf,.docx,.pptx,.doc,.csv,.xlsx"
					type="file"
					class="fileupload"
					on:change={onFileChange}
					bind:files={document.files}
					bind:this={uploader}
					required />
			</label>
		{/if}
	</Dialog>
</form>
