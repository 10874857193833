import amplitude from 'amplitude-js';

const AMPLITUDE_CLIENT_ID = myapp.env.AMPLITUDE_CLIENT_ID; //eslint-disable-line no-undef

export const initAmplitude = () => {
	amplitude.getInstance().init(AMPLITUDE_CLIENT_ID);
};

export const setAmplitudeUserDevice = (installationToken) => {
	amplitude.getInstance().setDeviceId(installationToken);
};

export const setAmplitudeUserId = (userId) => {
	amplitude.getInstance().setUserId(userId);
};

export const setAmplitudeUserProperties = (properties) => {
	amplitude.getInstance().setUserProperties(properties);
};

export const sendAmplitudeEvent = (eventType, eventProperties) => {
	amplitude.getInstance().logEvent(eventType, eventProperties);
};
