import {writable} from 'svelte/store';

import {getCategoriesShort} from '../../api/categories';

function createCategories() {
	const {subscribe, set} = writable({});
	return {
		subscribe,
		fetch: async () => {
			const categories = await getCategoriesShort();
			const result = categories.reduce((map, obj) => {
				map[obj.id] = obj.name;
				return map;
			}, {});
			return set(result);
		},
	};
}

export const shortCategories = createCategories();
