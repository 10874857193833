<script>
	import {onMount} from 'svelte';
	import Select from 'svelte-select';

	import {createEventDispatcher} from 'svelte';
	const dispatch = createEventDispatcher();

	import Dialog from '../Dialog.svelte';

	import {
		getPublicModulesForMyLocations,
		copyModule,
	} from '../../api/modules';

	let close;
	export let open;
	export let modules = [];

	let error = '';
	$: selected = null;

	onMount(async () => {
		modules = await getPublicModulesForMyLocations('course');
		if (!modules) {
			modules = [];
		}
		modules = modules.map((module) => {
			module.label = module.name;
			module.value = module.id;
			return module;
		});
	});

	const onSubmit = async () => {
		try {
			if (selected === null) {
				return;
			}
			await copyModule(selected.id);
			dispatch('submit');
			close();
		} catch (e) {
			error = e.error;
		}
	};
</script>

<form on:submit|preventDefault={onSubmit}>
	<Dialog title="Add module from library" bind:open bind:close bind:error>
		<Select items={modules} bind:value={selected} />
	</Dialog>
</form>

<style>
	form {
		max-width: 100%;
	}
</style>
