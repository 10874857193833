<script>
	import {createEventDispatcher} from 'svelte';
	const dispatch = createEventDispatcher();

	import Dialog from '../Dialog.svelte';

	import {models} from '../../stores/models';

	let error = '';
	let upload1, upload2;
	let close;
	export let open;
	export let model;

	$: model = model || {
		id: undefined,
		modelFile: null,
		labelFile: null,
		name: '',
		type: 'classification',
	};

	const onSubmit = async () => {
		try {
			if (model.id) {
				await models.change(model);
			} else {
				await models.add(model);
			}

			model = null;

			upload1.value = null;
			upload2.value = null;

			dispatch('submit');
			close();
		} catch (e) {
			error = e.error ? e.error : e;
		}
	};
</script>

<form on:submit|preventDefault={onSubmit}>
	<Dialog
		title="{model.id ? 'Edit' : 'Add'} model"
		bind:open
		bind:close
		bind:error>
		<label>
			Name<input type="text" bind:value={model.name} required />
		</label>
		<label>
			Model file<input
				type="file"
				accept=".tflite"
				bind:this={upload1}
				bind:files={model.modelFile} />
		</label>
		<label>
			Label file<input
				type="file"
				accept=".txt"
				bind:this={upload2}
				bind:files={model.labelFile} />
		</label>
	</Dialog>
</form>
