<script>
	import {createEventDispatcher, onMount} from 'svelte';
	import Button from '../components/Button.svelte';
	import Page from '../components/Page.svelte';
	import {refreshLogin} from '../api/authentication';
	import {token} from '../stores/token';

	import {locations} from '../stores/locations';

	const dispatch = createEventDispatcher();

	let error = '';

	const onSubmit = async () => {
		try {
			const newLoc = await locations.add(newLocation);
			dispatch('submit');
			const user = await refreshLogin();
			$token = user.token;
			location.href = '/location/' + newLoc.id;
		} catch (e) {
			error = e.error ? e.error : e;
		}
	};

	$: newLocation = newLocation || {
		name: '',
		description: '',
	};
</script>

<Page title="Create new location">
	<form on:submit|preventDefault={onSubmit}>
		<label>
			Name
			<input type="text" bind:value={newLocation.name} required />
		</label>

		<label text="Description">
			Description
			<input type="text" bind:value={newLocation.description} />
		</label>
		{#if error}<p class="error">{error}</p>{/if}
		<Button submit>Create Location</Button>
	</form>
</Page>
