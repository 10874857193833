import {writable} from 'svelte/store';

import {getLoginLogs} from '../api/loginLogs';

function createUserLogins() {
	const {subscribe, set} = writable({count: 0, records: []});
	const fetchLoginLogs = async (offset, limit) => {
		const loginLogs = await getLoginLogs(offset, limit);
		set(loginLogs);
	};
	return {
		subscribe,
		fetch: (offset, limit) => fetchLoginLogs(offset, limit),
	};
}

export const loginLogs = createUserLogins();
