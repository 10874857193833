<script>
	export let onSignIn = () => {};

	const GOOGLE_CLIENT_ID = myapp.env.GOOGLE_CLIENT_ID; //eslint-disable-line no-undef

	window.onSuccess = (googleUser) => {
		onSignIn(googleUser.getAuthResponse().id_token);
		googleSignOut(); //Sign out from google again to avoid annoying re-sign in logic
	};

	export const googleSignOut = () => {
		const auth2 = gapi.auth2.getAuthInstance();
		auth2.signOut().then(() => {
			// User is now signed out
		});
	};
</script>

<svelte:head>
	<meta name="google-signin-client_id" content={GOOGLE_CLIENT_ID} />
	<script src="https://apis.google.com/js/platform.js" async defer></script>
</svelte:head>

<div class="g-signin2" data-longtitle="true" data-onsuccess="onSuccess" />
