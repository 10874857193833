<script>
	import QRCode from 'qrcode';
	import Dialog from '../Dialog.svelte';

	let close;
	export let open;
	export let locationCode;

	let qrCode = null;
	let code = null;

	const updateCode = async () => {
		qrCode = await QRCode.toDataURL(
			`https://app.unifractal.com/join?locationcode=${locationCode}`
		);
		code = `https://app.unifractal.com/join?locationcode=${locationCode}`;
	};

	$: if (locationCode) updateCode();
</script>

<Dialog title="Location code" bind:open bind:close noCancel noSubmit>
	{#if qrCode}
		<img src={qrCode} alt="qr code" />
		<p><strong>Link:</strong><br />{code}</p>
	{/if}
</Dialog>

<style>
	img {
		width: 100%;
		image-rendering: pixelated;
	}
	p {
		text-align: center;
	}
</style>
