export const stripExtension = (fileName) => {
	return fileName.split('.')[0];
};

export const isDocument = (type) => {
	const documentTypes = [
		'application/pdf',
		'application/x-ms-dos-executable',
		'text/plain',
	];
	return documentTypes.includes(type);
};

export const isVideo = (type) => {
	return type.includes('video');
};
