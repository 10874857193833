//Removes keys with undefined as value from object
export const stripUndefined = (obj) => {
	const noMutate = {...obj};
	Object.keys(noMutate).forEach(
		// eslint-disable-next-line no-undefined
		(key) => noMutate[key] === undefined && delete noMutate[key]
	);

	return noMutate;
};

export default stripUndefined;
